<template>
    <v-row align="center" class="ml-0" justify="center">
        <v-col cols="12" xs="12" sm="8" md="6" lg="4" xl="4">
            <v-card class="pa-4">
                <v-card-title>
                    <p class="display-1 text--primary">Đăng Ký</p>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form_auth_register" @keyup.native.enter="register">
<!--                        <v-text-field-->
<!--                                label="Họ và tên"-->
<!--                                id="name"-->
<!--                                name="name"-->
<!--                                prepend-icon="person"-->
<!--                                type="text"-->
<!--                                v-model="name"-->
<!--                                :counter="30"-->
<!--                                required-->
<!--                                :rules="nameRules"-->
<!--                        ></v-text-field>-->
                        <v-text-field
                                label="Tên đăng nhập"
                                id="username"
                                name="username"
                                prepend-icon="person"
                                type="text"
                                v-model="username"
                                :counter="20"
                                required
                                :rules="usernameRules"
                        ></v-text-field>
                        <v-text-field
                                label="Email"
                                id="email"
                                name="email"
                                prepend-icon="email"
                                type="text"
                                v-model="email"
                                required
                                :rules="emailRules"
                        ></v-text-field>
                        <v-text-field
                                id="password"
                                label="Mật khẩu"
                                name="password"
                                type="password"
                                prepend-icon="lock"
                                v-model="password"
                                :rules="passwordRules"
                                :counter="20"
                                required
                        ></v-text-field>
                        <v-text-field
                                id="password_confirmation"
                                label="Nhập lại mật khẩu"
                                name="password_confirmation"
                                prepend-icon="lock"
                                type="password"
                                v-model="password_confirmation"
                                :counter="20"
                                required
                                :rules="confirmPasswordRules"
                        ></v-text-field>
                        <v-text-field
                                label="Mã giới thiệu ( nếu có )"
                                id="ref"
                                name="ref"
                                prepend-icon="mdi-account-switch"
                                type="text"
                                v-model="ref"
                                :counter="20"
                                required
                                :value="ref"
                                :rules="refRules"
                        ></v-text-field>
                    </v-form>
                    <v-card-actions class="px-8 col-11 offset-1 md-12 align-center recaptcha">
                        <vue-recaptcha
                                class="g-recaptcha"
                                ref="recaptcha"
                                @verify="onVerify"
                                refs="recaptcha"
                                :sitekey="g_recaptcha_key">
                        </vue-recaptcha>
                    </v-card-actions>
                </v-card-text>
                <v-card-actions class="px-8">
                    <v-btn
                            xs12
                            color="primary"
                            block
                            height="60"
                            rounded
                            class="white--text font-weight-bold cols-12"
                            @click="register"
                    >Đăng Ký
                    </v-btn>
                </v-card-actions>
                <p class="text-center mt-4">
                    Bạn đã có tài khoản?
                    <router-link to="/login">Đăng Nhập</router-link>
                </p>
            </v-card>
        </v-col>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </v-row>
</template>
<script>
    import { AUTH_REGISTER } from "~/store/actions.type";
    // import {COMMON_SET_SNACK} from "../store/actions.type";
    import VueRecaptcha from 'vue-recaptcha';
    import {GOOGLE_RECAPTCHA_KEY} from "~/config/constants";
    export default {
        components: { VueRecaptcha },
        data() {
            return {
                email: "",
                // name: "",
                username: "",
                password: "",
                password_confirmation: "",
                ref: "",
                recaptcha : "",
                has_error: false,
                showPassword: false,
                overlay: false,
                g_recaptcha_key : GOOGLE_RECAPTCHA_KEY,
                // nameRules: [
                //     v => !!v || 'Vui lòng nhập tên',
                //     v => (v && v.length <= 30) || 'Tên không được quá 20 ký tự',
                // ],
                /* eslint-disable */
                usernameRules: [
                    v => !!v || 'Vui lòng nhập tên đăng nhập',
                    v => /[a-z0-9]/i.test(v) || 'Tên tài khoản chỉ bao gồm chữ cái thường và số',
                    v => (v == v.toLowerCase()) || 'Tên tài khoản chỉ bao gồm chữ cái thường và số',
                    v => (v && v.length <= 20) || 'Tên đăng nhập không được quá 20 ký tự',
                ],
                emailRules: [
                  v => !!v || 'Vui lòng nhập E-mail',
                  v => /.+@.+\..+/.test(v) || 'E-mail không hợp lệ',
                ],
                refRules: [
                    // v => (v.length <= 20) || 'Mã giới thiệu không được quá 20 ký tự',
                ],
                passwordRules: [
                    v => !!v || 'Vui lòng nhập mật khẩu',
                    v => (v && v.length <= 20) || 'Mật khẩu không được quá 20 ký tự',
                ],
                confirmPasswordRules: [
                    v => !!v || 'Vui lòng nhập lại mật khẩu',
                    v => (v && v.length <= 20) || 'Mật khẩu không được quá 20 ký tự',
                    v => (v === this.password) || 'Password không khớp'
                ]
            };
        },
        created() {
            this.ref = this.$route.query.ref;
            if(typeof this.ref === "undefined"){
                this.ref = '';
            }
        },
        watch: {
            'username' : function(v) {
                this.username = v.toLowerCase().trim();
            }
        },
        methods: {
            async register() {
                if (this.$refs.form_auth_register.validate()) {
                    this.overlay = true;
                    // let name = this.name;
                    let username = this.username;
                    let email = this.email;
                    let password = this.password;
                    let password_confirmation = this.password_confirmation;
                    let ref = this.ref;
                    let recaptcha = this.recaptcha;
                    try {
                        this.$store.dispatch(AUTH_REGISTER, {
                            // name,
                            username,
                            email,
                            password,
                            password_confirmation,
                            ref,
                            recaptcha
                        }).then((data) => {
                            this.overlay = false;
                            if (data) {
                                // this.$store.dispatch(COMMON_SET_SNACK, {
                                //     message: 'Đăng kí tài khoản thành công! Bạn đã được nhận 1000 coin từ hệ thống HANA !',
                                //     type: "success"
                                // });
                                this.$router.push({ name: "login" });
                            }
                        });
                    } catch (error) {
                        this.overlay = false;
                        console.log(error);
                    }
                    this.overlay = false;
                    this.$refs.recaptcha.reset();
                }else{
                    this.$refs.recaptcha.reset();
                }
            },
            onVerify(response) {
                this.recaptcha = response;
            },
            resetRecaptcha: function() {
                this.grecaptcha.reset();
            },
            refOnChange(){

            }
        }
    };
</script>
<style lang="scss" scoped>
    .recaptcha {
        position: relative;
        padding-top: 70px;
        padding-bottom: 55px;
        .g-recaptcha {
            position: absolute;
            left: 50%;
            margin-left: -152px;
        }
    }

</style>
