<script src="//unpkg.com/vue/dist/vue.js"></script>

<template>
    <div class="lucky-wheel" style="background-image: url(/images/background_lucky_wheel.jpg);">
        <v-container fluid grid-list-lg>
            <v-row>
                <v-col>
                    <v-card class="deposit pa-2 white--text text-center bg-gradient" outlined :to="{ name: 'lucky_wheel', params: {type: 'run'}}" @click="type='run'">
                        <span class="mt-3">Quay thưởng</span><br />
                    </v-card>
                    <div v-if="type==='run'" class="bg-gradient div-flag"></div>
                </v-col>
                <v-col>
                    <v-card class="fanpage pa-2 white--text text-center bg-gradient" outlined :to="{ name: 'lucky_wheel', params: {type: 'history'}}" @click="type='history'">
                        <span>Góc trả thưởng</span>
                    </v-card>
                    <div  v-if="type==='history'" class="bg-gradient div-flag"></div>
                </v-col>
            </v-row>
            <div v-if="type==='run'" class="tab_run">
                <v-row>
                    <v-col>
                        <span id="app">
                            <div class="wheel-wrapper">
                                <div
                                    class="wheel-pointer"
                                    @click="process_exchange_coin"
                                >
                                </div>
                                <div
                                    class="wheel-bg"
                                    :class="{freeze: freeze}"
                                    :style="`transform: rotate(${wheelDeg}deg)`"
                                    style="background-image: url(/images/lucky_wheel_v2.png);"
                                >
                                    <div class="prize-list">
                                        <div
                                            class="prize-item-wrapper"
                                            v-for="(item,index) in awardList"
                                            :key="index"
                                        >
                                            <div
                                                class="prize-item"
                                                :style="`transform: rotate(${(360/ awardList.length) * index}deg)`"
                                                style="/images/facebook.png"
                                            >
                                                <div class="prize-name">

                                                </div>
                                                <div class="prize-icon">
                                                    <img :src="item.icon">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </v-col>
                </v-row>

                <v-layout row class="award_info">
                    <v-flex xs12>
                    - Nếu trúng thưởng, vui lòng điền thêm thông tin sau tại mục Góc trả thưởng:<br>
                    + Địa chỉ nhận hàng (đối với các giải Mũ Hana/Áo Hana)<br>
                    + Số điện thoại, nhà mạng (đối với Thẻ cào)<br>
                    + Link nhận tiền (đối với Momo)<br>
                    + Tài khoản ngân hàng (đối với Bank)<br>
                    - Đối với Mã dự thưởng, chúng tôi sẽ tiến hành chọn ngẫu nhiên 1 mã để quay vào 21h tối chủ nhật cuối cùng trong tháng.<br>
                    - Chúng tôi sẽ liên hệ với bạn để trao giải (chậm nhất là 1 tuần kể từ ngày trúng thưởng).
                    </v-flex>
                </v-layout>
            </div>
            <div v-if="type==='history'" class="tab_history">

                <v-layout row>
                    <v-flex xs12>

                        <v-card>
                            <v-card-title>
                                Lịch sử quay thưởng:
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <div id="history">
                                <v-data-table
                                    v-if="total_receive_award == 0"
                                    :headers="header_receive_award"
                                    :options.sync="options_receive_award"
                                    :server-items-length="total_receive_award"
                                    class="elevation-1"
                                >
                                    Bạn chưa quay lần nào!
                                </v-data-table>
                                <v-data-table v-else
                                    :headers="header_receive_award"
                                    :items="array_receive_award"
                                    :options.sync="options_receive_award"
                                    :server-items-length="total_receive_award"
                                    :loading="loading_receive_award"
                                    class="elevation-1"
                                >
                                    <template v-slot:item.award_id = "{item}">
                                        {{ item.award_name }}
                                    </template>
                                    <template v-slot:item.note = "{item}">
                                        {{ item.award_id == 3 ? item.random_code : item.note }}
                                    </template>
                                    <template v-slot:item.created_at = "{item}">
                                        {{ item.created_at }}
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <v-chip v-if="item.status == 1" color="orange" dark>
                                            <v-avatar left>
                                                <v-icon>mdi-sync</v-icon>
                                            </v-avatar>
                                            {{ statusMessage[item.status] }}
                                        </v-chip>
                                        <v-chip v-if="item.status == 2" color="success" dark>
                                            <v-avatar left>
                                                <v-icon>mdi-check</v-icon>
                                            </v-avatar>
                                            {{ statusMessage[item.status] }}
                                        </v-chip>
                                        <v-chip v-if="item.status == 3" color="secondary" dark>
                                            <v-avatar left>
                                                <v-icon>mdi-close-circle</v-icon>
                                            </v-avatar>
                                            {{ statusMessage[item.status] }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.update_info = "{item}">

                                        <router-link v-if="
                                            ( item.award_id == 1
                                                || item.award_id == 2
                                                || item.award_id == 4
                                                || item.award_id == 5
                                                || item.award_id == 8
                                                || item.award_id == 10
                                                || item.award_id == 11
                                                || item.award_id == 12
                                                || item.award_id == 13
                                                || item.award_id == 14
                                                || item.award_id == 15
                                                || item.award_id == 16
                                            )   && item.status == 1
                                                && item.mobile == null"
                                            :to="{
                                            name : 'lucky_wheel_history_receive_award',
                                            params : { id : item.id }
                                        }">Sửa
                                        </router-link>

                                        <!-- Thẻ đt -->
                                        <span class="show_update_info" v-if="
                                            ( item.award_id == 1
                                            || item.award_id == 4
                                            || item.award_id == 5
                                            || item.award_id == 8 )
                                            && item.mobile !== null"
                                        >
                                            Số điện thoại: {{ item.mobile }}<br>
                                            Nhà mạng: {{ item.telco_name }}<br>
                                            Loại hình: {{ item.simtype == 0 ? "Trả trước" : "Trả sau" }}<br>
                                        </span>

                                        <!-- Áo Hana -->
                                        <span class="show_update_info" v-if="
                                            ( item.award_id == 2 )
                                            && item.mobile !== null"
                                        >
                                            Size áo: {{ item.size }}<br>
                                            Tên người nhận: {{ item.fullname }}<br>
                                            Địa chỉ người nhận: {{ item.address }}<br>
                                            Sđt người nhận: {{ item.mobile }}<br>
                                        </span>

                                        <!-- Mũ Hana -->
                                        <span class="show_update_info" v-if="
                                            ( item.award_id == 10 )
                                            && item.mobile !== null"
                                        >
                                            Tên người nhận: {{ item.fullname }}<br>
                                            Địa chỉ người nhận: {{ item.address }}<br>
                                            Sđt người nhận: {{ item.mobile }}<br>
                                        </span>

                                        <!-- momo -->
                                        <span class="show_update_info" v-if="
                                            ( item.award_id == 11
                                            || item.award_id == 12
                                            || item.award_id == 13
                                            || item.award_id == 14 )
                                            && item.mobile !== null
                                            && item.momo !== null"
                                        >
                                            Tên người nhận: {{ item.fullname }}<br>
                                            Link nhận tiền momo: <br>
                                                <a :href="item.link_momo" target="_blank">
                                                   {{ item.link_momo }}
                                                </a><br>
                                            Số điện thoại: {{ item.mobile }}<br>
                                        </span>

                                        <!-- Bank -->
                                        <span class="show_update_info" v-if="
                                            ( item.award_id == 15
                                            || item.award_id == 16 )
                                            && item.mobile !== null
                                            && item.bank !== null"
                                        >
                                            Ngân hàng: {{ item.bank_name }}<br>
                                            Tên chủ thẻ: {{ item.account_name }}<br>
                                            Số tài khoản: {{ item.account_id }}<br>
                                            Chi nhánh: {{ item.branch_name }}<br>
                                        </span>

                                    </template>

                                </v-data-table>
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>
            </div>
        </v-container>
    </div>
</template>

<script>
    import {
        LUCKY_WHEEL_GET_LIST_AWARD,
        LUCKY_WHEEL_PROCESS_WIN_AWARD,
        LUCKY_WHEEL_GET_HISTORY_AWARD
    } from "~/store/actions.type";
    import {formatCurrency} from "~/common/helper.string";
    import Swal from 'sweetalert2';

    export default {
        data() {
            return {
                type: '',
                freeze: false,
                rolling: false,
                wheelDeg: 0,
                continueRoll: true,

                // history
                total_receive_award: 0,
                array_receive_award: [],
                loading_receive_award: true,
                options_receive_award: {},
                header_receive_award: [
                    { text: 'ID', value: 'id', align: "center"},
                    { text: 'Giải thưởng', value: 'award_id', align: "center"},
                    { text: 'Ghi chú', value: 'note', align: "center"  },
                    { text: 'Thời gian quay', value: 'created_at', align: "center"  },
                    { text: 'Trạng thái', value: 'status',  align: "center"},
                    { text: 'Sửa thông tin nhận giải', value: 'update_info', align: "center"  },
                ],
                awards: [],
                statusMessage: [],
                exchange_coin: 1000,
            }
        },
        filters : {
            formatNumber(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        },
        created() {
            this.initialize();
            this.type = this.$route.params.type;
        },
        computed: {
            awardList() {
                return this.awards.slice(0, this.awards.length);
            },
            coin() {
                return this.$store.getters.coin;
            },
        },
        methods : {
            async initialize(){
                window.scrollTo(0,0);
            },
            onClickRotate(res) {
                if (this.rolling) {
                    return;
                }

                var audio = new Audio(require('../../assets/audio/quay_thuong.mp3'));

                audio.play();
                audio.addEventListener("canplaythrough", function () {
                    setTimeout(function(){
                        audio.pause();
                    }, 8000);
                }, false);

                this.roll(res);
            },
            roll(res) {
                this.rolling = true;
                const { wheelDeg, awardList } = this;
                const position = res.position - 1;

                this.wheelDeg = wheelDeg -
                    wheelDeg % 360 +
                    4 * 360 +
                    (360 - 360 / awardList.length * position);

                this.rolling = false;

                setTimeout(() => {
                    this.continueRoll = true;
                }, 8000);
            },
            process_exchange_coin(){
                if (this.continueRoll) {
                    Swal.fire({
                        title: this.exchange_coin + ' coin/lần nhé bạn ?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Đầu tư',
                        cancelButtonText: 'Dừng chơi'
                    }).then((result) => {
                        if (result.value) {
                            this.continueRoll = false;
                            this.$store.dispatch(LUCKY_WHEEL_PROCESS_WIN_AWARD).then(res => {
                                if (res == false)
                                    this.continueRoll = true;
                                else
                                    this.onClickRotate(res);
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Mỗi lượt quay cách nhau ít nhất 5 giây bạn nhé!',
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Huỷ'
                    }).then((result) => {

                    });
                }
            },
            async get_data_api_award () {
                let data = await this.$store.dispatch(LUCKY_WHEEL_GET_LIST_AWARD);

                if (data.setting.is_maintain === 1) {
                    alert('Vòng quay đang được bảo trì. Hệ thống sẽ hoạt động trở lại trong thời gian sớm nhất.');
                    this.$router.push('/');
                }

                this.exchange_coin = data.setting.exchange_coin;
                this.awards = data.list_awards;
            },
            async get_data_api_history () {
                let object = await this.$store.dispatch(LUCKY_WHEEL_GET_HISTORY_AWARD);

                this.loading_receive_award = true
                const { sortBy = [], sortDesc = [], page, itemsPerPage } = this.options_receive_award

                let items   = object.history;
                const total = items.length;

                if (total == 0)
                    return {items,total};

                this.statusMessage      = object.status_message;

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                }

                this.loading_receive_award = false;
                return {items,total};
            },
        },
        watch: {
            prizeNumber() {
                this.freeze = true;
                this.wheelDeg = 0;

                setTimeout(() => {
                    this.freeze = false;
                }, 0);
            },
            options_receive_award: {
                handler () {
                    if (!this.continueRoll) {
                        setTimeout(() => {
                            this.get_data_api_history()
                            .then(object => {
                                this.array_receive_award = object.items
                                this.total_receive_award = object.total
                            });
                        }, 8000);
                    } else {
                        this.get_data_api_history()
                        .then(object => {
                            this.array_receive_award = object.items
                            this.total_receive_award = object.total
                        });
                    }
                },
                deep: true,
            },
        },
        build: {
            loaders: {
                vue: {
                    transformAssetUrls: {
                        audio: 'src'
                    }
                }
            },
            extend(config, ctx) {
                config.module.rules.push({
                    test: /\.(ogg|mp3|wav|mpe?g)$/i,
                    loader: 'file-loader',
                    options_receive_award: {
                        name: '[path][name].[ext]'
                    }
                })
            }
        },
        mounted() {
            this.get_data_api_award();
            this.get_data_api_history();
        }
    };
</script>
<style scoped lang="scss">

    .swal2-container .swal2-popup{
        font-family: Arial !important;
    }
    
    .lucky-wheel {
        background-size: cover;
        height: 100%;
    }

    .lucky-wheel {
        .goto-link {
            text-decoration: unset;
            color: unset;
        }
        .v-card {
            border-radius: 15px;
            border: none;
        }
        .v-card.bg-gradient {
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        }
        .v-list__group__header__prepend-icon .v-icon {
            color: red !important;
        }
        .v-card.notice {
            background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
        }
        .title {
            color: #0084F8;
        }
        .div-flag {
            width: 100%;
            height: 3px;
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            margin-top: 3px;
        }

    }


    $large: 768px;

    @mixin desktop {
        @media (min-width: #{$large}) {
            @content;
        }
    }

    .wheel-wrapper {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 300px;
        height: 300px;
        margin-top: 140px;

        @include desktop {
            width: 400px;
            height: 400px;
            margin-top: 250px;
        }
    }

    .wheel-pointer {
        border-radius: 1000px;
        position: absolute;
        transform: translate(-50%, -50%);
        text-align: center;
        line-height: 60px;
        z-index: 10;
        cursor: pointer;

        width: 60px;
        height: 60px;
        left: 50%;
        top: 50%;

        &::after {
            content: "";
            position: absolute;
            top: -32px;
            left: 50%;
            border-width: 0 8px 40px;
            border-style: solid;
            border-color: transparent transparent yellow;
            transform: translateX(-50%);
        }

        @include desktop {
            width: 100px;
            height: 100px;
            left: 50%;
            top: 50%;
        }
    }

    .award_info {
        margin-top: 300px !important;
        background-color: #f7f7f7 !important;
        border-radius: 10px;
        position: static;

        @include desktop {
            margin-top: 500px !important;
        }
    }

    .wheel-bg {
        background-size: contain;
        width: 100%;
        height: 100%;
        border-radius: 1000px;
        overflow: hidden;
        transition: transform 8s ease;

        &.freeze {
            transition: none;
            background: red;
        }
    }

    .prize-list {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
    }

    .prize-item-wrapper {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
        height: 150px;
    }

    .prize-item {
        width: 100%;
        height: 100%;
        transform-origin: bottom;

        padding: 0px 0;
        color: #fff;
        font-size: 13px;

        .prize-name {
            padding: 0px 0;
        }

        .prize-icon {
        }
    }

    .show_update_info {
        margin: 10px 0;
        display: block;
    }

</style>
