<template>
    <v-row align="center" class="ml-0" justify="center">
        <v-col cols="12" xs="12" sm="8" md="6" lg="4" xl="4">
            <v-card class="pa-3">
                <v-card-title>
                    <p class="display-1 text--primary">Đăng Nhập</p>
                </v-card-title>
                <v-card-text>
                    <v-form @keyup.native.enter="login">
                        <v-text-field
                            label="Tên đăng nhập"
                            id="username"
                            name="username"
                            prepend-icon="person"
                            type="text"
                            v-model="username"
                            :error-messages="usernameErrors"
                            required
                            outlined
                            @input="$v.username.$touch()"
                            @blur="$v.username.$touch()"
                        ></v-text-field>
                        <v-text-field
                            id="password"
                            label="Mật khẩu"
                            name="password"
                            type="password"
                            prepend-icon="lock"
                            v-model="password"
                            :error-messages="passwordErrors"
                            required
                            outlined
                            @input="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                        ></v-text-field>
                    </v-form>
<!--                    <v-card-actions class="px-8 col-11 offset-1 md-12 align-center recaptcha">-->
<!--                        <vue-recaptcha-->
<!--                                class="g-recaptcha"-->
<!--                                ref="recaptcha"-->
<!--                                @verify="onVerify"-->
<!--                                :sitekey="g_recaptcha_key">-->
<!--                        </vue-recaptcha>-->
<!--                    </v-card-actions>-->

                    <v-card-actions class="px-8 align-center">
                        <v-btn
                                xs12
                                color="primary"
                                block
                                height="60"
                                rounded
                                class="white--text font-weight-bold cols-12"
                                @click="login"
                        >Đăng Nhập
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
                <p class="text-center ml-2">
                    <router-link to="/password/email" class="">Bạn quên mật khẩu?</router-link>
                </p>
                <p class="text-center mt-4">
                    Chưa có tài khoản?
                    <router-link to="/register">Đăng Ký</router-link>
                </p>
            </v-card>
        </v-col>
        <v-overlay :value="overlay">
          <v-progress-circular
              indeterminate
              color="info"
          ></v-progress-circular>
        </v-overlay>
    </v-row>
</template>
<script>
import { required } from "vuelidate/lib/validators";
// import VueRecaptcha from 'vue-recaptcha';
import {GOOGLE_RECAPTCHA_KEY} from "~/config/constants";
export default {
    // components: { VueRecaptcha },
    data() {
        return {
            username: "",
            password: "",
            recaptcha : "",
            has_error: false,
            overlay : false,
            g_recaptcha_key : GOOGLE_RECAPTCHA_KEY
        };
    },
    computed: {
        usernameErrors() {
            const errors = [];
            if (!this.$v.username.$dirty) return errors;
            !this.$v.username.required &&
                errors.push("Vui lòng nhập tên đăng nhập.");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.required &&
                errors.push("Vui lòng nhập mật khẩu.");
            return errors;
        }
    },
    validations: {
        username: {
            required
        },
        password: {
            required
        }
    },
    methods: {
         login() {
            this.$v.$touch();
            if (this.$v.$invalid === false) {
                this.overlay = true;
                let username = this.username;
                let password = this.password;
                // let recaptcha = this.recaptcha;
                try {
                     this.$store.dispatch("auth_login", {
                         username,
                         password,
                         // recaptcha
                    }).then((resp) => {
                         localStorage.setItem('farmerId', resp.data.data.user.farmer.id)
                         localStorage.setItem('showPopupUpdate', 0);

                         if (resp.data.data.user.farmer.mobile === null
                             || resp.data.data.user.farmer.mobile === '') {
                             localStorage.setItem('showPopupUpdate', 1);
                         }

                         this.$router.push({ name: "home" });
                     });
                  setTimeout(()=>{
                    this.overlay = false;
                  },1000);

                } catch (error) {
                  setTimeout(()=>{
                    this.overlay = false;
                  },1000);
                  // this.$refs.recaptcha.reset();
                    console.log(error);

                }
            }
        },
        onVerify(response) {
            this.recaptcha = response;
        },
        callSetLangActions (event) {
             this.$i18n.locale = event.target.getAttribute('value');
        }
    }
};
</script>
<style lang="scss" scoped>
    .recaptcha {
        position: relative;
        padding-top: 35px;
        padding-bottom: 55px;
        .g-recaptcha {
            position: absolute;
            left: 50%;
            margin-left: -152px;
        }
    }

</style>
