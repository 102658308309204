<template>
  <div>
    <v-navigation-drawer app v-model="show_menu" color="deep-purple darken-4" class="white--text">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="100">
            <img
                    :src="avatar"
                    alt="avatar"
            />
          </v-avatar>
          <p class="subheading mt-1 text-center">
            {{ account_name }}
          </p>
        </v-flex>
      </v-layout>
      <v-card
              class="mx-3 user"
              max-width="344"
              flat
              color="transparent"
      >
        <v-divider class="mx-3"></v-divider>
        <v-list-item>
          <v-list-item-title class="text-center">Số dư: <span class="white--text font-weight-bold">{{ coin | formatNumber}}<font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon white--text"/></span></v-list-item-title>
        </v-list-item>
        <v-list class="white--text" rounded>
<!--          <v-divider></v-divider>-->

          <v-list-item class="pr-0 pl-1 left-menu-item home" :to="{ name : 'home'}">
            <v-list-item-icon>
              <v-icon color="white">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Trang chủ</v-list-item-title>
          </v-list-item>
          <v-list-item class="pr-0 pl-1 left-menu-item" :to="{ name : 'jobs'}">
            <v-list-item-icon>
              <v-icon color="white">mdi-credit-card-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kiếm tiền</v-list-item-title>
          </v-list-item>
          <v-list-item class="pr-0 pl-1 left-menu-item" :to="{ name : 'tool'}">
            <v-list-item-icon>
              <v-icon color="white">mdi-progress-download</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tool Auto</v-list-item-title>
          </v-list-item>
<!--          <v-list-item class="pr-0 pl-1 left-menu-item" :to="{ name : 'account_setting_security' }">-->
<!--            <v-list-item-icon>-->
<!--              <v-icon color="white">mdi-lock-question</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title>Bảo mật rút tiền</v-list-item-title>-->
<!--          </v-list-item>-->
          <v-list-item class="pr-0 pl-1 left-menu-item" :to="{ name : 'account_withdraw', params : { type : 'process' } }">
            <v-list-item-icon>
              <v-icon color="white">mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Rút tiền</v-list-item-title>
          </v-list-item>


<!--          <v-list-group-->
<!--                  no-action-->
<!--                  sub-group-->
<!--                  value="true"-->
<!--          >-->

<!--            <template v-slot:activator>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>Admin</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </template>-->

<!--            <v-list-item-->
<!--                    v-for="(admin, i) in admins"-->
<!--                    :key="i"-->
<!--                    link-->
<!--            >-->
<!--              <v-list-item-title v-text="admin[0]"></v-list-item-title>-->
<!--              <v-list-item-icon>-->
<!--                <v-icon v-text="admin[1]"></v-icon>-->
<!--              </v-list-item-icon>-->
<!--            </v-list-item>-->
<!--          </v-list-group>-->

          <v-list-item class="pr-0 pl-1 left-menu-item" :to="{ name : 'account'}">
            <v-list-item-icon>
              <v-icon color="white">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tài Khoản</v-list-item-title>
          </v-list-item>

          <v-list-item class="pr-0 pl-1 left-menu-item" :to="{ name : 'faqs' }">
            <v-list-item-icon>
              <v-icon color="white">mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hỗ trợ</v-list-item-title>
          </v-list-item>
          <v-list-item class="pr-0 pl-1 left-menu-item">
            <v-list-item-icon>
              <v-icon color="white">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="logout">Đăng xuất</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-navigation-drawer>

    <v-app-bar app flat>
      <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
<!--      <v-avatar size="48" class="ml-lg-12 hidden-md-and-down">-->
<!--        <img :src="logo"  alt="logo" />-->
<!--      </v-avatar>-->
      <v-toolbar-title class="text-capitalize">{{ title }}</v-toolbar-title>
<!--      <v-btn depressed color="transparent" :to="{ name : 'home' }" class="home white&#45;&#45;text hidden-md-and-down menu-item font-weight-bold ml-2">Trang chủ</v-btn>-->
<!--      <v-btn depressed color="transparent" :to="{ name : 'jobs' }" class="white&#45;&#45;text hidden-md-and-down menu-item font-weight-bold">Kiếm tiền</v-btn>-->
<!--      <v-btn depressed color="transparent" :to="{ name : 'account_withdraw', params : { type : 'process' } }" class="hidden-md-and-down white&#45;&#45;text menu-item font-weight-bold">Rút tiền</v-btn>-->
<!--      <v-btn depressed color="transparent" :to="{ name : 'account' }" class="hidden-md-and-down white&#45;&#45;text menu-item font-weight-bold">Tài khoản</v-btn>-->

      <v-spacer></v-spacer>
      <v-icon color="white" medium class="mr-3">mdi-bell-ring-outline</v-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-avatar class="hidden-lg-and-up">
            <img
                    v-on="on"
                    :src="avatar"
                    alt="John"
            >
          </v-avatar>
          <v-btn
                  icon
                  dark
                  v-on="on"
                  class="hidden-md-and-down"
          >
            <v-icon large>mdi-account-check</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-8">
          <v-list-item-group>
            <v-list-item class="text-center font-weight-bold">
              <v-list-item-title class="display-1">{{ account_name }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="text-center">
              <v-list-item-title>Số dư: <span class="font-weight-bold primary--text">{{ coin | formatNumber}}</span> <span class="font-weight-bold red--text">đ</span></v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="{ name : 'jobs'}">
              <v-list-item-icon>
                <v-icon>mdi-credit-card-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Kiếm tiền</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name : 'account_withdraw', params : { type : 'process' } }">
              <v-list-item-icon>
                <v-icon>mdi-credit-card</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Rút tiền</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name : 'account_setting' }">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Chỉnh sửa tài khoản</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name : 'account_manager_facebook_add' }">
              <v-list-item-icon>
                <v-icon>mdi-facebook</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Thêm tài khoản Facebook</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name : 'account_manager_instagram_add' }">
              <v-list-item-icon>
                <v-icon>mdi-instagram</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Thêm tài khoản Istagram</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name : 'account_manager_tiktok_add' }" color="dark">
              <v-list-item-icon>
                <v-icon>mdi-music-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Thêm tài khoản TikTok</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="logout">Đăng xuất</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-text-field v-if="loadingData" class="loading" color="red" loading disabled></v-text-field>
    </v-app-bar>
    <vue-snotify/>
  </div>
</template>

<script>
  import { ROUTER_DESCRIPTION } from '~/config/navbar';
  // import {FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS} from "~/store/actions.type";
  global.jQuery = require('jquery');
  let $ = global.jQuery;
  window.$ = $;
  export default {
    data() {
      return {
        show_menu: true,
        default_avatar : '/avatar/3.png',
        items: [
          { text: 'Tài Khoản', icon: 'mdi-account' },
          { text: 'Nạp Tiền', icon: 'mdi-credit-card' },
          { text: 'Thông Báo', icon: 'mdi-bell-ring-outline' },
        ],
        avatar : '/images/logo.jpg',
        logo : '/images/logo.jpg',
        account_name : 'HANA',
        withdrawHistory : [
          {
            'avatar': "https://scontent-nrt1-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/88276808_1028037104232715_3353758060991479808_o.jpg?_nc_cat=101&_nc_sid=dbb9e7&_nc_ohc=AHozNgiH9DIAX_EQI0h&_nc_ht=scontent-nrt1-1.xx&oh=7a1859c570627dc2d00f9e31101be3ce&oe=5EAF77E9",
            'farmer_name': 'HANA jimmytr',
            'form_convert': 'Ngân hàng',
            'coin': '200000',
            'time': '5 phút trước',
          },
          {
            'avatar': "https://scontent-nrt1-1.xx.fbcdn.net/v/t1.0-1/cp0/c37.8.106.106a/s50x50/1619546_302953823185540_1315402224_n.jpg?_nc_cat=106&_nc_sid=dbb9e7&_nc_ohc=AMrfqAl5IIAAX9IhKb6&_nc_ht=scontent-nrt1-1.xx&oh=291e15f4564148b4af79e264d0dbc57d&oe=5EB2E8A4",
            'farmer_name': 'Đoàn Việt',
            'form_convert': 'Ví điện tử MoMo',
            'coin': '500000',
            'time': '10 phút trước',
          },{
            'avatar': "https://scontent-nrt1-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/91600630_105270007797929_8575901963181359104_n.jpg?_nc_cat=101&_nc_sid=dbb9e7&_nc_ohc=s4bi6r6Zd6EAX_rVds6&_nc_ht=scontent-nrt1-1.xx&oh=ba2d4e346851a43e15502d2c5d313dc2&oe=5EAB4EFF",
            'farmer_name': 'Vũ Duy Tú',
            'form_convert': 'Thẻ điện thoại',
            'coin': '200000',
            'time': '15 phút trước',
          },{
            'avatar': "https://amai-like.ml/img/avatar/default.jpg",
            'farmer_name': 'kulehung',
            'form_convert': 'Ngân hàng',
            'coin': '350000',
            'time': '1 giờ trước',
          },{
            'avatar': "https://scontent-nrt1-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/88276808_1028037104232715_3353758060991479808_o.jpg?_nc_cat=101&_nc_sid=dbb9e7&_nc_ohc=AHozNgiH9DIAX_EQI0h&_nc_ht=scontent-nrt1-1.xx&oh=7a1859c570627dc2d00f9e31101be3ce&oe=5EAF77E9",
            'farmer_name': 'Triệu Đức Phương',
            'form_convert': 'Ngân hàng',
            'coin': '100000',
            'time': '1 phút trước',
          },{
            'avatar': "https://amai-like.ml/img/avatar/default.jpg",
            'farmer_name': 'Giang Sơn',
            'form_convert': 'Thẻ điện thoại',
            'coin': '300000',
            'time': '20 phút trước',
          },{
            'avatar': "https://scontent.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/49638323_1153339708161729_1377018698950246400_n.jpg?_nc_cat=102&_nc_sid=dbb9e7&_nc_ohc=l1pHfsXrNCEAX_Z5vsy&_nc_ht=scontent.xx&oh=14065a5d59e88105ef5d0c2b61a1f7f7&oe=5E93A6E4",
            'farmer_name': 'Đại Hải',
            'form_convert': 'Ví điện tử Momo',
            'coin': '100000',
            'time': '30 phút trước',
          },{
            'avatar': "https://scontent.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/17155499_1645228805786106_114495642542637145_n.jpg?_nc_cat=108&_nc_sid=dbb9e7&_nc_ohc=XnDZMO0wdBYAX9dlVkV&_nc_ht=scontent.xx&oh=a38d3980ddab5440461a34dad4208981&oe=5EA1DC49",
            'farmer_name': 'Thuỳ Đặng',
            'form_convert': 'Ví điện tử Momo',
            'coin': '50000',
            'time': '30 phút trước',
          },{
            'avatar': "https://scontent.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/45624416_2183228288561198_6998305074577408000_n.jpg?_nc_cat=101&_nc_sid=dbb9e7&_nc_ohc=WJe_s-A2QpoAX_EG2lC&_nc_ht=scontent.xx&oh=6964d1f0210a5baab116051dd6df62d6&oe=5EA1E4DD",
            'farmer_name': 'Đoàn Đạt',
            'form_convert': 'Ngân hàng',
            'coin': '500000',
            'time': '2 giờ trước',
          },{
            'avatar': "https://scontent-nrt1-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/83326000_3317676231581056_8018563517676257280_o.jpg?_nc_cat=103&_nc_sid=dbb9e7&_nc_ohc=8Dx1NHYJDcEAX_AQHBC&_nc_ht=scontent-nrt1-1.xx&oh=889451781a4c500002bd1b161325c55a&oe=5EB09D41",
            'farmer_name': 'Bình Ếch Xanh',
            'form_convert': 'Ngân hàng',
            'coin': '200000',
            'time': '3 giờ trước',
          },{
            'avatar': "https://scontent-nrt1-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/20245821_1429622913790557_2503152004870953995_n.jpg?_nc_cat=105&_nc_sid=dbb9e7&_nc_ohc=b8GSmVxABH8AX-g5sLU&_nc_ht=scontent-nrt1-1.xx&oh=e88409bb4216809fe718c4cd650aa447&oe=5EB3D659",
            'farmer_name': 'Trịnh Anh Tuấn',
            'form_convert': 'Ví điện tử Momo',
            'coin': '20000',
            'time': '3 phút trước',
          }
        ],
        withdrawShowNotify : {},
        coinIcon: '/images/coin.jpg',
      };
    },
    computed : {
      title() {
        let router_name = this.$store.getters.page;
        return ROUTER_DESCRIPTION[router_name];
      },
      loadingData() {
        return this.$store.getters.loading;
      },
      coin(){
        return this.$store.getters.coin;
      }
    },
    filters : {
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    },
    created: function() {
      // this.startInterval();
      this.initialize();
    },
    methods: {
       initialize() {
        // this.$store.dispatch(FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS).then(() => {
        //   // this.withdrawHistory = data;
        //   // console.log(data);
        // });
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.avatar = currentUser.avatar;
        this.account_name = currentUser.farmer.name;
      },
      toggleMenu() {
        this.show_menu = !this.show_menu;
      },
      logout() {
        this.$store.dispatch("auth_logout");
        this.$router.push("/login");
      },
      toHomePage() {
        this.$router.push('/');
      },
      showNotification() {
        let avatar = this.withdrawShowNotify.avatar;
        let name = this.withdrawShowNotify.farmer_name;
        let form_convert = this.withdrawShowNotify.form_convert;
        let time = this.withdrawShowNotify.time;
        let coin = (this.withdrawShowNotify.coin).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        this.$snotify.html(`
<div id="avatar">
<img src="` + avatar + `">
</div>
<div id="content">
<span class="font-weight-bold body-1">` + name + `</span><br />
<span class="caption"><i>Đã rút </i><b class="red--text"> ` + coin + ` đ </b>qua `+ form_convert +`</span>
</div>
<div id="time" class="caption primary--text"><i>`+time+`</i></div>
<div id="close">
</div>
`
  , {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          leftBottom: "leftBottom"
        });
      },
      startInterval: function () {
        // setInterval(() => {
        //   if(localStorage.getItem('withdraw_show_num') === null){
        //     localStorage.setItem('withdraw_show_num', 1);
        //   }
        //   if(parseInt(localStorage.getItem('withdraw_show_num')) <= 6) {
        //     let x = 10; // can be any number
        //     let rand = Math.floor(Math.random() * x) + 1;
        //     if (typeof this.withdrawHistory[rand] != "undefined") {
        //       this.withdrawShowNotify = this.withdrawHistory[rand];
        //       this.showNotification();
        //       localStorage.setItem('withdraw_show_num', parseInt(localStorage.getItem('withdraw_show_num')) + 1);
        //     }
        //   }
        // }, 10000);
      }
    },
    mounted: function () {
      $('.left-menu-item').each(function () {
        let pathname = window.location.pathname;
        let href = $(this).attr('href');
        if(pathname === href){
          $(this).addClass('v-list-item--active');
        }else{
          $(this).removeClass('v-list-item--active');
        }
      });
      this.$nextTick(function () {
        $('.left-menu-item').on('click', function () {
          $('.left-menu-item').removeClass('v-list-item--active');
          $(this).addClass('v-list-item--active');
        });
      })
    },
    updated: function () {
      $('.left-menu-item').each(function () {
        let pathname = window.location.pathname;
        let href = $(this).attr('href');
        if(pathname === href){
          $(this).addClass('v-list-item--active');
        }else{
          $(this).removeClass('v-list-item--active');
        }
      });
      this.$nextTick(function () {
        $('.left-menu-item').on('click', function () {
          $('.left-menu-item').removeClass('v-list-item--active');
          $(this).addClass('v-list-item--active');
        });
      })
    }
  };
</script>
<style lang="scss">
  .user {
    .v-list-item {
      cursor: pointer;
    }
    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: white !important;
    }
  }
 .v-app-bar {
   background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
   .loading {
     position: absolute;
     bottom: -24px;
     left: 0;
     width: 100%;
   }
   .v-app-bar__nav-icon{
     i {
       color: white !important;
     }
   }
   color: white !important;
 }
  .snotify{
   width: 366px !important;
  }

  .snotifyToast__inner{
    background-color: #ffffff;
    color: #484848;
    padding: 5px !important;
    flex-flow: row !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.3) !important;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    border-radius: 4px;
    max-width: 100%;
    width: 400px !important;
    min-height: 100px;
    #avatar {
      width: 25%;
      margin: auto;
      img {
        width: 55px;
        margin-top: 5px;
      }
      /*float:left;*/
    }
    #content{
      width: 75%;
      margin: auto;
      /*float: right;*/
    }
    #close {
      height: 25px;
      width: 25px;
      background-color: #ffffff;
      border-radius: 50%;
      position: relative;
    }

    #close:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "\d7";
      font-size: 22px;
      color: #777777;
      line-height: 25px;
      text-align: center;
    }

    #time{
      position: absolute;
      top: 8px;
      right: 35px;
    }
  }
  .v-navigation-drawer{
    background: linear-gradient(to bottom, #0084F8, #3B07F8);
    .v-list {
      /*border: 2px solid;*/
      /*border-radius: 42px;*/
    }

  }
  .v-coin-icon{
    width: 20px !important;
    height: 20px !important;
    background: #FB8C00;
    border-radius: 50%;
    margin-left: 3px;
    padding: 4px;
  }

</style>
