import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_USER, AUTH_REGISTER,  AUTH_SEND_MAIL, AUTH_RESET_PASSWORD } from "~/store/actions.type";
import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_ERROR,
    COMMON_SET_SNACK,
    COMMON_SET_LAYOUT
} from "~/store/mutations.type";
import ApiService from "~/common/service.api";
import AuthService from "~/common/service.api";
import TokenService from "~/common/service.token";
import {getErrorMsg} from "~/common/helper.string";
import {COMMON_SET_COIN} from "../mutations.type";

const state = {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    coin : 0
};

// getters
const getters = {
    auth_isLoggedIn: state => !!state.token,
    auth_status: state => state.status,
    coin: state => state.coin
};

// actions
const actions = {
    ["auth_maintain"]() {
        return new Promise((resolve, reject) => {
            AuthService.get("get-status-system")
                .then(resp => {
                    if( resp.data.data.maintain) {
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
                .catch(() => {
                    reject(false);
                });
        });
    },
    [AUTH_LOGIN]({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            AuthService.post("login", user)
                .then(resp => {
                    if( resp.data.success) {
                    const token = resp.data.data.token;
                    const user = resp.data.data.user;
                    localStorage.setItem("currentUser", JSON.stringify(user));
                    commit(COMMON_SET_COIN, user.farmer.coin);
                    TokenService.saveToken(token);

                    commit(COMMON_SET_SNACK, {
                        message: "Đăng nhập hệ thống thành công !",
                        type: "success"
                    });
                    // set state
                    commit(AUTH_SUCCESS, token);
                    commit(COMMON_SET_LAYOUT, "app-layout");
                    resolve(resp);
                    }else{
                        commit(AUTH_ERROR);
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "error"
                        });
                        resolve(resp);
                    }
                })
                .catch(err => {
                    // set state
                    commit(AUTH_ERROR);
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    reject(err);
                });
        });
    },
    [AUTH_REGISTER]({ commit }, user) {
        return new Promise((resolve, reject) => {
            // commit(AUTH_REQUEST);
            AuthService.post("register", user)
                .then(resp => {
                    if( resp.data.success) {
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "success"
                        });
                        resolve(resp);
                    }else {
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    // set state
                    commit(AUTH_ERROR);
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]({ commit }) {
        return new Promise((resolve) => {
            // set state
            commit(AUTH_LOGOUT);
            commit(COMMON_SET_SNACK, {
                message: "Đăng xuất thành công !",
                type: "success"
            });
            commit(COMMON_SET_LAYOUT, "simple-layout");
            resolve();
        });
    },
    [AUTH_USER]({ commit }, params) {
        return new Promise((resolve) => {
            ApiService.get("me", params)
                .then(({ data }) => {
                    if ("error" in data) {
                        resolve(false);
                    } else {
                        if( data.data.data){
                            commit(COMMON_SET_COIN, data.data.data.farmer.coin);
                        }
                        resolve(data);
                    }
                })
                .catch(() => {
                    resolve(false);
                });
        });
    },
    [AUTH_SEND_MAIL]({ commit }, params) {
        return new Promise((resolve, reject) => {
            AuthService.post("forgot-password", params)
                .then(resp => {
                    if( resp.data.success) {
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "success"
                        });
                        resolve(resp);
                    } else {
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "error"
                        });
                        resolve(resp);
                    }

                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    reject(err);
                });
        });
    },
    [AUTH_RESET_PASSWORD]({ commit }, params) {
        return new Promise((resolve, reject) => {
            AuthService.post("reset-password", params)
                .then(resp => {
                    if( resp.data.success) {
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "success"
                        });
                        resolve(resp);
                    } else {
                        commit(COMMON_SET_SNACK, {
                            message: resp.data.message,
                            type: "error"
                        });
                        resolve(resp);
                    }

                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    reject(err);
                });
        });
    },

};

// mutations
const mutations = {
    [AUTH_REQUEST](state) {
        state.status = "loading";
    },
    [AUTH_SUCCESS](state, token) {
        state.status = "success";
        state.layout = "app-layout";
        state.token = token;
        TokenService.saveToken(token);
        // set token to authorization headers
        ApiService.setHeader();
    },
    [AUTH_ERROR](state) {
        state.status = "error";
    },
    [AUTH_LOGOUT](state) {
        state.status = "";
        state.token = "";
        TokenService.destroyToken();
        ApiService.removeHeader();
        localStorage.removeItem("withdraw_show_num");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("facebook_account");
        localStorage.removeItem("coin");
    },
    [COMMON_SET_COIN](state, coin){
        state.coin = coin;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
