<template>
    <div class="account-manager-facebook-add">
      <div class="card card-default" style="background: white; padding: 0 25px">
            <v-row class="ma-md-2">
                <v-col>
                    <v-card class="text-center primary--text pa-3" href="https://www.youtube.com/watch?v=Oev_7zt8Gj8" target="_blank">
                        <v-icon color="primary">mdi-file-video</v-icon> Click để xem Video hướng dẫn
                    </v-card>
                </v-col>
            </v-row>
          <v-layout align-center row>
            <v-flex md2>
            </v-flex>
            <v-flex md10>
              <v-radio-group v-model="type" row>
                <v-radio label="Thêm account bằng uid" :input-value="type === 1" ></v-radio>
                <v-radio label="Thêm account bằng cookie" :input-value="type=== 0"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout row class="pa-0 mb-4" v-show="type === 0">
            <v-flex md2 class="mt-1">
              <label class="text-color">Nhập account theo uid</label>
            </v-flex>
            <v-flex md10>
              <v-textarea
                  solo
                  v-model="uids"
                  placeholder="Nhập danh sách uid dạng uid|pass|2fa (nếu có) . Mỗi uid 1 dòng"
                  height="400px"
              ></v-textarea>
              <div class="v-sheet theme--light explain">
                <ul>
                  <li><b>Nhập ít nhất 1 uid.</b></li>
                  <li><b>Nếu nhập nhiều uid , mỗi uid một dòng.</b></li>
                  <li class="red--text"><b>Uid phải có dạng uid|password|2fa (nếu có).</b></li>
                </ul>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row class="pa-0 mb-4" v-show="type === 1">
            <v-flex md2 class="mt-1">
              <label class="text-color">Nhập account theo cookie</label>
            </v-flex>
            <v-flex md10>
              <v-textarea
                  solo
                  v-model="cookies"
                  placeholder="Nhập danh sách cookie . Mỗi cookie 1 dòng"
                  height="400px"
              ></v-textarea>
              <div class="v-sheet theme--light explain">
                <ul>
                  <li><b>Nhâp ít nhất 1 cookie.</b></li>
                  <li><b>Nếu nhập nhiều cookie , mỗi cookie một dòng.</b></li>
                </ul>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row class="pa-0 mb-4">
            <v-flex md12>
              <v-btn width="100%" height="50px" style="background-color: #007bff"
                     class="white--text payment"
                     @click="addAccount">
                <i class="mdi mdi-cart"></i>
                Thêm tài khoản
              </v-btn>
            </v-flex>
          </v-layout>
      </div>
    </div>
</template>

<script>
import {ADD_MULTI_FACEBOOK_ACCOUNT} from '~/store/actions.type';
import Swal from "sweetalert2";

    export default {
        data() {
            return {
                postUrl : '',
                postId : null,
                fb_code : '',
                overlay : false,
                type : 0,
                actives : [],
                uids : '',
                arrayUids : [],
                cookies : '',
                arrayCookies : [],
                check : true,
            }
        },
        created() {
            this.initialize();
        },
        methods : {
            initialize(){
                let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                this.fb_code = currentUser.fb_code;
            },
            validateForm() {
                let check = true
                let message = 'Thông tin điền đang chưa chính xác';
                if (this.type === 1 && !this.cookies) {
                    check = false
                    message += '<br> Cần thêm ít nhất 1 tài khoản'
                }
                if (this.type === 0 && !this.uids) {
                    check = false
                    message += '<br> Cần thêm ít nhất 1 tài khoản'
                }
                if (this.type === 0 && !this.check) {
                    check = false
                    message += '<br> 1 Tài khoản không đúng định dạng'
                }

                return { check: check, message: message }
            },
            addAccount() {
                this.overlay = true;
                let validateForm = this.validateForm();
                if (validateForm.check) {
                    this.$store.dispatch(ADD_MULTI_FACEBOOK_ACCOUNT, {
                        type : this.type,
                        uids : JSON.stringify(this.arrayUids),
                        listCookies : JSON.stringify(this.arrayCookies)
                    }).then((data) => {
                        this.overlay = false;
                        if (typeof data !== "undefined" && data.success) {
                            this.$router.push('/account/manager/facebook');
                        }
                    });
                } else {
                    Swal.fire(
                        validateForm.message,
                        '...',
                        'error'
                    )
                }
            }
        },
        watch : {
            uids() {
                if (this.uids.trim() !== '') {
                    this.check = true;
                    this.arrayUids = this.uids.match(/[^\r\n]+/g).map(function (uid) {
                        let arr = uid.split('|');
                        if (!(arr[1])) {
                            this.check = false;
                        }
                        return {
                            'uid': arr[0],
                            'pass': arr[1] ? arr[1].trim() : '',
                            '2fa': arr[2] ? arr[2].trim() : ''
                        }
                    }.bind(this))
                } else {
                    this.arrayUids = []
                }
            },
            cookies() {
                if (this.cookies.trim() !== '') {
                    this.arrayCookies = this.cookies.match(/[^\r\n]+/g).map(function (cookie) {
                        return cookie
                    }.bind(this))
                } else {
                    this.arrayUids = []
                }
            }
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook-add {
        .v-card {
            &.facebook {
                background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            }
            &.confirm {
                background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            }
        }
    }

</style>
