<template>
    <v-snackbar
        v-model="snackbar"
        :bottom="y === 'bottom'"
        :color="color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="timeout"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
    >
        {{ text }}
        <v-btn dark text @click="snackbar = false" id="btn-close-snackbar">
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            color: "success",
            mode: "",
            snackbar: false,
            text: "Hello, I'm a snackbar",
            timeout: 4000,
            x: null,
            y: "top"
        };
    },
    created: function() {
        this.$store.watch(
            state => state.common.snack,
            () => {
                const snack = this.$store.state.common.snack;
                if (snack !== "") {
                    this.snackbar = true;
                    this.text = snack.message;
                    this.color = snack.type;
                }
            }
        );
    }
};
</script>
