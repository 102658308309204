<template>
    <div class="jobs-facebook">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col>
                    <v-card flat color="#b3d4fc">
                        <v-tabs  v-model="tab" background-color="primary" dark grow icons-and-text>
                            <v-tab key="works"> Danh sách công việc <v-icon color="white">mdi-library-books</v-icon></v-tab>
                            <v-tab key="work_done"> Việc đã làm <v-icon color="white">mdi-book</v-icon></v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="works">
                                <WorkList />
                            </v-tab-item>
                            <v-tab-item key="work_done">
                                <WorkDone />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                    </v-col>
                </v-row>
        </v-container>
    </div>
</template>

<script>
import WorkList from "~/components/jobs_facebook/WorkList";
import WorkDone from "~/components/jobs_facebook/WorkDone";
export default {
    components: {
        WorkList,
        WorkDone
    },
    data() {
        return {
            tab: this.$route.query.job_status ? 1 : 0,
        }
    },
    computed: {

    },
    methods : {
        initialize(){

        }
    }

};
</script>
