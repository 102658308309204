<template>
    <div class="account-manager-facebook-add">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col>
                    <v-card class="text-center primary--text pa-3">
                        <v-icon color="primary">mdi-file-video</v-icon> Click để xem Video hướng dẫn
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <div class="ml-3">Xác nhận tài khoản</div>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <v-col cols="8">
                    <v-text-field
                            label="Outlined"
                            single-line
                            :value="instagram_code"
                            outlined
                            readonly
                            id="verify-code"
                    ></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-btn width="120px" class="mt-1" large color="primary" @click="copyVerifyCode">Copy</v-btn>
                </v-col>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <v-col cols="12" class="red--text">Bước 1: Copy mã xác nhận và bình luận vào bài viết Instagram phía dưới để thêm tài khoản</v-col>
                <v-col cols="12">
                    <v-card flat class="text-center facebook white--text pa-3" @click="toInstagram">
                        <v-icon color="white" large>mdi-instagram</v-icon> Bình luận ngay
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <v-col cols="12" class="red--text">Bước 2: Xác nhận thêm tài khoản</v-col>
            </v-row>
            <v-row class="mx-md-5 my-2" @click="addAccountInstagram">
                <v-col cols="12">
                    <v-card flat class="text-center confirm white--text pa-3">
                        <v-icon color="white" large>mdi-check</v-icon> Xác nhận thêm tài khoản
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {
        ADD_INSTAGRAM_ACCOUNT,
        COMMON_SET_SNACK,
    } from '~/store/actions.type';
    export default {
        data() {
            return {
                postUrl : '',
                postId : null,
                instagram_code : '',
                overlay : false
            }
        },
        created() {
            this.initialize();
        },
        computed: {
        },
        methods : {
            initialize(){
                let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                this.instagram_code = currentUser.fb_code;
            },
            copyVerifyCode () {
                let testingCodeToCopy = document.querySelector('#verify-code');
                testingCodeToCopy.setAttribute('type', 'text');
                testingCodeToCopy.select();
                try {
                    document.execCommand('copy');
                    this.$store.dispatch(COMMON_SET_SNACK, {
                        message: 'Đã copy thành công !',
                        type: "success"
                    });
                } catch (err) {
                    console.log('Oops, unable to copy');
                }
            },
            toInstagram () {
                let url = "https://www.instagram.com/p/CAPg34mAxl7/";
                window.open(url, "_target");
            },
            addAccountInstagram() {
                this.overlay = true;
                this.$store.dispatch(ADD_INSTAGRAM_ACCOUNT, {
                    code: this.instagram_code
                }).then((data) => {
                    this.overlay = false;
                    if (typeof data !== "undefined" && data.success) {
                        this.$router.push('/account/manager/instagram');
                    }
                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook-add {
        .v-card {
            &.facebook {
                background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            }
            &.confirm {
                background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            }
        }
    }

</style>
