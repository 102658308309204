import Vue from 'vue';
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";

import App from './views/App.vue'
import router from './router'
import store from "./store";

import ApiService from "./common/service.api";
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import i18n from './lang/i18n';
import SocialSharing from 'vue-social-sharing';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import 'vue-snotify/styles/material.css';
// use jquery
global.jQuery = require('jquery');
const $ = global.jQuery;
window.$ = $;

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faCoins } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faCoins)

Vue.component('font-awesome-icon', FontAwesomeIcon)


// Set Vue globally
window.Vue = Vue;

// set router
Vue.router = router;
Vue.use(VueRouter);

Vue.use(Vuelidate);
Vue.use(SocialSharing);

const options = {
  global: {
    oneAtTime: true
  },
  toast: {
    position: SnotifyPosition.leftBottom
  }
};

Vue.use(Snotify, options);
// axios
ApiService.init();

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  i18n,
  Vuelidate,
  watch:{
    '$route' (to){
      this.$store.dispatch('setPage', to.name);
    }
  },
  icons: {
    iconfont: 'fa',
  },
  render: h => h(App)
}).$mount('#app')
