<template>
    <div class="home">
        <v-container fluid grid-list-lg>
            <v-row>
                <v-col>
                    <v-card class="withdraw pa-2 white--text text-center" outlined :to="{ name : 'account_withdraw', params : { type : 'process' } }">
                        <v-icon color="white" large>mdi-cash-usd</v-icon><br />
                        <span class="mt-3">Số dư</span><br />
                        <span class="mt-1">{{ coinFarmer.current_coin | formatNumber }}
                            <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon"/>
                        </span>
                    </v-card>
                </v-col>
                <v-col @click="pending_show = !pending_show, rework_show = false">
                    <v-card class="pending pa-2 white--text text-center" outlined>
                        <v-icon color="white" large>mdi-comment-processing-outline</v-icon><br />
                        <span>Chờ duyệt</span><br />
                        <span>{{ coinFarmer.total_pending | formatNumber}} <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon"/></span>
                    </v-card>
                </v-col>
                <v-col @click="rework_show = !rework_show, pending_show = false">
                    <v-card class="rework pa-2 white--text text-center" outlined>
                        <v-icon color="white" large>mdi-timetable</v-icon><br />
                        <span>Cần làm lại</span><br />
                        <span>{{ coinFarmer.total_rework | formatNumber}} <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon"/></span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="pending_show">
                <v-col>
                    <v-card class="pa-2 mx-auto row" outlined>
                        <v-col cols="4">
                            <v-list-item cols="4" three-line :to="{ name : 'jobs_facebook', query : {job_status : 2} }">
                                <v-list-item-content>
                                    <span class="body-2">Chờ duyệt</span><br />
                                    <span class="body-1 font-weight-bold red--text">{{ coinFarmer.pending.facebook | formatNumber}} đ</span><br />
                                </v-list-item-content>
                                <v-icon color="primary" class="display-2">mdi-facebook</v-icon>
                            </v-list-item>
                        </v-col>
                        <v-col cols="4">
                            <v-list-item cols="4" three-line :to="{ name : 'jobs_instagram', query : {job_status : 2} }">
                                <v-list-item-content>
                                    <span class="body-2">Chờ duyệt</span><br />
                                    <span class="body-1 font-weight-bold red--text">{{ coinFarmer.pending.instagram | formatNumber}} đ</span><br />
                                </v-list-item-content>
                                <v-icon color="orange darken-1" class="display-2">mdi-instagram</v-icon>
                            </v-list-item>
                        </v-col>
                        <v-col cols="4">
                            <v-list-item cols="4" three-line :to="{ name : 'jobs_tiktok', query : {job_status : 3} }">
                                <v-list-item-content>
                                    <span class="body-2">Chờ duyệt</span><br />
                                    <span class="body-1 font-weight-bold red--text">{{ coinFarmer.pending.tiktok | formatNumber}} đ</span><br />
                                </v-list-item-content>
                                <v-icon color="black" class="display-2">mdi-music-circle</v-icon>
                            </v-list-item>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="rework_show">
                <v-col>
                    <v-card class="pa-2 mx-auto row" outlined>
                        <v-col cols="4">
                            <v-list-item three-line :to="{ name : 'jobs_facebook', query : {job_status : 1} }">
                                <v-list-item-content>
                                    <span class="body-2">Cần làm lại</span><br />
                                    <span class="body-1 font-weight-bold red--text">{{ coinFarmer.rework.facebook | formatNumber}} đ</span><br />
                                </v-list-item-content>
                                <v-icon color="primary" class="display-2">mdi-facebook</v-icon>
                            </v-list-item>
                        </v-col>
                        <v-col cols="4">
                            <v-list-item three-line :to="{ name : 'jobs_instagram', query : {job_status : 1} }">
                                <v-list-item-content>
                                    <span class="body-2">Cần làm lại</span><br />
                                    <span class="body-1 font-weight-bold red--text">{{ coinFarmer.rework.instagram | formatNumber}} đ</span><br />
                                </v-list-item-content>
                                <v-icon color="orange darken-1" class="display-2">mdi-instagram</v-icon>
                            </v-list-item>
                        </v-col>
                        <v-col cols="4">
                            <v-list-item three-line :to="{ name : 'jobs_tiktok', query : {job_status : 1} }">
                                <v-list-item-content>
                                    <span class="body-2">Cần làm lại</span><br />
                                    <span class="body-1 font-weight-bold red--text">{{ coinFarmer.rework.tiktok | formatNumber}} đ</span><br />
                                </v-list-item-content>
                                <v-icon color="black" class="display-2">mdi-music-circle</v-icon>
                            </v-list-item>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="referral pa-2 white--text mx-auto" outlined>
                        <v-list-item three-line>
                            <v-list-item-content class="white--text">
                                <span class="body-1 font-weight-bold">Bạn càng đông tiền càng nhiều</span><br />
                                <span class="body-2">Hưởng {{ percent_presenter }}% thu nhập từ cấp dưới</span><br />
                                <router-link to="/referral" class="body-2">Click xem chi tiết</router-link>
                            </v-list-item-content>
                            <v-img max-width="80px" :src="referal_avatar" alt=""></v-img>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="racetop pa-2 white--text mx-auto" outlined>
                        <v-list-item three-line>
                            <v-list-item-content class="white--text">
                                <span class="body-1 font-weight-bold">Mua like, follow giá rẻ</span><br />
                                <span class="body-2">Hệ thống mua like, follow facebook với giá rẻ nhất thị trường. Trở thành người nổi tiếng ngay thôi!</span><br />
                                <a href="https://hacklikefb.net/" target="_blank" class="body-2 white-text">Click xem chi tiết</a>
                            </v-list-item-content>
                            <v-icon color="white" class="display-2">mdi-chart-line</v-icon>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="racetop pa-2 white--text mx-auto" outlined>
                        <v-list-item three-line>
                            <v-list-item-content class="white--text">
                                <span class="body-1" style="font-size: 13px !important;">Bảng xếp hạng</span><br />
                                <h5 class="body-2 font-weight-bold" style="font-size: 20px !important;">Đua TOP cùng Hana</h5><br />
                                <router-link to="/race-top" class="body-2">Click xem chi tiết</router-link>

                            </v-list-item-content>
                            <v-icon color="white" class="display-2">mdi-chart-bar</v-icon>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="pa-2 white--text mx-auto lucky_wheel" outlined>
                        <v-list-item three-line>
                            <v-list-item-content class="white--text">
                                <span class="headline font-weight-bold">Vòng quay may mắn</span><br/>
                                <span>Tích cực quay - vận may đến</span>
                                <div class="col-12">
                                    <v-btn
                                        :to="{ name : 'lucky_wheel', params: {type: 'run'} }"
                                        outlined
                                        class="ma-2 white--text"
                                    >
                                        Quay ngay
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                            <v-img max-width="80px" :src="lucky_wheel" alt=""></v-img>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="pa-2 white--text mx-auto ads_vnnn" outlined>
                        <v-list-item three-line>
                            <v-list-item-content class="white--text">
                                <a :href="ads_vnnn_link"
                                   target="_blank" class="ads_vnnn_link">
                                    <span>Trải nghiệm ngay game mới</span>
                                </a>
                            </v-list-item-content>
                            <a :href="ads_vnnn_link" target="_blank">
                                <v-img max-width="80px" :src="ads_vnnn_img"></v-img>
                            </a>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="pa-2 mx-auto primary--text" flat>
                        <span class="title font-weight-bold ml-3">Rút tiền gần nhất</span>
                        <v-btn @click="latest_withdrawal_list = !latest_withdrawal_list" text icon color="primary" class="float-right">
                            <v-icon large>{{ latest_withdrawal_list ? 'mdi-chevron-down' : 'mdi-chevron-right'}}</v-icon>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="latest_withdrawal_list" class="Latest-cash-withdrawal">
                <v-col cols="12" v-for="withdraw in withdrawHistory" :key="withdraw.id">
                    <v-card
                            class="mx-auto"
                            flat
                            color="white"
                    >
                        <v-list-item class="pr-3 ml-5">
                            <v-row>
                                <div class="pa-0">
                                    <v-avatar>
                                        <img  :src="withdraw.avatar" alt="avatar">
                                    </v-avatar>
                                </div>
                                <div class="ml-xs-1 ml-sm-3">
                                    <span class="primary--text body-2 font-weight-bold">{{ withdraw.farmer_name }}</span><br />
                                    <span class="body-2">Đã rút <span class="red--text body-1">{{ withdraw.money | formatNumber}}</span> qua {{ withdraw.form_convert }}</span>
                                </div>
                            </v-row>
                            <v-btn class="float-right pa-0" text>
                                <span class="overline">{{ withdraw.updated_at }}</span> <v-icon right class="headline">mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {
        FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS,
        GET_PERCENT_PRESENTER
    } from "~/store/actions.type";
    import {FARMER_GET_COIN} from "../../store/actions.type";

    global.jQuery = require('jquery');
    let $ = global.jQuery;
    window.$ = $;
export default {

    data() {
        return {
            pending_show : false,
            rework_show : false,
            latest_withdrawal_list : true,
            referal_avatar: '/images/referral.png',
            lucky_wheel: '/images/lucky_wheel_v2_2.png',
            ads_vnnn_img: '/images/ads_vnnn.png',
            ads_vnnn_link: 'https://api.adfly.vn/igr?partner=com.amaifarmer&widgetId=300',
            withdrawHistory : {},
            coinFarmer : {}
        }
    },
    created() {
        this.initialize();
        this.getCoinFarmer();
    },
    computed: {
        coin() {
            return this.$store.getters.coin;
        },
        percent_presenter() {
            return this.$store.getters.percentPresenter;
        }
    },
    filters : {
        formatNumber(num) {
            if(num > 1000){
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
            return num;
        }
    },
    methods : {
        async initialize() {
            this.withdrawHistory = await this.$store.dispatch(FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS);
            this.$store.dispatch(GET_PERCENT_PRESENTER);
        },
        getCoinFarmer(){
            this.$store.dispatch(FARMER_GET_COIN).then((data) => {
               this.coinFarmer = data;
            });
        },
    },
    updated: function () {
        this.$nextTick(function () {
            $('.v-overlay--active').css('z-index', 0);
        });
    }
};
</script>
<style scoped lang="scss">
    .swal2-container .swal2-popup{
        font-family: Arial !important;
    } 
    .home {
        .v-card {
            border-radius: 15px;
            border: none;
        }
        .v-card.withdraw {
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        }
        .v-card.pending {
            background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
        }
        .v-card.rework {
            background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
        }
        .v-card.referral {
            background: linear-gradient(66.03deg, #FF8585 9.02%, #FFA552 90.81%);
        }
        .v-card.racetop {
            background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
        }
        .v-card.version {
            background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
        }
        .v-coin-icon{
            width: 20px;
            height: 20px;
            background: #FB8C00;
            border-radius: 50%;
            margin-left: 3px;
            padding: 4px;
        }

        .lucky_wheel {
            background: linear-gradient(69.69deg, #006400 9.02%, #008080 90.81%);
        }
        .ads_vnnn {
            background: linear-gradient(69.69deg, #920606 9.02%, #ee0b0b 90.81%);
        }
        .ads_vnnn_link {
            color: #fff;
            text-decoration: none;
        }
    }

    @import '../../assets/css/custom.css';

</style>
