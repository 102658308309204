import {
  TIKTOK_JOB_FETCH_ALL,
  TIKTOK_JOB_GET_DETAIL,
  TIKTOK_JOB_EXECUTE, TIKTOK_GET_JOB_BY_STATUS,
  TIKTOK_JOB_REPORT,
  TIKTOK_DATA_POST,
  TIKTOK_DATA_USER,
  TIKTOK_JOB_FETCH_ALL_V2
} from "~/store/actions.type";
import {
  COMMON_SET_SNACK,
} from "~/store/mutations.type";
import {TikTokJobService} from "~/common/service.api";
import {TIKTOK_START_WORK} from "../actions.type";

const state = {
};

// getters
const getters = {
};

// actions
const actions = {
  [TIKTOK_JOB_FETCH_ALL]({commit}, params) {
    return TikTokJobService.query_params_string(params)
      .then(({data}) => {
        let res = data.data;
        if(data.success){
          return res;
        }
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_JOB_FETCH_ALL_V2]({commit}, params) {
    return TikTokJobService.get_tiktok_job_v2(params)
      .then(({data}) => {
        let res = data.data;
        if(data.success){
          return res;
        }
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_JOB_GET_DETAIL]({ commit }, id) {
    return new Promise((resolve) => {
      TikTokJobService.get(id)
        .then(res => {
          if( res.data.success) {
            resolve(res.data.data);
          }else{
            commit(COMMON_SET_SNACK, {
              message: res.data.message,
              type: "error"
            });
            resolve(false);
          }
        })
        .catch(err => {
          commit(COMMON_SET_SNACK, {
            message: err.response.data.message,
            type: "error"
          });
          resolve(false);
        });
    });
  },
  [TIKTOK_JOB_EXECUTE]({commit}, params) {
    return TikTokJobService.execute(params)
      .then(({data}) => {
        if(data.success){
          commit(COMMON_SET_SNACK, {
            message: data.message,
            type: "success"
          });
        }else{
          commit(COMMON_SET_SNACK, {
            message: data.message,
            type: "error"
          });
        }
        return data;
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_JOB_REPORT]({commit}, params) {
    return TikTokJobService.report(params)
      .then(({data}) => {
        if(data.success){
          commit(COMMON_SET_SNACK, {
            message: data.message,
            type: "success"
          });
          return data;
        }
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_START_WORK]({ commit }, params) {
    return new Promise((resolve) => {
      let callParams = {
        job_id: params.job_id,
        account_id: params.account_id
      }
      TikTokJobService.start_work(callParams)
        .then(res => {
          resolve(res.data.success);
          if( res.data.success) {
            commit(COMMON_SET_SNACK, {
              message: res.data.message,
              type: "success"
            });
            params.newWindow.location = params.linkJob;
          }else{
            commit(COMMON_SET_SNACK, {
              message: res.data.message,
              type: "error"
            });
            resolve(false);
          }
        })
        .catch(err => {
          commit(COMMON_SET_SNACK, {
            message: err.response.data.message,
            type: "error"
          });
          resolve(false);
        });
    });
  },
  [TIKTOK_DATA_POST]({ commit }, params) {
    return new Promise((resolve) => {
      TikTokJobService.get_data_post(params)
          .then(res => {
            if( res.data.success) {
              resolve(res.data);
            }else{
              commit(COMMON_SET_SNACK, {
                message: res.data.message,
                type: "error"
              });
              resolve(false);
            }
          })
          .catch(err => {
            commit(COMMON_SET_SNACK, {
              message: err.response.data.message,
              type: "error"
            });
            resolve(false);
          });
    });
  },
  [TIKTOK_DATA_USER]({ commit }, params) {
    return new Promise((resolve) => {
      TikTokJobService.get_data_user(params)
          .then(res => {
            if( res.data.success) {
              resolve(res.data);
            }else{
              commit(COMMON_SET_SNACK, {
                message: res.data.message,
                type: "error"
              });
              resolve(false);
            }
          })
          .catch(err => {
            commit(COMMON_SET_SNACK, {
              message: err.response.data.message,
              type: "error"
            });
            resolve(false);
          });
    });
  },
  [TIKTOK_GET_JOB_BY_STATUS]({commit}, params) {
    return TikTokJobService.get_my_jobs(params)
      .then(({data}) => {
        if(data.success){
          return data;
        }
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  }
};

// mutations
const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
