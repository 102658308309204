<template>
    <v-app>
        <Snackbar />
        <component v-bind:is="layout"></component>
    </v-app>
</template>
<script>
import AppLayout from "./layouts/AppLayout";
import SimpleLayout from "./layouts/SimpleLayout";
import Snackbar from "~/components/Snackbar";

export default {
    computed: {
        layout() {
            return this.$store.getters.common_layout;
        }
    },
    methods: {},
    components: {
        "app-layout": AppLayout,
        "simple-layout": SimpleLayout,
        Snackbar
    }
};
</script>
