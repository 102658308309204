export const AUTH_LOGIN = "auth_login";
export const AUTH_REGISTER = "auth_register";
export const AUTH_LOGOUT = "auth_logout";
export const AUTH_USER = "auth_user";
export const COMMON_SET_SNACK = "common_setSnack";
export const AUTH_SEND_MAIL = "auth_send_mail";
export const AUTH_RESET_PASSWORD = "auth_reset_password";
export const GET_PERCENT_PRESENTER = "get_percent_presenter";

// farmer
export const FARMER_SEND_EMAIL_ACCOUNT_INFO = "farmer_send_email_account_info";
export const FARMER_SEND_EMAIL_UPDATE_PASSWORD = "farmer_send_email_update_password";
export const FARMER_UPDATE_ACCOUNT_INFO = "farmer_update_account_info";
export const FARMER_CHANGE_PASSWORD = "farmer_change_password";
export const FARMER_CREATE_SECOND_PASSWORD = "farmer_set_second_password";
export const FARMER_UPDATE_SECOND_PASSWORD = "farmer_update_second_password";
export const FARMER_TURN_ON_SECOND_PASSWORD = "farmer_turn_on_second_password";
export const FARMER_TURN_OFF_SECOND_PASSWORD = "farmer_turn_off_second_password";
export const FARMER_GET_JOBS_BY_STATUS = "farmer_get_jobs_by_status";
export const FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS = "farmer_last_history_withdrawal_success";
export const FARMER_GET_COIN = "farmer_get_coin";
export const DELETE_PERMANENTLY_ACCOUNT = "delete_permanently_account";

// facebook add account
export const ADD_FACEBOOK_ACCOUNT = "add_facebook_account";
export const ADD_MULTI_FACEBOOK_ACCOUNT = 'add_multi_facebook_account';
export const FACEBOOK_UPDATE_ACCOUNT = "facebook_update_account";
export const FACEBOOK_DELETE_ACCOUNT = "facebook_delete_account";
export const FACEBOOK_SET_MAIN_ACCOUNT = "facebook_set_main_account";
export const FACEBOOK_ACCOUNT_FETCH_ALL = "facebook_account_fetch_all";
export const FACEBOOK_ACCOUNT_GET_POST_ID = "facebook_account_get_post_id";

// facebook job
export const FACEBOOK_JOB_FETCH_ALL = "facebook_job_fetch_all";
export const FACEBOOK_JOB_FETCH_ALL_V2 = "facebook_job_fetch_all_v2";
export const LIST_SEEDING = "list_seeding";
export const FACEBOOK_JOB_GET_DETAIL = "facebook_job_get_detail";
export const FACEBOOK_JOB_EXECUTE = "facebook_job_execute";
export const FACEBOOK_JOB_REPORT = "facebook_job_report";
export const FACEBOOK_START_WORK = "facebook_start_work";
//faqs
export const LIST_FAQS = "list_FAQS";

// withdraw
export const WITHDRAW_LIST_METHOD = "withdraw_lis_method";
export const WITHDRAW_DETAIL_FORM = "withdraw_detail_form";
export const WITHDRAW_POST_FORM = "withdraw_post_form";
export const WITHDRAW_POST_FORM_V2 = "withdraw_post_form_v2";
export const WITHDRAW_POST_VIEWTUB = "withdraw_post_viewtub";

// referral
export const REFERRAL_LIST_PRESENTER = 'referral_list_presenter';

/*------------- Instagram ----------------*/
// instagram add account
export const ADD_INSTAGRAM_ACCOUNT = "add_instagram_account";
export const INSTAGRAM_UPDATE_ACCOUNT = "instagram_update_account";
export const INSTAGRAM_DELETE_ACCOUNT = "instagram_delete_account";
export const INSTAGRAM_SET_MAIN_ACCOUNT = "instagram_set_main_account";
export const INSTAGRAM_ACCOUNT_FETCH_ALL = "instagram_account_fetch_all";
// export const FACEBOOK_ACCOUNT_GET_POST_ID = "facebook_account_get_post_id";

// instagram job
export const INSTAGRAM_JOB_FETCH_ALL = "instagram_job_fetch_all";
export const INSTAGRAM_JOB_FETCH_ALL_V2 = "instagram_job_fetch_all_v2";
export const INSTAGRAM_JOB_GET_DETAIL = "instagram_job_get_detail";
export const INSTAGRAM_JOB_EXECUTE = "instagram_job_execute";
export const INSTAGRAM_JOB_REPORT = "instagram_job_report";
export const INSTAGRAM_GET_MY_JOBS = "instagram_get_my_jobs";
export const INSTA_START_WORK = "insta_start_work";

// ===================== TIKTOK ========================
export const TIKTOK_ACCOUNT_FETCH_ALL = "tiktok_account_fetch_all";
export const TIKTOK_JOB_FETCH_ALL = "tiktok_job_fetch_all";
export const TIKTOK_JOB_FETCH_ALL_V2 = "tiktok_job_fetch_all_v2";
export const TIKTOK_JOB_GET_DETAIL = "tiktok_job_get_detail";
export const TIKTOK_JOB_EXECUTE = "tiktok_job_execute";
export const TIKTOK_JOB_REPORT = "tiktok_job_report";
export const TIKTOK_DELETE_ACCOUNT = "tiktok_delete_account";
export const ADD_TIKTOK_ACCOUNT = "tiktok_add_account";
export const TIKTOK_SET_MAIN_ACCOUNT = "tiktok_set_main_account";
export const TIKTOK_UPDATE_ACCOUNT = "tiktok_update_account";
export const TIKTOK_GET_JOB_BY_STATUS = "tiktok_get_job_by_status";
export const TIKTOK_START_WORK = "tiktok_start_work";
export const FETCH_DATA_POST = 'fetch_data_tiktok_video';
export const FETCH_DATA_USER = 'fetch_data_user';
export const TIKTOK_DATA_POST = 'tiktok_data_user';
export const TIKTOK_DATA_USER = 'tiktok_data_user';
export const TIKTOK_UPDATE_COOKIE = 'tiktok_update_cookie';

// LUCKY_WHEEL
export const LUCKY_WHEEL_GET_LIST_AWARD = "lucky_wheel_get_list_award";
export const LUCKY_WHEEL_PROCESS_WIN_AWARD = "lucky_wheel_process_win_award";
export const LUCKY_WHEEL_GET_HISTORY_AWARD = "lucky_wheel_get_history_award";
export const LUCKY_WHEEL_POST_NOTE_AWARD = "lucky_wheel_post_note_award";
export const LUCKY_WHEEL_GET_NOTE_AWARD = "lucky_wheel_get_note_award";

//VIEWTUB
export const ADD_VIEWTUB_ACCOUNT = "viewtub_add_account";
export const VIEWTUB_ACCOUNT_FETCH_ALL = "viewtub_fetch_all_account";
export const VIEWTUB_UPDATE_ACCOUNT = "viewtub_update_account";
export const VIEWTUB_DELETE_ACCOUNT = "viewtub_delete_account";

//TOOL_DOWNLOAD
export const TOOL_DOWNLOAD_GET_LIST = "tool_download_get_list";

//RACE_TOP
export const RACE_TOP_GET_LIST = "race_top_get_list";
