import {
    FACEBOOK_JOB_FETCH_ALL,
    FACEBOOK_JOB_FETCH_ALL_V2,
    LIST_SEEDING,
    FACEBOOK_JOB_GET_DETAIL, FACEBOOK_JOB_EXECUTE,
    LIST_FAQS, FARMER_GET_JOBS_BY_STATUS,
    WITHDRAW_LIST_METHOD, WITHDRAW_DETAIL_FORM, WITHDRAW_POST_FORM, FACEBOOK_JOB_REPORT,
    FACEBOOK_START_WORK, WITHDRAW_POST_FORM_V2, WITHDRAW_POST_VIEWTUB
} from "../../store/actions.type";
import {
    COMMON_SET_SNACK, FACEBOOK_ACCOUNTS,
} from "../../store/mutations.type";
import {FacebookJobService, WithdrawService} from "~/common/service.api";
import {getErrorMsg} from "~/common/helper.string";

const state = {
    accounts : {}
};

// getters
const getters = {
    accounts: state => state.reports
};

// actions
const actions = {
    [FACEBOOK_JOB_FETCH_ALL_V2]({commit}, params) {
      return FacebookJobService.get_list_job_v2(params)
        .then(({data}) => {
          let res = data.data;
          if(data.success){
            commit(FACEBOOK_ACCOUNTS, res);
            return res;
          }
        })
        .catch(err => {
          commit(COMMON_SET_SNACK, {
            message: err.response.data.message,
            type: "error"
          });
          // throw new Error(err);
        });
    },
    [FACEBOOK_JOB_FETCH_ALL]({commit}, params) {
        return FacebookJobService.query_params_string(params)
            .then(({data}) => {
                let res = data.data;
                if(data.success){
                    commit(FACEBOOK_ACCOUNTS, res);
                    return res;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [LIST_SEEDING]({commit}, params) {
        return FacebookJobService.get_list_seeding(params)
            .then(({data}) => {
                if(data.success){
                    return data.data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FACEBOOK_JOB_GET_DETAIL]({ commit }, params) {
        return new Promise((resolve) => {
            FacebookJobService.get(params)
                .then(res => {
                    if( res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [FACEBOOK_START_WORK]({ commit }, params) {
        return new Promise((resolve) => {
            let callParams = {
              job_id: params.job_id,
              account_id: params.account_id
            }
            FacebookJobService.start_work(callParams)
              .then(res => {
                  resolve(res.data.success);
                  if( res.data.success) {
                      params.newWindow.location = params.linkFB;
                  }else{
                      commit(COMMON_SET_SNACK, {
                          message: res.data.message,
                          type: "error"
                      });
                      resolve(false);
                  }
              })
              .catch(err => {
                  commit(COMMON_SET_SNACK, {
                      message: err.response.data.message,
                      type: "error"
                  });
                  resolve(false);
              });
        });
    },
    [FACEBOOK_JOB_EXECUTE]({commit}, params) {
        return FacebookJobService.execute(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }else{
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "error"
                    });
                }
                return data;
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FACEBOOK_JOB_REPORT]({commit}, params) {
        return FacebookJobService.report(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                    return data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [LIST_FAQS]({commit}) {
        return new Promise((resolve) => {
            FacebookJobService.get_list_faqs()
                .then(res => {
                    if( res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [FARMER_GET_JOBS_BY_STATUS]({commit}, params) {
        return FacebookJobService.get_jobs_by_status(params)
            .then(({data}) => {
                if(data.success){
                    return data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [WITHDRAW_LIST_METHOD]({commit}) {
        return new Promise((resolve) => {
            WithdrawService.get('farmer/withdrawal-moneyV2')
                .then(res => {
                    if( res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [WITHDRAW_DETAIL_FORM]({commit}, param) {
        return new Promise((resolve) => {
            WithdrawService.query_params_string('farmer/form-convert-detailV2', param)
                .then(res => {
                    if( res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [WITHDRAW_POST_FORM]({commit}, params) {
        return new Promise((resolve) => {
            WithdrawService.post('farmer/process-withdrawal-money', params)
                .then(res => {
                    if (res.data.success) {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "success"
                        });
                        resolve(res.data.data);
                    } else {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [WITHDRAW_POST_FORM_V2]({commit}, params) {
        return new Promise((resolve) => {
            WithdrawService.post('farmer/process-withdrawal-money-v2', params)
                .then(res => {
                    if (res.data.success) {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "success"
                        });
                        resolve(res.data.data);
                    } else {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [WITHDRAW_POST_VIEWTUB]({commit}, params) {
        return new Promise((resolve) => {
            WithdrawService.post('farmer/process-withdrawal-money-viewtub', params)
                .then(res => {
                    if (res.data.success) {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "success"
                        });
                        resolve(res.data.data);
                    } else {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
};

// mutations
const mutations = {
    [FACEBOOK_ACCOUNTS](state, accounts) {
        state.accounts = accounts;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
