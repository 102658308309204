<template>
    <div class="account-manager-facebook-add">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col>
                    <v-card class="text-center primary--text pa-3" href="https://www.youtube.com/watch?v=Oev_7zt8Gj8" target="_blank">
                        <v-icon color="primary">mdi-file-video</v-icon> Click để xem Video hướng dẫn
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <div class="ml-3">Xác nhận tài khoản</div>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <v-col cols="8">
                    <v-text-field
                            label="Outlined"
                            single-line
                            :value="fb_code"
                            outlined
                            readonly
                            id="verify-code"
                    ></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-btn width="120px" class="mt-1" large color="primary" @click="copyVerifyCode">Copy</v-btn>
                </v-col>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <v-col cols="12" class="red--text">Bước 1: Copy mã xác nhận và đăng bài lên trang cá nhân với nội dung là mã xác nhận vừa Copy.</v-col>
                <v-col cols="12">
                    <v-card flat class="text-center facebook white--text pa-3" @click="toFacebook">
                        <v-icon color="white" large>mdi-facebook</v-icon> Đăng Bài Lên Trang Cá Nhân
                    </v-card>
                </v-col>
            </v-row>
            <!--<v-row class="mx-md-5 my-2">-->
                <!--<v-col cols="12" class="red&#45;&#45;text">Bước 2: Nhập link bài viết chứa mã xác nhận trên tường của nick:</v-col>-->
                <!--<v-col cols="12">-->
                    <!--<v-text-field-->
                            <!--single-line-->
                            <!--outlined-->
                            <!--v-model="postUrl"-->
                            <!--name="post_link"-->
                            <!--contenteditable="true"-->
                            <!--@paste="getPortId"-->
                    <!--&gt;</v-text-field>-->
                <!--</v-col>-->
            <!--</v-row>-->
            <v-row class="mx-md-5 my-2">
                <v-col cols="12" class="red--text">Bước 2: Nhập link bài viết chứa mã xác nhận trên tường của nick:</v-col>
                <v-col cols="12">
                    <div contenteditable="true" class="allow paste_data" @input="onInput" style="border-color: rgba(0, 0, 0, 0.42); border: 1px solid rgba(0, 0, 0, 0.42); line-height: 2; border-radius: 5px; padding: 10px;">&nbsp;</div>
                </v-col>
            </v-row>
          <v-row class="mx-md-5 my-2">
            <v-col cols="12" class="red--text">
              <p>Bước 3: Xác nhận chỉ làm job share stream:</p>
              <p><i>(Bỏ qua nếu bạn không làm job này)</i></p>
            </v-col>
            <v-col cols="12">
              <ul>
                <li><b>Nếu bạn sử dụng account này cho job share stream, bạn sẽ không thể làm các job khác.</b></li>
                <li><b>Khi bạn làm job này bạn cần join vào các group có sẵn trước.</b></li>
                <li><b>Account sau khi join group 1 ngày mới có thể làm job share stream.</b></li>
                <li><b>Nên chọn những account "khoẻ" để làm job</b></li>
                <li class="red--text"><b>Giá trị của job này là cao hơn các job khác.</b></li>
              </ul>
            </v-col>
            <v-col cols="12 d-flex">
              <v-checkbox class="bot-checkbox mt-0" v-model="actives" name="bot-checkbox" :value="shareActive"
              ></v-checkbox>
              <span class="mt-1">Làm job share live stream, post bài group…</span>
            </v-col>
          </v-row>
            <v-row class="mx-md-5 my-2" @click="addAccount">
                <v-col cols="12">
                    <v-card flat class="text-center confirm white--text pa-3">
                        <v-icon color="white" large>mdi-check</v-icon> Xác nhận thêm tài khoản
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import {ADD_FACEBOOK_ACCOUNT, COMMON_SET_SNACK} from '~/store/actions.type';
import {FACEBOOK_ACCOUNT_GET_POST_ID} from "../../../store/actions.type";

    export default {
        data() {
            return {
                postUrl : '',
                postId : null,
                fb_code : '',
                overlay : false,
                shareActive : 1,
                actives : []
            }
        },
        created() {
            this.initialize();
        },
        computed: {
        },
        methods : {
            initialize(){
                let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                this.fb_code = currentUser.fb_code;
            },
            copyVerifyCode () {
                let testingCodeToCopy = document.querySelector('#verify-code');
                testingCodeToCopy.setAttribute('type', 'text');
                testingCodeToCopy.select();
                try {
                    document.execCommand('copy');
                    this.$store.dispatch(COMMON_SET_SNACK, {
                        message: 'Đã copy thành công !',
                        type: "success"
                    });
                } catch (err) {
                    console.log('Oops, unable to copy');
                }
            },
            toFacebook () {
                let url = "https://www.facebook.com/me";
                window.open(url, "_target");
            },
            onInput(e) {
                var pastedData = e.target.innerText;
                console.log('pastedData:'+pastedData);

                this.$store.dispatch(FACEBOOK_ACCOUNT_GET_POST_ID, {facebook_link: pastedData}).then((data) => {
                    console.log("data:"+data);

                    if(data === null || typeof data === "undefined" || data === null){
                        this.$store.dispatch('common_setSnack', {
                            message : 'Link bài viết không hợp lệ !',
                            type : 'error'
                        });
                    }else{
                        this.postId = this.postUrl = data;
                        // this.$store.dispatch('common_setSnack', {
                        //     // message : 'Bạn đã nhập link bài viết thành công !',
                        //     // message : '',
                        //     // type : 'success'
                        // });
                    }
                });
            },
            // getPortId(evt) {
            //     // let clipboardData, pastedData;
            //     // let pastedData;
            //     evt.stopPropagation();
            //     evt.preventDefault();
            //
            //     const pastedData = (evt.originalEvent || evt).clipboardData.getData('text/plain');
            //     window.document.execCommand('insertText', false, pastedData);
            //
            //     console.log("pastedData:"+pastedData);
            //
            //
            //     this.$store.dispatch(FACEBOOK_ACCOUNT_GET_POST_ID, {facebook_link: pastedData}).then((data) => {
            //         console.log("data:"+data);
            //
            //         if(data === null || typeof data === "undefined" || data === null){
            //             this.$store.dispatch('common_setSnack', {
            //                 message : 'Link bài viết không hợp lệ !',
            //                 type : 'error'
            //             });
            //         }else{
            //             this.postId = this.postUrl = data;
            //             this.$store.dispatch('common_setSnack', {
            //                 message : 'Hệ thống tự động lấy ID bài viết cho bạn !',
            //                 type : 'success'
            //             });
            //         }
            //     });
            //     /* eslint-disable */
            //     // let rex = /(?:(?:http|https):\/\/(?:www|m|mbasic|business)?\.?(?:facebook|fb)\.com\/)(?:photo(?:\.php|s)|permalink\.php|video\.php|story\.php|media|watch\/|questions|notes|[^\/]+\/(?:activity|posts|videos|photos))[\/?](?:fbid=|story_fbid=|id=|b=|v=|)([0-9]+|[^\/]+\/[\d]+)/gm;
            //     // postId = rex.exec(pastedData);
            //     // let tmpUrl = new URL(pastedData);
            //     // postId = tmpUrl.searchParams.get('story_fbid');
            //     // if (postId === null) {
            //     //     postId = pastedData.split('/')[5].split('?')[0];
            //     // }
            //     // postId = pastedData.search(/^https:\/\/www\.facebook\.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^\/]+\/(activity|posts))[\/?].*$/gm);
            //     // if(postId.includes('?')){
            //     //     postId = postId.split('?')[0]
            //     // }
            //
            // },
            addAccount() {
                console.log("this.postId:"+this.postId);

                this.overlay = true;
                this.$store.dispatch(ADD_FACEBOOK_ACCOUNT,{
                    fb_code : this.fb_code,
                    post_id : this.postId,
                    actives : JSON.stringify(this.actives)
                }).then((data) => {
                    this.overlay = false;
                    if(typeof data !== "undefined" && data.success){
                        this.$router.push('/account/manager/facebook');
                    }
                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook-add {
        .v-card {
            &.facebook {
                background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            }
            &.confirm {
                background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            }
        }
    }

</style>
