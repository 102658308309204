<template>
    <v-card flat class="pa-3 work-done">
        <v-row @click="listAccountShow = !listAccountShow" v-if="accounts.length !== 0">
            <v-col>
                <v-card>
                    <v-card-text class="font-weight-bold">
                        <v-row>
                            <v-col class="py-0">
                                <span>Chọn tài khoản</span><br/>
                                <span class="primary--text">Kiếm tiền</span>
                                <v-icon color="primary" class="ml-1">mdi-arrow-right-bold-circle-outline</v-icon>
                            </v-col>
                            <v-col class="py-0 text-right">
                                <v-avatar>
                                    <img :src="profilePic" alt="">
                                </v-avatar>
                                {{ accountName }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row><!---->
        <v-row v-if="accounts.length === 0">
            <v-col cols="12">
                <v-card class="version pa-2 mx-auto text-center" color="primary"
                        :to="{ name : 'account_manager_instagram_add' }">
                    <v-icon class="pl-3 mt-1 float-left" color="white" medium>mdi-account-plus
                    </v-icon>
                    <span class="title font-weight-bold ml-3 white--text">Thêm tài khoản</span>
                </v-card>
            </v-col>
            <v-col cols12 class="text-center">
                <h4>Vui lòng thêm tài khoản Facebook để có thể kiếm tiền... </h4>
            </v-col>
        </v-row>
        <v-row v-if="listAccountShow" class="list-account">
            <v-col>
                <v-card class="mx-auto" tile flat>
                    <v-list>
                        <v-list-item-group color="primary">
                            <v-list-item
                                    v-for="(account, i) in accounts"
                                    :key="i"
                                    @click="accountSelected(account)"
                            >
                                <v-list-item-icon>
                                    <v-avatar class="seeding">
                                        <img :src="account.profile_pic_url" alt="">
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ account.username }}<br>
                                        <span class="caption">Việc đã làm trong ngày</span>
                                        <v-btn class="mx-2" fab dark x-small color="primary">
                                            {{ account.job_count_today }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon v-if="account.profile_pic_url == profilePic">
                                    <v-icon>mdi-check</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <div v-if="!listAccountShow && accounts.length !== 0">
            <v-row>
                <v-col cols="12" md="4">
                    <v-select
                            v-model="jobStatus"
                            :items="status"
                            item-text="text"
                            item-value="code"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            max-width="290"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :value="computedStartDateFormattedMomentjs"
                                    clearable
                                    label="Từ ngày"
                                    readonly
                                    v-on="on"
                                    @click:clear="startDate = null"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="startDate"
                                locale="vi_VN"
                                @change="menu1 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                    <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            max-width="290"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :value="computedEndDateFormattedMomentjs"
                                    clearable
                                    label="Đến ngày"
                                    readonly
                                    v-on="on"
                                    @click:clear="endDate = null"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="endDate"
                                :min="startDate"
                                locale="vi_VN"
                                @change="menu2 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" v-for="job in jobs" :key="job.id">
                    <router-link :to="{ name : 'jobs_instagram_log_detail', params : { id : job.id } }">
                        <v-row class="job">
                            <v-col cols="3" md="1" class="text-center pa">
                                <div style="border-right: 1px solid #777777">
                                    <v-avatar size="36">
                                        <img :src="job.icon" alt="">
                                    </v-avatar>
                                    <h6 class="body-1 font-weight-bold mt-1">
                                        <span><span class="hold-prices success--test" v-if="job.status === 3">{{ '+'+job.coin }}</span></span>
                                        <span><span class="hold-prices black--text" v-if="job.status !== 3">{{ job.coin }}</span></span>
                                        <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon white--text"/>
                                    </h6>
                                </div>
                            </v-col>
                            <v-col cols="9" md="11" class="caption job-info px-0">
                                <div class="black--text">
                                    <span class="black--text" v-if="job.status === 2">Ngày thực hiện:
                                        <span class="font-weight-bold">{{ job.date_work }}</span>
                                        <span class="float-right">
                                            {{ job.time_remain }}
                                            <v-icon small>mdi-arrow-right</v-icon>
                                        </span>
                                    </span>
                                    <span class="black--text" v-if="job.status === 3">Đã duyệt:
                                        <span class="font-weight-bold">{{ job.date_work }}</span>
                                        <span class="float-right">
                                            {{ job.time_remain }}
                                            <v-icon small>mdi-arrow-right</v-icon>
                                        </span>
                                    </span>
                                    <span class="black--text" v-if="job.status !== 3 && job.status !== 2">Ngày duyệt:
                                        <span class="font-weight-bold">{{ job.date_work }}</span>
                                        <span class="float-right">
                                            {{ job.time_remain }}
                                            <v-icon small>mdi-arrow-right</v-icon>
                                        </span>
                                    </span>
                                    <br/>
                                    <span class="nowrap primary--text">{{ job.seeding_title }}</span>
                                    <span v-if="params.status === ''"
                                                                                     class="float-right status"
                                                                                     :class="'status-'+job.status">{{ job.status | getStatusText }}</span><br/>
                                    <span>{{ job.content | jobContentFormat }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
                <v-col class="text-center" v-if="jobs.length === 0">
                    <h3 class="body-1">Không tìm thấy job !</h3>
                </v-col>
            </v-row>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
    import moment from 'moment';
    import {INSTAGRAM_ACCOUNT_FETCH_ALL, INSTAGRAM_GET_MY_JOBS} from "~/store/actions.type";

    export default {
        data() {
            return {
                listAccountShow: false,
                status: [
                    {code: '', text: 'Tất cả'},
                    {code: 1, text: 'Cần làm lại'},
                    {code: 2, text: 'Chờ duyệt'},
                    {code: 3, text: 'Đã duyệt'},
                    {code: 4, text: 'Làm lỗi'},
                ],
                startDate: moment().utcOffset('+0700').format('YYYY-MM-DD'),
                endDate: moment().utcOffset('+0700').format('YYYY-MM-DD'),
                menu1: false,
                menu2: false,
                jobStatus: {code: '', text: 'Tất cả'},
                coinSvg: '/images/coin.svg',
                likePageGif: '/images/like_page.gif',
                accounts: {},
                profilePic: '',
                accountName: '',
                jobs: {},
                params: {},
                overlay: false
            }
        },
        filters: {
            getStatusText(code) {
                if (code === 1) {
                    return 'Cần làm lại';
                }
                if (code === 2) {
                    return 'Chờ duyệt';
                }
                if (code === 3) {
                    return 'Đã duyệt';
                }
                if (code === 4) {
                    return 'Làm lỗi';
                }
            },
            dateFormat(date){
                return moment(date).format('DD-MM-YYYY');
            },
            jobContentFormat(content){
                let cutoff = 30;
                if(typeof content !== "undefined" && content.length > 30){
                    return content.substring(0, cutoff)+'...';
                }
                return content;
            }
        },
        watch: {
            startDate() {
                this.params.start_date = this.changeFormerDate(this.startDate);
                this.initialize();
            },
            endDate() {
                this.params.end_date = this.changeFormerDate(this.endDate);
                this.initialize();
            },
            jobStatus: {
                handler(val) {
                    this.params.status = val;
                    this.initialize();
                },
                deep: true
            },
        },
        created() {
            this.setParams();
            this.facebookAccountFetchAll();
        },
        computed: {
            computedStartDateFormattedMomentjs() {
                return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
            },
            computedEndDateFormattedMomentjs() {
                return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
            },
        },
        methods: {
            async initialize() {
                if(this.params.instagram_account_id){
                    this.overlay = true;
                    await this.$store.dispatch(INSTAGRAM_GET_MY_JOBS, this.params).then(({data}) => {
                       this.jobs = data;
                    });
                    this.overlay = false;
                }
            },
            setParams() {
                let date = new Date();
                date = new Date(date.setDate(date.getDate() -10));
                this.startDate = date.toISOString().substr(0, 10);
                let jobStatusCode = parseInt(this.$route.query.job_status);
                if(jobStatusCode){
                    this.jobStatus = jobStatusCode;
                }
                this.params = {
                    'instagram_account_id': localStorage.getItem('instagram_account'),
                    'status': jobStatusCode ? jobStatusCode : '',
                    'start_date': this.changeFormerDate(this.startDate),
                    'end_date': this.changeFormerDate(this.endDate)
                };
                // console.log(this.params.status);
            },
            facebookAccountFetchAll() {
                this.$store.dispatch(INSTAGRAM_ACCOUNT_FETCH_ALL)
                    .then(({data}) => {
                        this.accounts = data;
                        if(data.length === 0) return;
                        let main_account = data.filter(o => o.is_main == 1)[0];
                        this.profilePic = main_account.profile_pic_url;
                        this.accountName = main_account.username;
                    });
            },
            accountSelected(account) {
                this.profilePic = account.profile_pic_url;
                this.accountName = account.username;
                localStorage.setItem('instagram_account', account.id);
                this.listAccountShow = false;
                this.params.instagram_account_id = account.id;
                this.initialize();
            },
            changeFormerDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            getStatusText(code) {
                if (code === 1) {
                    return 'Cần làm lại';
                }
                if (code === 2) {
                    return 'Chờ duyệt';
                }
                if (code === 3) {
                    return 'Đã duyệt';
                }
                if (code === 4) {
                    return 'Làm lỗi';
                }
            }
        }
    };
</script>
<style scoped lang="scss">
    .job {
        background-color: #ffffff;
        .v-avatar{
            height: 40px !important;
        }
        .job-info {
            position: relative;

            div {
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .status {
                color: white;
                padding: 1px 10px;
                border-radius: 5px;
                margin-right: 20px;

                &.status-1 {
                    background-color: #1E88E5;
                }

                &.status-2 {
                    background-color: #d19015;
                }

                &.status-3 {
                    background-color: #5E35B1;
                }

                &.status-4 {
                    background-color: #F57C00;
                }
            }
        }
    }

    .v-chip {
        &.share {
            background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
        }
    }
    a {
        text-decoration: none;
    }
</style>
