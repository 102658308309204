import {
    ADD_INSTAGRAM_ACCOUNT,
    INSTAGRAM_ACCOUNT_FETCH_ALL,
    INSTAGRAM_DELETE_ACCOUNT,
    INSTAGRAM_SET_MAIN_ACCOUNT,
    INSTAGRAM_UPDATE_ACCOUNT
} from "../../store/actions.type";
import {
    COMMON_SET_SNACK,
} from "../../store/mutations.type";
import {InstagramAccountService} from "../../common/service.api";

const state = {

};

// getters
const getters = {
    // editMemoStatus: state => state.editMemoStatus,
};

// actions
const actions = {
    [INSTAGRAM_ACCOUNT_FETCH_ALL]({commit}) {
        return InstagramAccountService.get()
          .then(({data}) => {
              return data;
          })
          .catch(err => {
              commit(COMMON_SET_SNACK, {
                  message: err.response.data.message,
                  type: "error"
              });
              // throw new Error(err);
          });
    },

    [ADD_INSTAGRAM_ACCOUNT]({commit}, params) {
        return InstagramAccountService.create(params)
            .then((data) => {
                console.log(data);
                commit(COMMON_SET_SNACK, {
                    message: `Chúc mừng bạn đã thêm tài khoản Instagram thành công !`,
                    type: "success"
                });
                return data.data;
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [INSTAGRAM_DELETE_ACCOUNT]({commit}, params) {
        return InstagramAccountService.destroy(params.id, {'password': params.password})
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
                return true
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [INSTAGRAM_UPDATE_ACCOUNT]({commit}, id) {
        return InstagramAccountService.update(id)
            .then(() => {
                commit(COMMON_SET_SNACK, {
                    message: `Cập nhật thông tin tài khoản thành công !`,
                    type: "success"
                });
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [INSTAGRAM_SET_MAIN_ACCOUNT]({commit}, id) {
        return InstagramAccountService.set_main(id)
            .then(() => {
                commit(COMMON_SET_SNACK, {
                    message: `Thiết lập tài khoản chính thành công !`,
                    type: "success"
                });
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    }
};

// mutations
const mutations = {
    // [COMMON_SET_PERCENT_PRESENTER](state, percentPresenter){
    //     state.percentPresenter = percentPresenter;
    // }
};

export default {
    state,
    getters,
    actions,
    mutations
};
