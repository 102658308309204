export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
};

export const replaceNewLine = str => {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

export const getErrorMsg = (err) => {
  let message = "Đã có một vài lỗi xảy ra, vui lòng thư lại sau !";
  if (err.response.data.message) {
    message = err.response.data.message;
  }
  // if (err.response.status === 422) {
  //   let error = Object.values(err.response.data.errors);
  //   error.forEach(function (val, ind) {
  //     if (ind === 0) {
  //       message = val[0];
  //     }
  //   });
  // }
  return message;
};
export const formatCurrency= (value) => {
  let floatValue = (value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return floatValue.substring(0, floatValue.length - 2);
};
export default { truncateString, replaceNewLine, getErrorMsg, formatCurrency };
