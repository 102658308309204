export const AUTH_REQUEST = "auth_request";
export const AUTH_SUCCESS = "auth_success";
export const AUTH_ERROR = "auth_error";
export const AUTH_LOGOUT = "auth_logout";
export const COMMON_SET_SNACK = "common_setSnack";
export const COMMON_SET_LAYOUT = "common_setLayout";
export const COMMON_SET_PAGE_TITLE = "common_setPageTitle";
export const COMMON_SET_COIN = "common_set_coin";
export const COMMON_SET_PERCENT_PRESENTER = "common_set_percent_presenter";

// add facebook account
export const ADD_FACEBOOK_ACCOUNT = 'add_facebook_account';
export const FACEBOOK_ACCOUNTS = 'facebook_accounts';

