<template>
    <div class="jobs">
        <v-container fluid grid-list-lg>
            <v-row class="facebook ma-2 pa-5">
                <div class="pa-0">
                    <v-avatar class="mt-1">
                        <img width="20px" :src="facebook_img" alt="John">
                    </v-avatar>
                </div>
                <div class="ml-3 white--text">
                    <span class="headline font-weight-bold">Facebook</span><br/>
                    <span class="body-2">{{ account_fb_number }} tài khoản làm việc</span>
                </div>
                <div class="col-12">
                    <v-btn
                            :to="{ name : 'jobs_facebook' }"
                            outlined
                            class="ma-2 white--text"
                    >
                        Kiếm tiền ngay
                        <v-icon color="white" right dark>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                </div>
                <v-icon color="primary" class="facebook-icon">mdi-facebook</v-icon>
            </v-row>
            <v-row class="instagram ma-2 pa-5">
                <div class="pa-0">
                    <v-avatar class="mt-1">
                        <img width="20px" :src="instagram_img" alt="John">
                    </v-avatar>
                </div>
                <div class="ml-3 white--text">
                    <span class="headline font-weight-bold">Instagram</span><br/>
                    <span class="body-2">{{ account_ins_number }} tài khoản làm việc</span>
                </div>
                <div class="col-12">
                    <v-btn
                            :to="{ name : 'jobs_instagram' }"
                            outlined
                            class="ma-2 white--text"
                    >
                        Kiếm tiền ngay
                        <v-icon color="white" right dark>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                </div>
                <v-icon color="orange darken-1" class="facebook-icon">mdi-instagram</v-icon>
            </v-row>
            <v-row class="tiktok ma-2 pa-5">
                <div class="pa-0">
                    <v-avatar class="mt-1">
                        <img width="20px" :src="tiktok_img" alt="John">
                    </v-avatar>
                </div>
                <div class="ml-3 white--text">
                    <span class="headline font-weight-bold">TikTok</span><br/>
                    <span class="body-2">{{ account_tiktok_number || 0 }} tài khoản làm việc</span>
                </div>
                <div class="col-12">
                    <v-btn
                            :to="{ name : 'jobs_tiktok' }"
                            outlined
                            class="ma-2 white--text"
                    >
                        Kiếm tiền ngay
                        <v-icon color="white" right dark>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                </div>
                <v-icon color="dark" class="facebook-icon">mdi-music-circle</v-icon>
            </v-row>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
  import {FACEBOOK_ACCOUNT_FETCH_ALL, INSTAGRAM_ACCOUNT_FETCH_ALL, TIKTOK_ACCOUNT_FETCH_ALL} from "~/store/actions.type";

  export default {
    data() {
      return {
        facebook_img: '/images/facebook.png',
        instagram_img: '/images/instagram.jpg',
        tiktok_img: '/images/tiktok.jpg',
        latest_withdrawal_list: true,
        account_fb_number: 0,
        account_ins_number: 0,
        account_tiktok_number: 0,
        overlay: false
      }
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        this.overlay = true;
        let facebookAccount = await this.$store.dispatch(FACEBOOK_ACCOUNT_FETCH_ALL);
        this.account_fb_number = facebookAccount.data.length;
        let instagramAccount = await this.$store.dispatch(INSTAGRAM_ACCOUNT_FETCH_ALL);
        this.account_ins_number = instagramAccount.data.length;
        let tiktokAccount = await this.$store.dispatch(TIKTOK_ACCOUNT_FETCH_ALL);
        this.account_tiktok_number = tiktokAccount.data.length;
        this.overlay = false;

      }
    }
  };
</script>
<style scoped lang="scss">
    .jobs {
        .instagram {
            background: linear-gradient(77.89deg, #FD3F36 9.02%, #FDE259 90.81%);
            border-radius: 15px;
            position: relative;

            .facebook-icon {
                right: 30px;
                bottom: 30px;
                font-size: 90px;
                position: absolute;
            }
        }

        .facebook {
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            border-radius: 15px;
            position: relative;

            .facebook-icon {
                right: 30px;
                bottom: 30px;
                font-size: 90px;
                position: absolute;
            }
        }

        .tiktok {
            background: linear-gradient(77.89deg, #000000 9.02%, #33001a 90.81%);
            border-radius: 15px;
            position: relative;

            .facebook-icon {
                right: 30px;
                bottom: 30px;
                font-size: 90px;
                position: absolute;
            }
        }
    }

</style>
