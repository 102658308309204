<template>
    <div class="account-manager-facebook">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col cols="12">
                    <h4 class="primary--text title">Các tài khoản</h4>
                </v-col>
                <v-col cols="12">
                    <v-card class="mx-auto">
                        <v-card-text>
                            <div class="primary--text body-2">
                                <v-icon color="primary">mdi-information</v-icon>
                                Quan trọng.
                            </div>
                            <div class="body-2 mt-1">Chọn tài khoản facebook chính của bạn để hệ thống có thể gửi những
                                thông báo quan trọng tới tài khoản facebook của bạn qua Messenger.
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card
                            class="mx-auto py-3"
                            color="white"
                            flat
                    >
                        <v-expansion-panels focusable>
                            <v-expansion-panel
                                    v-for="account in accounts" :key="account.id"
                            >
                                <v-expansion-panel-header>
                                    <v-row class="account-list">
                                        <v-col cols="3" md="1" class="align-content-center">
                                            <v-avatar>
                                                <img width="50px" :src="account.profile_pic" alt="John">
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="9" md="5" class="show-info">
                                            <span class="primary--text body-1 font-weight-bold">{{ account.name }}
                                                <span class="caption ml-1 pa-1 black--text" style="background: #f8ff00;border-radius: 5px;" v-if="account.checkpoint">Checkpoint</span>
                                                <span class="is-main-account caption ml-1 pa-1 white--text" v-if="account.is_main">Tài khoản chính</span>
                                            </span>
                                            <br/>
                                            <span class="caption font-weight-bold">cập nhật thông tin tài khoản</span><br/>
                                            <v-slider
                                                    min="1"
                                                    max="5"
                                                    :value="account | infoAccountPublic"
                                                    :thumb-size="30"
                                                    width="300"
                                                    thumb-label="always"
                                                    readonly
                                            >
                                                <template v-slot:thumb-label="{ value }">
                                                    {{ value +'/'+ 5 }}
                                                </template>
                                            </v-slider>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="account-info">
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-account-key</v-icon>
                                                Giới tính
                                            </v-col>
                                            <v-col cols="6" class="float-left primary--text">{{ account.gender === 1 ? 'Nam' : 'Nữ' }}</v-col>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-cake</v-icon>
                                                Tuổi
                                            </v-col>
                                            <v-col cols="6" class="float-left primary--text">{{ account.birthday !== null ? account.birthday : '___' }}</v-col>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-account-multiple-outline</v-icon>
                                                Bạn bè
                                            </v-col>
                                            <v-col cols="6" class="float-left primary--text">{{ account.friends }}</v-col>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-earth</v-icon>
                                                Theo dõi
                                            </v-col>
                                            <v-col cols="6" class="float-left primary--text">{{ account.is_public === 1 ? 'Công khai' : 'Riêng tư' }}</v-col>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-home-variant</v-icon>
                                                Địa chỉ
                                            </v-col>
                                            <v-col cols="6" class="float-left primary--text">{{ account.address !== null ? account.address : '___' }}</v-col>
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2 text-center account-action" @click="setMainAccount(account.id)">Đặt làm tài khoản chính</v-col>
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2 text-center account-action" @click="updateAccount(account.id)">Cập nhật thông tin tài khoản</v-col>
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2 text-center account-action" @click="redirectProfile(account.link_profile)">Xem trang cá nhân</v-col>
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2 text-center account-action" @click="deleteAccount(account.id)">Xóa tài khoản</v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-row>
                          <v-col cols="6">
                            <v-card class="version pa-2 mx-auto text-center" color="primary"
                                    :to="{ name : 'account_manager_facebook_add' }">
                              <v-icon class="pl-3 mt-1 float-left" color="white" medium>mdi-account-plus
                              </v-icon>
                              <span class="title font-weight-bold ml-3 white--text">Thêm tài khoản</span>
                            </v-card>
                          </v-col>
                          <v-col cols="6">
                            <v-card class="version pa-2 mx-auto text-center" color="warning"
                                    :to="{ name : 'account_manager_facebook_multi_add' }">
                              <v-icon class="pl-3 mt-1 float-left" color="white" medium>mdi-account-plus
                              </v-icon>
                              <span class="title font-weight-bold ml-3 white--text">Thêm tài khoản hàng loạt</span>
                            </v-card>
                          </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {FACEBOOK_ACCOUNT_FETCH_ALL, COMMON_SET_SNACK, AUTH_USER} from "~/store/actions.type";
    import {FACEBOOK_SET_MAIN_ACCOUNT, FACEBOOK_UPDATE_ACCOUNT, FACEBOOK_DELETE_ACCOUNT} from "~/store/actions.type";
    import Swal from "sweetalert2";
    import {SWAL_SETTING_CONFIRM_DELETE} from "~/config/constants"

    global.jQuery = require('jquery');
    let $ = global.jQuery;
    window.$ = $;
    export default {
        data() {
            return {
                show_account_info: false,
                facebook_img: '/images/facebook.png',
                accounts: null,
                slider : 2,
                security: 0,
            }
        },
        created() {
            this.facebookAccountFetchAll();
            this.initialize();
        },
        filters: {
          infoAccountPublic(account) {
              let k = 0;
              if(account.gender){
                  k = k+1;
              }
              if(account.birthday){
                  k = k+1;
              }
              if(account.friends){
                  k = k+1;
              }
              if(account.is_public){
                  k = k+1;
              }
              if(account.address){
                  k = k+1;
              }
            return k;
          }
        },
        methods: {
            async initialize() {
                let currentUser = await this.$store.dispatch(AUTH_USER);
                this.security = currentUser.data.data.security;
            },
            facebookAccountFetchAll() {
                this.$store.dispatch(FACEBOOK_ACCOUNT_FETCH_ALL)
                    .then(({data}) => {
                        this.accounts = data;
                    })
                .catch(() => {
                   this.$store.dispatch(COMMON_SET_SNACK, {
                       message : 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
                       type : 'error'
                   });
                });
            },
            updateAccount(account_id){
                this.$store.dispatch(FACEBOOK_UPDATE_ACCOUNT, account_id).then(()=>{
                    this.facebookAccountFetchAll();
                });
            },
            redirectProfile(link_profile){
                window.open(link_profile, "_target");
            },
            deleteAccount(account_id){
                switch (this.security) {
                    default:
                        Swal.fire(SWAL_SETTING_CONFIRM_DELETE('facebook')).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Hãy nhập mật khẩu của bạn',
                                    input: 'password',
                                    inputAttributes: {
                                        autocapitalize: 'off',
                                        required: 'true'
                                    },
                                    validationMessage: 'Bạn cần nhập mật khẩu',
                                    showCancelButton: true,
                                    confirmButtonText: 'Xác nhận',
                                    cancelButtonText: 'Bỏ qua',
                                    showLoaderOnConfirm: true,
                                    preConfirm: (password) => {
                                        let params = {
                                            'password' : password,
                                            'id' : account_id
                                        }
                                        return this.$store.dispatch(FACEBOOK_DELETE_ACCOUNT, params);
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                }).then((result) => {
                                    if (result.value === true) {
                                        this.facebookAccountFetchAll()
                                    }
                                })
                            }
                        })
                }
            },
            setMainAccount(account_id){
                this.$store.dispatch(FACEBOOK_SET_MAIN_ACCOUNT, account_id).then(() => {
                    this.facebookAccountFetchAll();
                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook {
        .v-avatar {
            width: 65px !important;
            height: 65px !important;

            img {

            }
        }

        .account-action {
            cursor: pointer;
        }

        .v-list-item {
            position: relative;
            .chevron-right {
                position: absolute;
                top: 40px;
                right: 0px;
            }
        }

        .is-main-account{
            border-radius: 5px;
            background-color:#EF5350;
        }

    }

</style>
