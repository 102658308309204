<template>
    <div class="account-manager-facebook-add">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col>
                    <v-card class="text-center primary--text pa-3" href="#"
                            target="_blank">
                        <v-icon color="primary">mdi-file-video</v-icon>
                        Click để xem Video hướng dẫn
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <div class="ml-3">Xác nhận tài khoản</div>
            </v-row>
            <v-row class="mx-md-5 my-2">
                <v-col cols="12" class="red--text">
                    Nhập token bạn lấy từ app ViewTub ( Lưu ý token chỉ có thể tồn tại trong vòng 5 đến 10 phút)
                </v-col>
                <v-col cols="12">
                    <v-text-field
                            single-line
                            :value="token"
                            v-model="token"
                            outlined
                            placeholder="token"
                            id="verify-code"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mx-md-5 my-2">
                <v-col cols="12" class="red--text">Xác nhận liên kết tài khoản ViewTub
                </v-col>
                <v-col cols="12" @click="addAccount">
                    <v-card flat class="text-center confirm white--text pa-3">
                        <v-icon color="white" large>mdi-check</v-icon>
                        Xác nhận thêm tài khoản
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {ADD_VIEWTUB_ACCOUNT} from '~/store/actions.type';

    export default {
        data() {
            return {
                overlay : false,
                token: '',
            }
        },
        computed: {},
        methods: {
            addAccount() {
                this.overlay = true;
                this.$store.dispatch(ADD_VIEWTUB_ACCOUNT,{
                    token: this.token
                }).then((data) => {
                    this.overlay = false;
                    if (typeof data !== "undefined" && data.success) {
                        this.$router.push('/account/manager/viewtub');
                    }
                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook-add {
        .v-card {
            &.facebook {
                background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            }

            &.confirm {
                cursor: pointer;
                background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            }
        }
    }

    .text-warning {
        color: #ff0000;
    }

</style>

