<template>
    <div class="account-manager-facebook">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col cols="12">
                    <h4 class="primary--text title">Các tài khoản</h4>
                </v-col>
                <v-col cols="12">
                    <v-card class="mx-auto">
                        <v-card-text>
                            <div class="primary--text body-2">
                                <v-icon color="primary">mdi-information</v-icon>
                                Quan trọng.
                            </div>
                            <div class="body-2 mt-1">Chọn tài khoản viewtub chính của bạn để hệ thống có thể gửi những
                                thông báo quan trọng tới tài khoản viewtub của bạn qua.
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card
                            class="mx-auto py-3"
                            color="white"
                            flat
                    >
                        <v-expansion-panels focusable>
                            <v-expansion-panel
                                    v-for="account in accounts" :key="account.id"
                            >
                                <v-expansion-panel-header>
                                    <v-row class="account-list">
                                        <v-col cols="3" md="1" class="align-content-center">
                                            <v-avatar>
                                                <img width="50px" src="/images/viewtub.png" alt="John">
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="9" md="5" class="show-info">
                                            <span class="primary--text body-1 font-weight-bold">Tên: {{ account.name }}</span>
                                            <br>
                                            <span class="primary--text body-1 font-weight-bold">Email: {{ account.email }}</span>
                                            <br>
                                            <span class="primary--text body-1 font-weight-bold">Coin: {{ account.coin }}</span>
                                            <br>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="account-info">
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-account-key</v-icon>
                                                Tên tài khoản
                                            </v-col>
                                            <v-col cols="6" class="float-left primary&#45;&#45;text">{{ account.name }}</v-col>
                                            <v-divider></v-divider>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-earth</v-icon>
                                                Email
                                            </v-col>
                                            <v-col cols="6" class="float-left primary&#45;&#45;text">{{ account.email }}</v-col>
                                            <v-divider></v-divider>
                                            <v-col cols="6" class="float-left">
                                                <v-icon class="mr-3 mt-n2">mdi-cash</v-icon>
                                                Coin
                                            </v-col>
                                            <v-col cols="6" class="float-left primary&#45;&#45;text">{{ account.coin }}</v-col>
                                            <v-divider></v-divider>
                                        </v-col>

                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2 text-center account-action" 
                                                @click="updateAccount(account.id)">Cập nhật thông tin tài khoản
                                        </v-col>
                                        <v-col cols="12" class="body-2">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" class="body-2 text-center account-action"
                                               @click="deleteAccount(account.id)">Xóa tài khoản
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-col cols="12">
                            <v-card class="version pa-2 mx-auto text-center" color="#2EAB38"
                                    :to="{ name : 'account_manager_viewtub_add' }">
                                <v-icon class="pl-3 mt-1 float-left" color="white" medium>mdi-account-plus
                                </v-icon>
                                <span class="title font-weight-bold ml-3 white--text">Thêm tài khoản</span>
                            </v-card>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {
        VIEWTUB_ACCOUNT_FETCH_ALL,
        COMMON_SET_SNACK,
        VIEWTUB_DELETE_ACCOUNT,
        VIEWTUB_UPDATE_ACCOUNT
    } from "~/store/actions.type";
    import Swal from "sweetalert2";
    import {AUTH_USER} from "../../../store/actions.type";
    import {SWAL_SETTING_CONFIRM_DELETE_VIEWTUB} from "../../../config/constants";

    global.jQuery = require('jquery');
    let $ = global.jQuery;
    window.$ = $;
    export default {
        data() {
            return {
                show_account_info: false,
                facebook_img: '/images/facebook.png',
                accounts: [],
                security: 0,
                slider: 2
            }
        },
        created() {
            this.viewTubAccountFetchAll();
            this.initialize();
        },
        filters: {
            infoAccountPublic(account) {
                let k = 0;
                if (account.gender) {
                    k = k + 1;
                }
                if (account.birthday) {
                    k = k + 1;
                }
                if (account.friends) {
                    k = k + 1;
                }
                if (account.is_public) {
                    k = k + 1;
                }
                if (account.address) {
                    k = k + 1;
                }
                return k;
            }
        },
        methods: {
            async initialize() {
                let currentUser = await this.$store.dispatch(AUTH_USER);
                this.security = currentUser.data.data.security;
            },
            viewTubAccountFetchAll() {
                this.$store.dispatch(VIEWTUB_ACCOUNT_FETCH_ALL)
                    .then(({data}) => {
                        this.accounts = data;
                    })
                    .catch(() => {
                        this.$store.dispatch(COMMON_SET_SNACK, {
                            message: 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
                            type: 'error'
                        });
                    });
            },
            updateAccount(account_id){
                this.$store.dispatch(VIEWTUB_UPDATE_ACCOUNT, account_id).then(()=>{
                    this.viewTubAccountFetchAll();
                });
            },
            deleteAccount(account_id) {
                switch (this.security) {
                    case 0:
                        Swal.fire({
                            icon: 'warning',
                            title: 'Notice...',
                            text: 'Bạn cần tạo mật khẩu bảo mật trước!',
                            footer: '<a href="/account/setting-security">Tạo mật khẩu bảo mật!</a>',
                        })
                        break;
                    case 1:
                        Swal.fire({
                            icon: 'warning',
                            title: 'Notice...',
                            text: 'Bạn cần bật mật khẩu bảo mật trước!',
                            footer: '<a href="/account/setting-security">Bật bảo mật tài khoản!</a>'
                        })
                        break;
                    case 2:
                    default:
                        Swal.fire(SWAL_SETTING_CONFIRM_DELETE_VIEWTUB('viewtub')).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Hãy nhập mật khẩu bảo mật của bạn',
                                    input: 'password',
                                    inputAttributes: {
                                        autocapitalize: 'off',
                                        required: 'true'
                                    },
                                    validationMessage: 'Bạn cần nhập mật khẩu bảo mật',
                                    showCancelButton: true,
                                    confirmButtonText: 'Xác nhận',
                                    cancelButtonText: 'Bỏ qua',
                                    showLoaderOnConfirm: true,
                                    preConfirm: (password) => {
                                        let params = {
                                            'password' : password,
                                            'id' : account_id
                                        }
                                        return this.$store.dispatch(VIEWTUB_DELETE_ACCOUNT, params);
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                }).then((result) => {
                                    if (result.value === true) {
                                        this.viewTubAccountFetchAll()
                                    }
                                })
                            }
                        })
                }
            },
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook {
        .v-avatar {
            width: 65px !important;
            height: 65px !important;

            img {

            }
        }

        .account-action {
            cursor: pointer;
        }

        .v-list-item {
            position: relative;

            .chevron-right {
                position: absolute;
                top: 40px;
                right: 0px;
            }
        }

        .is-main-account {
            border-radius: 5px;
            background-color: #EF5350;
        }

    }

</style>
