import {
    INSTAGRAM_JOB_FETCH_ALL,
    INSTAGRAM_JOB_GET_DETAIL,
    INSTAGRAM_JOB_EXECUTE, INSTAGRAM_GET_MY_JOBS,
    INSTAGRAM_JOB_REPORT,
  INSTAGRAM_JOB_FETCH_ALL_V2
} from "~/store/actions.type";
import {
    COMMON_SET_SNACK,
} from "~/store/mutations.type";
import {InstagramJobService} from "~/common/service.api";
import {INSTA_START_WORK} from "../actions.type";

const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    [INSTAGRAM_JOB_FETCH_ALL]({commit}, params) {
        return InstagramJobService.query_params_string(params)
            .then(({data}) => {
                let res = data.data;
                if(data.success){
                    return res;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
  [INSTAGRAM_JOB_FETCH_ALL_V2]({commit}, params) {
    return InstagramJobService.get_ins_job_v2(params)
      .then(({data}) => {
        let res = data.data;
        if(data.success){
          return res;
        }
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
    [INSTAGRAM_JOB_GET_DETAIL]({ commit }, params) {
        return new Promise((resolve) => {
            InstagramJobService.get(params)
                .then(res => {
                    if( res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [INSTA_START_WORK]({ commit }, params) {
        return new Promise((resolve) => {
            InstagramJobService.start_work(params)
              .then(res => {
                  resolve(res.data.success);
                  if( res.data.success) {
                      commit(COMMON_SET_SNACK, {
                          message: res.data.message,
                          type: "success"
                      });
                  }else{
                      commit(COMMON_SET_SNACK, {
                          message: res.data.message,
                          type: "error"
                      });
                      resolve(false);
                  }
              })
              .catch(err => {
                  commit(COMMON_SET_SNACK, {
                      message: err.response.data.message,
                      type: "error"
                  });
                  resolve(false);
              });
        });
    },
    [INSTAGRAM_JOB_EXECUTE]({commit}, params) {
        return InstagramJobService.execute(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }else{
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "error"
                    });
                }
                return data;
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [INSTAGRAM_JOB_REPORT]({commit}, params) {
        return InstagramJobService.report(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                    return data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [INSTAGRAM_GET_MY_JOBS]({commit}, params) {
        return InstagramJobService.get_my_jobs(params)
            .then(({data}) => {
                if(data.success){
                    return data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    }
};

// mutations
const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
};
