export const ROUTER_DESCRIPTION =
  {
    home: "Trang chủ",
    account: "Tài khoản",
    account_setting: "Chỉnh sửa tài khoản",
    account_manager: "Quản lý tài khoản",
    account_manager_facebook: "Tài khoản facebook",
    account_manager_facebook_add: "Thêm tài khoản facebook",
    account_withdraw: "Rút tiền",
    account_setting_security: "Bảo mật rút tiền",
    account_log_withdraw: "Lịch sử rút tiền",
    jobs: "Chọn kênh kiếm tiền",
    faqs: "Liên hệ hỗ trợ",
    jobs_facebook: "Kiếm tiền",
    jobs_facebook_detail: "Chi tiết",
    jobs_facebook_log_detail: "Chi tiết",
    referral: "Giới thiệu",

    account_manager_instagram: "Tài khoản Instagram",
    jobs_instagram: "Kiếm tiền",
    jobs_instagram_detail: "Chi tiết",
    jobs_instagram_log_detail: "Chi tiết",

    account_manager_tiktok: "Tài khoản TikTok",
    account_manager_tiktok_add: "Thêm tài khoản tiktok",

    job_tiktok: "Kiếm tiền",
    jobs_tiktok_detail: "Chi tiết",
    jobs_tiktok_log_detail: "Chi tiết",
      
    race_top: "Đua top",
    tool: "Tool Auto", 
  }
;

