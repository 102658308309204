<template>
    <v-row align="center" class="ml-0" justify="center">
        <v-col cols="12" xs="12" sm="8" md="6" lg="4" xl="4">
            <v-card class="pa-4">
                <v-card-title>
                    <p class="display-1 text--primary">Quên Mật Khẩu</p>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form_auth_sendmail">
                        <v-text-field
                                label="Nhập địa chỉ Email"
                                id="email"
                                name="email"
                                prepend-icon="email"
                                type="text"
                                v-model="email"
                                required
                                :rules="emailRules"
                        ></v-text-field>
                    </v-form>
                    <v-card-actions class="px-8 col-11 offset-1 md-12 align-center recaptcha">
                        <vue-recaptcha
                                class="g-recaptcha"
                                ref="recaptcha"
                                @verify="onVerify"
                                :sitekey="g_recaptcha_key">
                        </vue-recaptcha>
                    </v-card-actions>
                </v-card-text>
                <v-card-actions class="px-8">
                    <v-btn
                            xs12
                            color="primary"
                            block
                            height="60"
                            rounded
                            class="white--text font-weight-bold cols-12"
                            @click="sendMail"
                    >Gửi Yêu Cầu
                    </v-btn>
                </v-card-actions>
                <div class="text-center">
                    <v-btn
                            :to="{ name : 'home' }"
                            color="primary text"
                            rounded
                    >
                        {{ back }}
                    </v-btn>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import { AUTH_SEND_MAIL } from "~/store/actions.type";
    import {GOOGLE_RECAPTCHA_KEY} from "~/config/constants";
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        components: { VueRecaptcha },
        data() {
            return {
                email: "",
                recaptcha: "",
                emailRules: [
                    v => !!v || 'Vui lòng nhập E-mail',
                    v => /.+@.+\..+/.test(v) || 'E-mail không hợp lệ',
                ],
                back : '<< Trờ Về',
                g_recaptcha_key : GOOGLE_RECAPTCHA_KEY
            };
        },

        methods: {
            async sendMail() {
                if (this.$refs.form_auth_sendmail.validate()) {
                    let email = this.email;
                    let recaptcha = this.recaptcha;
                    try {
                        await this.$store.dispatch(AUTH_SEND_MAIL, {
                            email: email,
                            recaptcha: recaptcha,
                        });
                        await this.$router.push({ name: "password.reset" });
                    } catch (error) {
                        // console.log(error);
                    }
                }
            },
            onVerify(response) {
                this.recaptcha = response;
            },
        }
    };
</script>
<style lang="scss" scoped>
    .recaptcha {
        position: relative;
        padding-top: 50px;
        padding-bottom: 40px;
        .g-recaptcha {
            position: absolute;
            left: 50%;
            margin-left: -152px;
        }
    }
</style>
