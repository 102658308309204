<template>
    <div class="maintain">
        <div class="row">
            <div class="col-12">
                <img class="w60 ml20" width="70%" src="/images/maintain/MaintenanceImage_2.png">
            </div>
            <div class="col-12">
                <div class="w60 ml20 text-center">
                    <h2 style="color: red">
                        Hệ thống đang bảo trì, tham gia các nhóm hỗ trợ của chúng tôi để nhận thông báo mới nhất và nhanh nhất.
                    </h2>
                </div>
            </div>
            <div class="col-12">
                <div class="w60 ml20 row">
                    <div class="col-2">
                    </div>
                    <div class="col-2">
                        <a href="https://t.me/hanasupport" target="_blank">
                            <img width="50px" src="/images/maintain/telegram.png">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://bit.ly/hanaZalo" target="_blank">
                            <img width="50px" src="/images/maintain/zalo.png">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://bit.ly/hanavieclamonline" target="_blank">
                            <img width="50px" src="/images/maintain/facebook.png">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://www.facebook.com/messages/t/vieclamonlinedotorg" target="_blank">
                            <img width="50px" src="/images/maintain/messager.png">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "maintain",

        data() {
            return {
                // Color chinh
            }
        }
    }
</script>

<style lang="scss">
    .maintain{
        width: 100%;
        height: 100%;
        background-color: white;
        .w60 {
            width: 60%;
        }
        .ml20{
            margin-left: 20%;
        }
    }

</style>
