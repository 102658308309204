export const LIST_SEEDING_DEFAULT = {};
export const JOB_TYPE_FACEBOOK = 1;
export const JOB_TYPE_INSTAGRAM = 2;
export const JOB_TYPE_TIKTOK = 5;
export const GOOGLE_RECAPTCHA_KEY = '6LdfCvAUAAAAAP-flt3eC-0pRnbbvRTUIeaa9rT9';
// export const GOOGLE_RECAPTCHA_KEY = '6LdSV_AUAAAAAOiA5UzF-YA-1yIazyA2KNBf-5jU';
export const SWAL_SETTING_CONFIRM_DELETE = (message) => {
    return {
        title:  'Bạn có chắc chắn muốn xoá tài khoản ' + message + ' này không?',
        text: 'Mọi thông tin về job bạn làm sẽ bị huỷ?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Vâng , Tôi đồng ý!',
        cancelButtonText: 'Không'
    }
}
export const SWAL_SETTING_CONFIRM_DELETE_VIEWTUB = (message) => {
    return {
        title:  'Bạn có chắc chắn muốn xoá tài khoản ' + message + ' này không?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Vâng , Tôi đồng ý!',
        cancelButtonText: 'Không'
    }
}
export const SWAL_SETTING_CONFIRM_DELETE_HANA_ACCOUNT = () => {
    return {
        title:  'Bạn có chắc chắn muốn xoá tài khoản vĩnh viễn?',
        text: 'Mọi thông tin về tài khoản sẽ không được khôi phục sau hành động này',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Vâng , Tôi đồng ý!',
        cancelButtonText: 'Không'
    }
}