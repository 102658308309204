import {
    ADD_FACEBOOK_ACCOUNT, ADD_MULTI_FACEBOOK_ACCOUNT,
    FACEBOOK_ACCOUNT_FETCH_ALL, FACEBOOK_ACCOUNT_GET_POST_ID,
    FACEBOOK_DELETE_ACCOUNT, FACEBOOK_SET_MAIN_ACCOUNT, FACEBOOK_UPDATE_ACCOUNT, GET_PERCENT_PRESENTER
} from "../../store/actions.type";
import {
    COMMON_SET_PERCENT_PRESENTER,
    COMMON_SET_SNACK,
} from "../../store/mutations.type";
import {FacebookAccountService} from "~/common/service.api";

const state = {
    designs: [],
    pages: 0,
    design: null,
    totalRow: 0,
    editMemoStatus: -1,
    percentPresenter : 0,
};

// getters
const getters = {
    designs: state => state.designs,
    design: state => state.design,
    pages: state => state.pages,
    totalRow: state => state.totalRow,
    editMemoStatus: state => state.editMemoStatus,
    percentPresenter: state => state.percentPresenter
};

// actions
const actions = {
    [FACEBOOK_ACCOUNT_FETCH_ALL]({commit}) {
        return FacebookAccountService.get()
            .then(({data}) => {
                return data;
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [ADD_FACEBOOK_ACCOUNT]({commit}, params) {
        return FacebookAccountService.create(params)
            .then((data) => {
                commit(COMMON_SET_SNACK, {
                    message: `Chúc mừng bạn đã thêm tài khoản facebook thành công !`,
                    type: "success"
                });
                return data.data;
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [ADD_MULTI_FACEBOOK_ACCOUNT]({commit}, params) {
        return FacebookAccountService.createMulti(params)
            .then((data) => {
                commit(COMMON_SET_SNACK, {
                    message: data.data.message,
                    type: "success"
                });
                return data.data;
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
            });
    },
    [FACEBOOK_DELETE_ACCOUNT]({commit}, params = {}) {
        return FacebookAccountService.destroy(params.id, {'password': params.password})
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
                return true
            })
            .catch(error => {
                commit(COMMON_SET_SNACK, {
                    message: error.response.data.message,
                    type: "error"
                });
                // throw new Error(error);
            });
    },
    [FACEBOOK_SET_MAIN_ACCOUNT]({commit}, id) {
        return FacebookAccountService.set_main(id)
            .then(() => {
                commit(COMMON_SET_SNACK, {
                    message: `Thiết lập tài khoản chính thành công !`,
                    type: "success"
                });
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FACEBOOK_UPDATE_ACCOUNT]({commit}, id) {
        return FacebookAccountService.update(id)
            .then(() => {
                commit(COMMON_SET_SNACK, {
                    message: `Cập nhật thông tin tài khoản thành công !`,
                    type: "success"
                });
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FACEBOOK_ACCOUNT_GET_POST_ID]({commit}, params) {
        return FacebookAccountService.get_post_id(params)
            .then(({data}) => {
                return data.data
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [GET_PERCENT_PRESENTER]({commit}) {
        return FacebookAccountService.get_percent_presenter()
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_PERCENT_PRESENTER, data.data.percent);
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
};

// mutations
const mutations = {
    [COMMON_SET_PERCENT_PRESENTER](state, percentPresenter){
        state.percentPresenter = percentPresenter;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
