import {
  ADD_TIKTOK_ACCOUNT, TIKTOK_UPDATE_ACCOUNT,
  TIKTOK_ACCOUNT_FETCH_ALL, TIKTOK_DELETE_ACCOUNT, TIKTOK_SET_MAIN_ACCOUNT, TIKTOK_UPDATE_COOKIE
} from "../../store/actions.type";
import {
  COMMON_SET_SNACK,
} from "../../store/mutations.type";
import { TikTokAccountService } from "../../common/service.api";

const state = {

};

// getters
const getters = {
  // editMemoStatus: state => state.editMemoStatus,
};

// actions
const actions = {
  [TIKTOK_ACCOUNT_FETCH_ALL]({ commit }) {
    return TikTokAccountService.get()
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [ADD_TIKTOK_ACCOUNT]({ commit }, params) {
    return TikTokAccountService.create(params)
      .then((data) => {
        commit(COMMON_SET_SNACK, {
          message: `Chúc mừng bạn đã thêm tài khoản TikTok thành công !`,
          type: "success"
        });
        return data.data;
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_DELETE_ACCOUNT]({ commit }, params) {
    return TikTokAccountService.destroy(params.id, { 'password': params.password })
      .then(({ data }) => {
        if (data.success) {
          commit(COMMON_SET_SNACK, {
            message: data.message,
            type: "success"
          });
        }
        return true
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_UPDATE_ACCOUNT]({ commit }, id) {
    return TikTokAccountService.update(id)
      .then(() => {
        commit(COMMON_SET_SNACK, {
          message: `Cập nhật thông tin tài khoản thành công !`,
          type: "success"
        });
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_UPDATE_COOKIE]({ commit }, id, cookie) {
    return TikTokAccountService.updateCookie(id, cookie)
      .then(() => {
        commit(COMMON_SET_SNACK, {
          message: `Cập nhật thông tin tài khoản thành công !`,
          type: "success"
        });
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [TIKTOK_SET_MAIN_ACCOUNT]({ commit }, id) {
    return TikTokAccountService.set_main(id)
      .then(() => {
        commit(COMMON_SET_SNACK, {
          message: `Thiết lập tài khoản chính thành công !`,
          type: "success"
        });
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  }
};

// mutations
const mutations = {
  // [COMMON_SET_PERCENT_PRESENTER](state, percentPresenter){
  //     state.percentPresenter = percentPresenter;
  // }
};

export default {
  state,
  getters,
  actions,
  mutations
};
