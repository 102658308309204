<template>
    <div class="account-setting">
        <v-container fluid grid-list-lg>
            <v-row>
                <v-col>
                    <h3 class="primary--text display-1">Thông tin tài khoản</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-form class="pa-5" ref="form_update_account_info" lazy-validation>
                            <v-text-field
                                    v-model="name"
                                    :rules="nameRules"
                                    label="Name"
                                    required
                            ></v-text-field>

                            <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    label="Email"
                                    required
                            ></v-text-field>

                            <v-text-field
                                    v-model="mobile"
                                    :rules="mobileRules"
                                    label="Mobile"
                                    required
                            ></v-text-field>
                            <v-text-field
                                v-if="sentEmail && this.currentUser.email"
                                v-model="verifyCode"
                                :rules="verifyCodeRules"
                                label="Mã xác nhận"
                                required
                            ></v-text-field>
                            <v-col class="text-right">
                              <v-btn
                                  v-if="!sentEmail && this.currentUser.email"
                                  color="primary"
                                  class="mr-4"
                                  width="260"
                                  @click="sendEmail"
                              >
                                Yêu cầu thay đổi
                              </v-btn>
                                <v-btn
                                    v-else
                                    color="primary"
                                    class="mr-4"
                                    width="120"
                                    @click="updateAccountInfo"
                                >
                                    Lưu
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-form class="pa-5" ref="form_change_password" lazy-validation>
                            <v-text-field
                                    v-model="old_password"
                                    label="Mật khẩu hiện tại"
                                    name="old_password"
                                    type="password"
                                    required
                            ></v-text-field>

                            <v-text-field
                                    v-model="new_password"
                                    :rules="passwordRules"
                                    label="Mật khẩu mới"
                                    name="new_password"
                                    type="password"
                                    required
                            ></v-text-field>

                            <v-text-field
                                    v-model="new_password_confirmation"
                                    :rules="confirmPasswordRules"
                                    label="Nhập lại mật khẩu mới"
                                    type="password"
                                    required
                            ></v-text-field>
                            <v-text-field
                                v-if="sentEmailPassword && this.currentUser.email"
                                v-model="verifyCodePassword"
                                :rules="verifyCodeRules"
                                label="Mã xác nhận"
                                required
                            ></v-text-field>
                            <v-col class="text-right">
                              <v-btn
                                  v-if="!sentEmailPassword && this.currentUser.email"
                                  color="primary"
                                  class="mr-4"
                                  width="260"
                                  @click="sendEmailUpdatePassword"
                              >
                                Yêu cầu thay đổi
                              </v-btn>
                                <v-btn
                                    v-else
                                    color="primary"
                                    class="mr-4"
                                    width="120"
                                    @click="changePassword"
                                >
                                    Lưu
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-overlay :value="overlay">
                <v-progress-circular
                    indeterminate
                    color="info"
                ></v-progress-circular>
            </v-overlay>
        </v-container>
    </div>
</template>

<script>
    import {AUTH_USER, FARMER_CHANGE_PASSWORD, FARMER_UPDATE_ACCOUNT_INFO, FARMER_SEND_EMAIL_ACCOUNT_INFO, FARMER_SEND_EMAIL_UPDATE_PASSWORD} from "~/store/actions.type";

    export default {
        data() {
            return {
                currentUser: {},
                name: '',
                mobile: '',
                sentEmail: false,
                sentEmailPassword: false,
                verifyCode: '',
                verifyCodePassword: '',
                mobileRules: [
                    v => /(09|01|03|[2|6|8|9])+([0-9]{8})\b/.test(v) || 'Số điện thoại không hợp lệ !',
                ],
                nameRules: [
                    v => !!v || 'Tên không được để trống',
                    v => (v && v.length <= 20) || 'Tên không được quá 20 ký tự !',
                ],
                verifyCodeRules: [
                    v => !!v || 'Mã xác nhận không được để trống',
                    v => (v && v.length <= 20) || 'Mã xác nhận không được quá 20 ký tự !',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'Email không được để trống !',
                    v => /.+@.+\..+/.test(v) || 'Email không hợp lệ !',
                ],
                select: null,
                old_password: '',
                new_password: '',
                new_password_confirmation: '',
                passwordRules: [
                    v => !!v || 'Vui lòng nhập password',
                    v => (v && v.length <= 20) || 'Password không được quá 20 ký tự',
                ],
                confirmPasswordRules: [
                    v => !!v || 'Vui lòng nhập confirm password',
                    v => (v && v.length <= 20) || 'ConfirmPassword không được quá 20 ký tự',
                    v => (v === this.new_password) || 'Password không khớp'
                ],
                overlay: false,
            }
        },
        created() {
            this.initialize();
        },
        methods: {
            async initialize() {
                const user = await this.$store.dispatch(AUTH_USER)
                this.currentUser = user.data.data
                this.email = this.currentUser.email;
                this.name = this.currentUser.farmer.name;
                this.mobile = this.currentUser.farmer.mobile;
            },
            async updateAccountInfo() {
                if (this.$refs.form_update_account_info.validate()) {
                    this.overlay = true;
                    let params = {
                        'name': this.name,
                        'email': this.email,
                        'mobile': this.mobile,
                        'verify_code': this.verifyCode
                    };
                    const res = await this.$store.dispatch(FARMER_UPDATE_ACCOUNT_INFO, params)
                    this.overlay = false;
                    this.initialize();
                    this.verifyCode = ''
                    this.sentEmail = !res.success
                }
            },
            async sendEmail() {
                if (this.$refs.form_update_account_info.validate()) {
                    this.overlay = true;
                    let params = {
                      'name': this.name,
                      'email': this.email,
                      'mobile': this.mobile
                    };
                    const res = await this.$store.dispatch(FARMER_SEND_EMAIL_ACCOUNT_INFO, params)
                    this.overlay = false;
                    this.sentEmail = res.success
                }
            },

            async changePassword() {
              if (this.$refs.form_change_password.validate()) {
                this.overlay = true;
                let params = {
                  'old_password': this.old_password,
                  'new_password': this.new_password,
                  'new_password_confirmation': this.new_password_confirmation,
                  'verify_code': this.verifyCodePassword
                };
                const res = await this.$store.dispatch(FARMER_CHANGE_PASSWORD, params)
                this.overlay = false;
                this.verifyCodePassword = ''
                this.sentEmailPassword = !res.success
              }
            },


            async sendEmailUpdatePassword() {
              if (this.$refs.form_update_account_info.validate()) {
                this.overlay = true;
                let params = {
                  'old_password': this.old_password,
                  'new_password': this.new_password,
                  'new_password_confirmation': this.new_password_confirmation
                };
                const res = await this.$store.dispatch(FARMER_SEND_EMAIL_UPDATE_PASSWORD, params)
                this.overlay = false;
                this.sentEmailPassword = res.success
              }
            },
        },
    };
</script>
<style scoped lang="scss">
    .account-setting {


    }

</style>
