import {
    FARMER_CHANGE_PASSWORD,
    FARMER_CREATE_SECOND_PASSWORD,
    FARMER_GET_COIN,
    FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS,
    FARMER_SEND_EMAIL_ACCOUNT_INFO,
    FARMER_TURN_ON_SECOND_PASSWORD,
    FARMER_UPDATE_ACCOUNT_INFO,
    FARMER_UPDATE_SECOND_PASSWORD,
    FARMER_SEND_EMAIL_UPDATE_PASSWORD,
    DELETE_PERMANENTLY_ACCOUNT
} from '../../store/actions.type'
import {
    COMMON_SET_SNACK,
} from "../../store/mutations.type";
import {FarmerAccountService} from "../../common/service.api";
import {FARMER_TURN_OFF_SECOND_PASSWORD} from "../actions.type";

const state = {

};

// getters
const getters = {

};

// actions
const actions = {
    [FARMER_UPDATE_ACCOUNT_INFO]({commit}, params) {
        return new Promise((resolve) => {
            return FarmerAccountService.update(params)
              .then(({ data }) => {
                  if (data.success) {
                      commit(COMMON_SET_SNACK, {
                          message: data.message,
                          type: "success"
                      });
                  }
                  resolve(data);
              })
              .catch(err => {
                  commit(COMMON_SET_SNACK, {
                      message: err.response.data.message,
                      type: "error"
                  });
                  resolve(false);
                  // throw new Error(err);
              });
        })
    },
    [FARMER_SEND_EMAIL_ACCOUNT_INFO]({commit}, params) {
        return new Promise((resolve) => {
            return FarmerAccountService.sendEmailChangeInfo(params)
              .then(({data}) => {
                  if(data.success){
                      commit(COMMON_SET_SNACK, {
                          message: data.message,
                          type: "success"
                      });
                  }
                  resolve(data);
              })
              .catch(err => {
                  commit(COMMON_SET_SNACK, {
                      message: err.response.data.message,
                      type: "error"
                  });
                  resolve(false);
                  // throw new Error(err);
              });
        })
    },
    [FARMER_SEND_EMAIL_UPDATE_PASSWORD]({commit}, params) {
        return new Promise((resolve) => {
            return FarmerAccountService.sendEmailChangePassword(params)
              .then(({data}) => {
                  if(data.success){
                      commit(COMMON_SET_SNACK, {
                          message: data.message,
                          type: "success"
                      });
                  }
                  resolve(data);
              })
              .catch(err => {
                  commit(COMMON_SET_SNACK, {
                      message: err.response.data.message,
                      type: "error"
                  });
                  resolve(false);
                  // throw new Error(err);
              });
        })
    },
    [FARMER_CHANGE_PASSWORD]({commit}, params) {
        return new Promise((resolve) => {
            FarmerAccountService.change_password(params)
                .then(({data}) => {
                    if(data.success){
                        commit(COMMON_SET_SNACK, {
                            message: data.message,
                            type: "success"
                        });
                        resolve(data);
                    } else {
                        commit(COMMON_SET_SNACK, {
                            message: data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [FARMER_CREATE_SECOND_PASSWORD]({commit}, params) {
        return FarmerAccountService.set_second_password(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FARMER_UPDATE_SECOND_PASSWORD]({commit}, params) {
        return FarmerAccountService.update_second_password(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FARMER_TURN_ON_SECOND_PASSWORD]({commit}, params) {
        return FarmerAccountService.turn_on_second_password(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
                return true
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FARMER_TURN_OFF_SECOND_PASSWORD]({commit}, params) {
        return FarmerAccountService.turn_off_second_password(params)
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
                return true
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FARMER_LAST_HISTORY_WITHDRAWAL_SUCCESS]({commit}) {
        return FarmerAccountService.last_history_withdrawal_success()
            .then(({data}) => {
                if(data.success){
                   return data.data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [FARMER_GET_COIN]({commit}) {
        return FarmerAccountService.get_coin()
            .then(({data}) => {
                if(data.success){
                   return data.data;
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: err.response.data.message,
                    type: "error"
                });
                // throw new Error(err);
            });
    },
    [DELETE_PERMANENTLY_ACCOUNT]({commit}, params = {}) {
        return FarmerAccountService.destroy(params.id, {'password': params.password})
            .then(({data}) => {
                if(data.success){
                    commit(COMMON_SET_SNACK, {
                        message: data.message,
                        type: "success"
                    });
                }
                return true
            })
            .catch(error => {
                commit(COMMON_SET_SNACK, {
                    message: error.response.data.message,
                    type: "error"
                });
                // throw new Error(error);
            });
    },
};

// mutations
const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
};
