<template>

    <v-bottom-navigation
            app
            fixed
            v-model="activeBtn"
            color="primary"
            class="hidden-lg-and-up"
    >

        <v-btn @click="toHomePage" class="nav-bottom-menu home">
            <span>Trang chủ</span>
            <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'jobs'}" class="nav-bottom-menu">
            <span>Kiếm tiền</span>
            <v-icon>mdi-credit-card</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'faqs'}" class="nav-bottom-menu">
            <span>Hỗ trợ</span>
            <v-icon>mdi-help-circle</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'account'}" class="nav-bottom-menu">
            <span>Tài khoản</span>
            <v-icon>mdi-account</v-icon>
        </v-btn>
    </v-bottom-navigation>

</template>

<script>
    global.jQuery = require('jquery');
    let $ = global.jQuery;
    window.$ = $;
    export default {
        data() {
            return {
                activeBtn: 0
            }
        },
        methods: {
            toHomePage() {
                this.$router.push('/');
            }
        },
        mounted: function () {
          if($('.nav-bottom-menu.v-btn--active').length > 1){
            $('.nav-bottom-menu.home').removeClass('v-btn--active');
          }
          this.$nextTick(function () {
              $('.nav-bottom-menu').on('click', function () {
                $('.nav-bottom-menu').removeClass('v-btn--active');
                 $(this).addClass('v-btn--active');
              });
          })
        }
    };
</script>

