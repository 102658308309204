<template>
    <div class="jobs-facebook-log-detail">
        <v-row class="job">
            <v-col cols="3" md="1" class="text-center pa">
                <div style="border-right: 1px solid #777777">
                    <v-avatar size="36">
                        <img :src="job.icon" alt="">
                    </v-avatar>
                    <h6 class="title font-weight-bold mt-1">
                        <span><span class="hold-prices">{{ job.coin }}</span></span>
                        <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon white--text"/>
                    </h6>
                </div>
            </v-col>
            <v-col cols="9" md="11" class="subtitle-1 job-info px-0" >
                <div>
                    <span>Ngày duyệt: <span class="font-weight-bold">{{ job.time_remain }}</span></span><br>
                    <span v-if="job.seeding_type === 8" class="black--text">{{ job.comment_need }}</span>
                    <span v-if="job.seeding_type !== 8" class="black--text">{{ job.content }}</span><br/>
                    <span class="caption">Job Id: <span class="font-weight-bold">{{ job.id }}</span> - Fb Id: <span class="font-weight-bold">{{ job.post_id }}</span></span>
                </div>
            </v-col>
            <v-col cols="12" class="ml-md-10 text-md-left text-center">
                <v-btn color="primary" width="300px" :href="job.link" target="_blank">Xem chi tiết</v-btn>
                <v-btn color="primary" class="ml-md-2 mt-md-0 mt-2" width="300px" :to="{ name : 'jobs_instagram_detail', params : { id : job.id } }">Thực hiện lại</v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import {INSTAGRAM_JOB_GET_DETAIL} from "~/store/actions.type";

export default {
    data() {
        return {
            tab: null,
            job : null,
            coinSvg: '/images/coin.svg',
        }
    },
    created() {
        this.getDataFromApi();
    },
    methods : {
        async getDataFromApi () {
          let params = {
            fb_account_id: localStorage.getItem('instagram_account'),
            job_id: this.$route.params.id
          };
            let data = await this.$store.dispatch(INSTAGRAM_JOB_GET_DETAIL, params);
            this.job = data;
        },
    }

};
</script>
<style lang="scss" scoped>
    .v-avatar{
        height: 40px !important;
    }
</style>
