<template>
    <div class="account">
        <v-container fluid grid-list-lg>
            <v-row>
                <v-col>
                    <v-card class="account-info pa-2 white--text text-center" outlined>
                        <v-avatar>
                            <img :src="avatar" alt="">
                        </v-avatar>
                        <div class="mt-2">{{ account_name }}</div>
                        <div class="mt-2">Số dư <span class="font-weight-bold">{{ coin | formatNumber }} đ</span></div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" md="3" lg="2" v-for="item in items" :key="item.icon">
                    <v-card class="pa-2 text-center" :to="item.to">
                        <v-avatar>
                            <div class="portfolio" :class="item.class_name">
                                <v-icon color="white" medium>{{ item.icon }}</v-icon>
                            </div>
                        </v-avatar>
                        <div class="body-2 mt-1" v-html="item.title"></div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items : [
                { icon : 'mdi-comment-account-outline', class_name : 'account_manager', title : "Quản lý <br> tài khoản", to: { name:'account_manager' } },
                { icon : 'mdi-history', class_name : 'withdraw_history', title : "Lịch sử <br> dòng tiền", to: {name: 'account_withdraw',  params: {type: 'log'}} },
                { icon : 'mdi-account-search', class_name : 'check_money', title : "Đối soát <br> tiền duyệt", to: {name : 'home'}  },
                { icon : 'mdi-credit-card-plus-outline', class_name : 'withdraw', title : "Rút tiền <br> ViewTub", to: { name:'money_viewtub' } },
                { icon : 'mdi-credit-card-plus-outline', class_name : 'withdraw', title : "Rút tiền", to: { name: 'account_withdraw', params: {type: 'process'}} },
                { icon : 'mdi-share-variant', class_name : 'referral', title : "Giới thiệu bạn bè", to: {name : 'referral'}  },
                { icon : 'mdi-help-circle', class_name : 'questions', title : "Nội quy",  to: {name : 'home'}  },
                { icon : 'mdi-delete-circle ', class_name : 'delete-permanently', title : "Xoá vĩnh viễn tài khoản",  to: {name : 'delete'}  },
            ],
            avatar : '/images/logo.jpg',
            account_name : ''
        }
    },
    filters : {
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    },
    created() {
        this.initialize();
    },
    computed : {
      coin(){
          return this.$store.getters.coin;
      }
    },
    methods : {
        initialize() {
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.avatar = currentUser.avatar;
            this.account_name = currentUser.farmer.name;
        },
    }
};
</script>
<style scoped lang="scss">
    .account {
        .v-card {
            border-radius: 15px;
            min-height: 108px;
            border: none;
        }
        .v-card.account-info {
            background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
        }
        .portfolio {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            &.account_manager {
                background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            }
            &.withdraw_history {
                background: rgb(0, 204, 131);
            }
            &.check_money {
                background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            }
            &.withdraw {
                background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
            }
            &.referral {
                background: rgb(249, 169, 1);
            }
            &.questions, &.logout {
                background: #0084F8;
            }
            &.delete-permanently {
                background: #ff0000;
            }
            &.add-fb-account {
                background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            }
            &.add-ins-account {
                background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            }
        }
        .v-avatar {
            border-radius: 50% !important;
        }
    }

</style>
