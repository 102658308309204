import {
  ADD_VIEWTUB_ACCOUNT, VIEWTUB_UPDATE_ACCOUNT,
  VIEWTUB_ACCOUNT_FETCH_ALL, VIEWTUB_DELETE_ACCOUNT
} from "../../store/actions.type";
import {
  COMMON_SET_SNACK,
} from "../../store/mutations.type";
import {ViewTubAccountService} from "../../common/service.api";

const state = {

};

// getters
const getters = {
  // editMemoStatus: state => state.editMemoStatus,
};

// actions
const actions = {
  [VIEWTUB_ACCOUNT_FETCH_ALL]({commit}) {
    return ViewTubAccountService.get()
      .then(({data}) => {
        return data;
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [ADD_VIEWTUB_ACCOUNT]({commit}, params) {
    return ViewTubAccountService.create(params)
      .then((data) => {
        commit(COMMON_SET_SNACK, {
          message: `Chúc mừng bạn đã thêm tài khoản ViewTub thành công !`,
          type: "success"
        });
        return data.data;
      })
      .catch(err => {
        commit(COMMON_SET_SNACK, {
          message: err.response.data.message,
          type: "error"
        });
        // throw new Error(err);
      });
  },
  [VIEWTUB_DELETE_ACCOUNT]({commit}, params) {
    return ViewTubAccountService.destroy(params.id, {'password': params.password})
        .then(({data}) => {
          if(data.success){
            commit(COMMON_SET_SNACK, {
              message: data.message,
              type: "success"
            });
          }
          return true
        })
        .catch(err => {
          commit(COMMON_SET_SNACK, {
            message: err.response.data.message,
            type: "error"
          });
          // throw new Error(err);
        });
  },
  [VIEWTUB_UPDATE_ACCOUNT]({commit}, id) {
    return ViewTubAccountService.update(id)
        .then(() => {
            commit(COMMON_SET_SNACK, {
                message: `Cập nhật thông tin tài khoản thành công !`,
                type: "success"
            });
        })
        .catch(err => {
            commit(COMMON_SET_SNACK, {
                message: err.response.data.message,
                type: "error"
            });
            // throw new Error(err);
        });
},
};

// mutations
const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
