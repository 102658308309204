<template>
  <div class="withdraw-container">
    <v-container fluid grid-list-lg>
      <v-row>
        <v-col>
          <v-card class="deposit pa-2 white--text text-center bg-gradient" outlined
                  :to="{ name: 'account_withdraw', params: {type: 'process'}}" @click="type='process'">
            <v-icon color="white" large>mdi-credit-card-plus-outline</v-icon>
            <br/>
            <span class="mt-3">Rút tiền</span><br/>
            <span class="mt-1">{{ coin | formatNumber }} <font-awesome-icon :icon="['fas', 'coins']"
                                                                            class="v-coin-icon white--text"/></span>
          </v-card>
          <div v-if="type==='process'" class="bg-gradient div-flag"></div>
        </v-col>
        <v-col>
          <v-card class="fanpage pa-2 white--text text-center bg-gradient" outlined
                  :to="{ name: 'account_withdraw', params: {type: 'log'}}" @click="type='log'">
            <v-icon color="white" large>mdi-history</v-icon>
            <br/>
            <span>Lịch sử</span><br/>
            <span>Rút tiền</span>
          </v-card>
          <div v-if="type==='log'" class="bg-gradient div-flag"></div>
        </v-col>
      </v-row>
      <div v-if="type==='process'" class="tab-withdraw">
        <v-row>
          <v-col>
            <v-card class="notice pa-2 white--text bg-gradient" outlined>
              <p>
                <span class="font-weight-bold">Lưu ý:</span><br>
                - Hana duyệt tiền 24/24 ngay khi nhận được yêu cầu rút tiền.<br>
                - Các lệnh rút tiền vào Thứ 7, Chủ Nhật sẽ chậm hơn các ngày trong tuần.<br>
                <b>- Hình thức rút tiền nhanh sẽ thu phí thêm 10%.</b>
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="sercue pa-2 white--text" outlined @click="makeSercurity()">
              <p>
                Để tránh bị mất tiền vui lòng thiết lập bảo mật tài khoản tại đây!
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-7 mt-3 title">Tài khoản rút tiền</h3><br>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card class="pa-2 bg-white" outlined>
              <span class="mb-4 font-weight-bold">Chọn tài khoản rút tiền</span><br>
              <span class="mb-2">Rút tiền từ</span>
              <v-select
                  solo
                  :items="type_accounts"
                  v-model="type_account"
                  item-text="name"
                  item-value="id"
              ></v-select>
            </v-card>
          </v-col>
          <v-col cols="12" v-show="type_account == 2">
            <a href="/money-viewtub-process">Rút tiền từ Viewtub về Hana tại đây!</a>
            <v-card class="pa-2 bg-white" outlined>
              <span class="mb-4 font-weight-bold">Chọn tài khoản ViewTub</span><br>
              <v-select
                  solo
                  :items="viewtub_accounts"
                  v-model="viewtub_account"
                  item-text="name"
                  item-value="id"
              ></v-select>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-7 mt-3 title">Phương thức</h3><br>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card class="pa-2 bg-white" outlined>
              <span class="mb-4 font-weight-bold">Chọn phương thức rút tiền</span><br>
              <span class="mb-2">Rút tiền qua</span>
              <v-select
                  solo
                  :items="methods"
                  v-model="method"
                  item-text="name"
                  item-value="value"
              ></v-select>
              <span class="mb-2">Phương thức rút tiền</span>
              <v-select
                  solo
                  :items="speeds"
                  v-model="speed"
                  item-text="name"
                  item-value="value"
              ></v-select>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-7 title">Thông tin thanh toán</h3><br>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="pa-2 bg-white" outlined>
              <!--                                loai 1: ngan hang-->
              <v-form ref="form_user_withdraw_1">
                <div v-if="showComming===BANK" class="method-bank">
                  <span class="mb-2">Ngân hàng</span>
                  <v-autocomplete
                      solo
                      v-model="bankName"
                      :items="bankArray"
                      item-text="name"
                      item-value="value"
                      label="Gõ để tìm kiếm ngân hàng nhanh hơn"
                      required
                      :rules="bankNameRules"
                  ></v-autocomplete>
                  <span class="mb-2">Tên chủ thẻ</span>
                  <v-text-field
                      v-model="nameAccount"
                      solo
                      required
                      type="text"
                      :rules="nameAccountRules"
                  ></v-text-field>
                  <span class="mb-2">Số tài khoản</span>
                  <v-text-field
                      v-model="noAccount"
                      solo
                      required
                      type="number"
                      :rules="noAccountRules"
                  ></v-text-field>
                  <span class="mb-2">Chi nhánh</span>
                  <v-text-field
                      v-model="branchName"
                      solo
                      required
                      type="text"
                      :rules="branchNameRules"
                  ></v-text-field>
                  <span class="mb-2">Số tiền</span>
                  <v-select
                      v-model="money1"
                      solo
                      :items="money1s"
                      item-text="name"
                      item-value="nameValue"
                  ></v-select>
                  <span class="mb-2">Mật khẩu</span>
                  <v-text-field
                      solo
                      v-model="password1"
                      type="password"
                      required
                      :rules="passwordRules"
                  ></v-text-field>
                </div>
              </v-form>
              <!--                                loai 4: the garena-->
              <v-form ref="form_user_withdraw_4">
                <div v-if="showComming===GARENA" class="method-sdt">
                  <span class="mb-2">Số tiền cần rút</span>
                  <v-select
                      solo
                      :items="money4s"
                      v-model="money4"
                      item-text="name"
                      item-value="nameValue"
                  ></v-select>
                  <span class="mb-2">Nhập mã rút tiền Garena</span>
                  <v-text-field
                      v-model="nameAccount4"
                      solo
                      required
                  ></v-text-field>
                  <span class="mb-2">Mật khẩu</span>
                  <v-text-field
                      solo
                      v-model="password4"
                      type="password"
                      required
                      :rules="passwordRules"
                  ></v-text-field>
                </div>
              </v-form>
              <!--                                loai 5: the dt-->
              <v-form ref="form_user_withdraw_5">
                <div v-if="showComming===MOBILE" class="method-sdt">
                  <span class="mb-2">Chọn nhà mạng</span>
                  <v-select
                      solo
                      :items="homeNetworks"
                      v-model="homeNetwork"
                      item-text="name"
                      item-value="value"
                      @change="setMobileDenoms()"
                  ></v-select>
                  <span class="mb-2">Loại hình</span>
                  <v-select
                      solo
                      :items="simTypes"
                      v-model="simType"
                      item-text="name"
                      item-value="value"
                  ></v-select>
                  <span class="mb-2">Chọn mệnh giá</span>
                  <v-select
                      solo
                      :items="mobileDenoms"
                      v-model="mobileDenom"
                      item-text="name"
                      item-value="nameValue"
                  ></v-select>
                  <span class="mb-2">Số điện thoại</span>
                  <v-text-field
                      v-model="mobile"
                      solo
                      :counter="11"
                      required
                      type="number"
                      :rules="mobileRules"
                  ></v-text-field>
                  <span class="mb-2">Mật khẩu</span>
                  <v-text-field
                      solo
                      v-model="password5"
                      type="password"
                      required
                      :rules="passwordRules"
                  ></v-text-field>
                </div>
              </v-form>
              <!--                                loai 7: Momo-->
              <v-form ref="form_user_withdraw_7">
                <div v-if="showComming===MOMO" class="method-sdt">
                  <span class="mb-2">Số tiền cần rút</span>
                  <v-select
                      solo
                      :items="money7s"
                      v-model="money7"
                      item-text="name"
                      item-value="nameValue"
                  ></v-select>
                  <span class="mb-2">Số điện thoại</span>
                  <v-text-field
                      v-model="mobile7"
                      solo
                      :counter="11"
                      required
                      type="number"
                      :rules="mobileRules"
                  ></v-text-field>
                  <span class="mb-2">Họ và tên</span>
                  <v-text-field
                      v-model="nameAccount7"
                      solo
                      required
                      type="text"
                      :rules="nameAccountRules"
                  ></v-text-field>
                  <span class="mb-2">Mật khẩu </span>
                  <v-text-field
                      solo
                      v-model="password7"
                      type="password"
                      required
                      :rules="passwordRules"
                  ></v-text-field>
                </div>
              </v-form>
              <!--                                loai 8: Nạp tiền cho hacklikefb-->
              <v-form ref="form_user_withdraw_8">
                <div v-if="showComming===HACKLIKE" class="method-sdt">
                  <span class="mb-2">Số tiền cần rút</span>
                  <v-text-field
                      v-model="money8"
                      solo
                      required
                      type="number"
                      :rules="moneyRules"
                  ></v-text-field>
                  <span class="mb-2">Nhập tài khoản hacklikefb.net (username đăng nhập)</span>
                  <v-text-field
                      v-model="nameAccount8"
                      solo
                      required
                  ></v-text-field>
                  <span class="mb-2">Mật khẩu</span>
                  <v-text-field
                      solo
                      v-model="password8"
                      type="password"
                      required
                      :rules="passwordRules"
                  ></v-text-field>
                </div>
              </v-form>
              <!--                                khong ho tro-->
              <div v-if="showComming === -1">
                <span class="red--text">Chưa hỗ trợ hình thức rút tiền này</span>
              </div>

            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="showComming === 4">
          <v-col>
            <v-card class="pa-4">
              <h4>Hướng dẫn sử dụng phương thức rút tiền Thẻ Garena:
                <a target="__blank" href="https://www.youtube.com/watch?v=_aKW_8HY2rE">https://www.youtube.com/watch?v=_aKW_8HY2rE</a>
              </h4>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn width="100%" height="50px" color="blue" class="white--text" @click="withDrawV2()">
              Rút Tiền
            </v-btn>
          </v-col>
        </v-row>

      </div>

      <div v-if="type==='log'" class="tab-log-withdraw">

        <v-layout row>
          <v-flex xs12>

            <v-card>
              <v-card-title>
                Lịch Sử rút tiền
                <v-spacer></v-spacer>

                <!--                                <v-text-field-->
                <!--                                        v-model="search"-->
                <!--                                        outlined-->
                <!--                                        append-icon="search"-->
                <!--                                        label="Tìm theo Post ID"-->
                <!--                                        hide-details-->
                <!--                                ></v-text-field>-->
                <!--                                <div class="float-right div-icon-action">-->
                <!--                                    <v-icon @click="print" >mdi-printer</v-icon>-->
                <!--                                </div>-->
                <!--                                <div class="float-right div-icon-action ml-0">-->
                <!--                                    <export-excel  :data   = "desserts">-->
                <!--                                        <v-icon>mdi-file-export</v-icon>-->
                <!--                                    </export-excel>-->
                <!--                                </div>-->
              </v-card-title>
              <div id="log-withdraw-activity-log">
                <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :options.sync="options"
                    :server-items-length="totalDesserts"
                    :loading="loading"
                    class="elevation-1"
                >
                  <template v-slot:item.money="{item}">
                    <v-chip color="orange" dark>
                      {{ item.money | formatNumber }}VND
                    </v-chip>
                  </template>
                  <template v-slot:item.speed="{item}">
                    <v-chip color="red" dark v-if="item.speed">
                      {{ item.speed }}
                    </v-chip>
                  </template>
                  <template v-slot:item.status_message="{ item }">
                    <v-chip v-if="item.status == 1" color="orange" dark>
                      <v-avatar left>
                        <v-icon>mdi-sync</v-icon>
                      </v-avatar>
                      {{ item.status_message }}
                    </v-chip>
                    <v-chip v-if="item.status == 2" color="success" dark>
                      <v-avatar left>
                        <v-icon>mdi-check</v-icon>
                      </v-avatar>
                      {{ item.status_message }}
                    </v-chip>
                    <v-chip v-if="item.status == 3" color="secondary" dark>
                      <v-avatar left>
                        <v-icon>mdi-close-circle</v-icon>
                      </v-avatar>
                      {{ item.status_message }}
                    </v-chip>
                  </template>

                </v-data-table>
              </div>


            </v-card>
          </v-flex>
        </v-layout>

      </div>

    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          color="info"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { formatCurrency } from '~/common/helper.string'
import Swal from 'sweetalert2'
import {
  WITHDRAW_POST_FORM_V2,
  WITHDRAW_LIST_METHOD,
  WITHDRAW_DETAIL_FORM,
  AUTH_USER,
  VIEWTUB_ACCOUNT_FETCH_ALL,
  // COMMON_SET_SNACK
} from '../../store/actions.type'

export default {
  data () {
    return {
      BANK: 1,
      MOBILE: 5,
      MOMO: 7,
      GARENA: 4,
      HACKLIKE: 8,

      // history
      totalDesserts: 0,
      desserts: [],
      loading: true,
      overlay: false,
      options: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Số tiền', value: 'money', align: 'center' },
        { text: 'Ngày rút', value: 'created_at', align: 'center' },
        { text: 'Phương thức', value: 'form_convert', align: 'center' },
        { text: 'Số điện thoại', value: 'mobile_convert', align: 'center' },
        { text: 'Loại hình', value: 'speed', align: 'center' },
        { text: 'Trạng thái', value: 'status_message', align: 'center' },
        { text: 'Ghi chú', value: 'note', align: 'center' },
      ],
      status: [],
      quickRates: {},
      // process
      money: 0,
      type: '',
      method: 5,
      methods: [],
      speed: 0,
      type_accounts: [
        {
          'id': 1,
          'name': 'Hana Account'
        }
      ],
      type_account: 1,
      viewtub_accounts: [],
      viewtub_account: null,
      showComming: 5,
      detailForm: [],
      // Rut loai 1: ngan hang
      bankNames: {},
      bankArray: [],
      bankName: '',
      bankNameRules: [
        v => !!v || 'Vui lòng chọn ngân hàng'
      ],
      nameAccount: '',
      nameAccountRules: [
        v => !!v || 'Vui lòng nhập Tên chủ thẻ'
      ],
      noAccount: '',
      noAccountRules: [
        v => !!v || 'Vui lòng nhập Số tài khoản'
      ],
      branchName: '',
      branchNameRules: [
        v => !!v || 'Vui lòng nhập Chi nhánh'
      ],
      money1s: [],
      money1: 50000,
      password1: '',

      // rut ra the dt: loai 5
      homeNetwork: 1, // vietel
      homeNetworks: [],
      simType: 0,
      simTypes: [],
      mobileDenom: 20000, // min 20k cua vietel
      mobileDenoms: [],
      mobileDenomAll: [],
      mobile: '',
      mobileRules: [
        v => !!v || 'Vui lòng nhập số điện thoại liên hệ',
        v => (v && v.length <= 11 && v.length >= 10) || 'Số điện thoại trong khoản 10-11 ký tự',
      ],
      password5: '',

      //rut ra bang momo: loai 7
      money7s: [],
      money7: 50000,
      mobile7: '',
      nameAccount7: '',
      password7: '',

      // rút tiền bằng garena
      money4s: [],
      money4: 50000,
      nameAccount4: '',
      password4: '',
      // Nạp tiền cho hacklike
      money8s: [],
      money8: '',
      nameAccount8: '',
      password8: '',
      //Common
      moneyRules: [
        v => !!v || 'Vui lòng nhập Số tiền'
      ],
      passwordRules: [
        v => !!v || 'Vui lòng nhập mật khẩu'
      ],

      security: 0,
      dataDetail: [],

      linkMomo: '',
      linkMomoRules: [
        v => !!v || 'Vui lòng nhập link nhận tiền'
      ],

    }
  },
  filters: {
    formatNumber (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  },
  created () {
    this.type = this.$route.params.type
    this.initialize()
    this.viewTubAccountFetchAll()
  },
  watch: {
    method () {
      this.getDetailForm()
    },
    options: {
      handler () {
        this.getDataHistoryFromApi()
            .then(data => {
              this.desserts = data.items
              this.totalDesserts = data.total
            })
      },
      deep: true,
    },
    type_account () {
      this.showMessageErrorAccount()
    }
  },
  computed: {
    coin () {
      return this.$store.getters.coin
    },
    speeds() {
      const speeds = [
        {
          'name': 'Rút tiền chậm (2 đến 3 ngày, phí giao dịch 0%)',
          'value': 0
        },
        {
          'name': `Rút tiền nhanh (1 ngày, phí giao dịch ${this.quickRates[this.method] * 100}%)`,
          'value': 1
        },
      ]
      const speedsMomo = [
        {
          'name': `Rút tiền nhanh (phí giao dịch ${this.quickRates[this.method] * 100}%)`,
          'value': 1
        },
      ]
      return  this.method === this.MOMO ? speedsMomo : speeds
    }
  },
  methods: {
    getCurrentMoney (list, value) {
      return list.filter(item => item.nameValue === value)[0]
    },
    showMessageErrorAccount () {
      if (this.type_account == 2 && this.viewtub_accounts.length == 0) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Bạn phải có liên kết tài khoản viewtub trước!',
          footer: '<a href="/account/manager/viewtub">Liên kết tài khoản viewTub</a>'
        })
      }
    },
    viewTubAccountFetchAll () {
      this.$store.dispatch(VIEWTUB_ACCOUNT_FETCH_ALL)
          .then(({ data }) => {
            this.viewtub_account = data[0].id
            this.viewtub_accounts = data.map(item => {
              let data = {
                id: item.id,
                name: item.name + ' - Coin:' + item.coin
              }
              return data
            })
          })
      // .catch(() => {
      // this.$store.dispatch(COMMON_SET_SNACK, {
      //     message: 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
      //     type: 'error'
      // });
      // });
    },
    async initialize () {
      let currentUser = await this.$store.dispatch(AUTH_USER)
      this.security = currentUser.data.data.security
    },
    async getDataFromApi () {
      let dataMethod = await this.$store.dispatch(WITHDRAW_LIST_METHOD)
      if (dataMethod !== false) {
        this.money = dataMethod.farmer_money
        let dataArr = Object.values(dataMethod.form_convert_list)
        this.quickRates = dataMethod.quick_rate
        // Set method
        for (let i = 0; i < dataArr.length; i++) {
          this.methods.push({
            name: dataMethod.form_convert_list[i].val,
            value: dataMethod.form_convert_list[i].key
          })
        }
        this.getDetailForm()
      }
    },
    async getDataHistoryFromApi () {
      let dataMethod = await this.$store.dispatch(WITHDRAW_LIST_METHOD)
      // history
      this.loading = true
      const { sortBy = [], sortDesc = [], page, itemsPerPage } = this.options

      let items = dataMethod.history_convert_money

      const total = items.length

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]]
          const sortB = b[sortBy[0]]

          if (sortDesc[0]) {
            if (sortA < sortB) return 1
            if (sortA > sortB) return -1
            return 0
          } else {
            if (sortA < sortB) return -1
            if (sortA > sortB) return 1
            return 0
          }
        })
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
      }

      this.loading = false
      return { items, total }

    },
    async getDetailForm () {
      let dataDetail = await this.$store.dispatch(WITHDRAW_DETAIL_FORM, { form_convert_id: this.method })
      if (dataDetail !== false) {
        this.dataDetail = dataDetail
        this.showComming = this.method
        switch (this.method) {
          case this.BANK: // ngan hang
            for (let i = 0; i < dataDetail.banks.length; i++) {
              const bank = dataDetail.banks[i]
              this.bankArray.push({ name: bank.name, value: bank.id })
              this.bankNames[bank.id] = { name: bank.name, value: bank.id }
            }
            for (let i = 0; i < dataDetail.mobile_denominations.length; i++) {
              const denomination = dataDetail.mobile_denominations[i]
              this.money1s.push({
                name: formatCurrency(denomination.value) + ' VND',
                nameValue: denomination.value
              })
            }
            this.money1 = dataDetail.mobile_denominations[0].value
            break
          case this.MOBILE: // the dt
            for (let i = 0; i < Object.values(dataDetail.mobile_denominations).length; i++) {
              this.homeNetworks.push({
                name: Object.values(dataDetail.mobile_denominations)[i].name,
                value: Object.values(dataDetail.mobile_denominations)[i].id
              })
            }

            for (let i = 0; i < Object.values(dataDetail.mobile_denom_all).length; i++) {
              const denomination = dataDetail.mobile_denom_all[i]
              this.mobileDenomAll.push({
                name: formatCurrency(denomination.value) + ' ' + dataDetail.unit,
                nnameValue: denomination.value
              })
            }

            for (let i = 0; i < Object.values(dataDetail.simtypes).length; i++) {
              this.simTypes.push({ name: Object.values(dataDetail.simtypes)[i], value: i })
            }

            this.setMobileDenoms()
            break
          case this.GARENA: // garena
            for (let i = 0; i < dataDetail.mobile_denominations.length; i++) {
              const denomination = dataDetail.mobile_denominations[i]
              this.money4s.push({
                name: formatCurrency(denomination.value) + ' VND',
                nameValue: denomination.value
              })
            }
            this.money4 = dataDetail.mobile_denominations[0].value
            break
          case this.MOMO: // momo
            for (let i = 0; i < dataDetail.mobile_denominations.length; i++) {
              const denomination = dataDetail.mobile_denominations[i]
              this.money7s.push({
                name: formatCurrency(denomination.value) + ' VND',
                nameValue: denomination.value
              })
            }
            this.money7 = dataDetail.mobile_denominations[0].value
            this.speed = 1
            break
          case this.HACKLIKE: // hacklikefb
            break
          default:
            this.showComming = -1
        }
      } else {
        this.showComming = -1
      }
    },
    setMobileDenoms () {
      this.mobileDenoms = []
      let denomValueArray = Object.values(this.dataDetail.mobile_denominations)[this.homeNetwork - 1].denom_value
      this.mobileDenom = denomValueArray[0].val
      for (let i = 0; i < denomValueArray.length; i++) {
        this.mobileDenoms.push({
          name: formatCurrency(denomValueArray[i].val) + ' VND',
          nameValue: denomValueArray[i].val,
        })
      }
    },
    getParams (method) {
      let param = []
      switch (method) {
        case this.BANK: // ngan hang
          param = {
            form_convert_id: 1,
            bank_name: this.bankNames[this.bankName].name,
            branch_name: this.branchName,
            account_name: this.nameAccount,
            account_id: this.noAccount,
            money: this.money1,
            password: this.password1,
          }
          break
        case this.GARENA: // garena
          param = {
            form_convert_id: 4,
            money: this.money4,
            name: this.nameAccount4,
            password: this.password4,
          }
          break
        case this.MOBILE: // the dt
          param = {
            form_convert_id: 5,
            money: this.mobileDenom,
            mobile: this.mobile,
            home_network_id: this.homeNetwork,
            simtype: this.simType,
            password: this.password5,
          }
          break
        case this.MOMO: // momo
          param = {
            form_convert_id: 7,
            money: this.money7,
            mobile: this.mobile7,
            link_momo: this.linkMomo,
            name: this.nameAccount7,
            password: this.password7,
          }
          break
        case this.HACKLIKE: // nạp tiền cho account hacklike
          param = {
            form_convert_id: 8,
            money: this.money8,
            name: this.nameAccount8,
            password: this.password8,
          }
          break
        default:
      }
      param.type_account = this.type_account
      param.viewtub_account = this.viewtub_account
      param.speed = this.speed

      return param
    },
    getHmlConfirm (method) {
      let html = ''
      let coin = 0
      let speedFee = 0
      switch (method) {
        case this.BANK: // ngan hang
          // eslint-disable-next-line no-case-declarations
          const currentMoney = this.getCurrentMoney(this.money1s, this.money1)
          coin = currentMoney.nameValue * this.coin / this.money
          speedFee = this.speed ? this.quickRates[this.method] * 100 : 0
          // eslint-disable-next-line no-case-declarations
          let moneyReceiver1 = this.speed ? (formatCurrency(coin * (1 - this.quickRates[this.method])) + ' VND') : formatCurrency(coin) + ' VND'
          html = '<h2>' +
              'Ngân hàng: ' + this.bankNames[this.bankName].name + '<br>' +
              'Tên chủ thẻ: ' + this.nameAccount + '<br>' +
              'Số tài khoản: ' + this.noAccount + '<br>' +
              'Chi nhánh: ' + this.branchName + '<br>' +
              'Số tiền: ' + currentMoney.name + '<br>' +
              'Số tiền thực nhận: ' + moneyReceiver1 + '<br>' +
              'Phí giao dịch: ' + speedFee + '%'
              '</h2>'
          break
        case this.GARENA: // Thẻ garena
          // eslint-disable-next-line no-case-declarations
          const currentMoneyGa = this.getCurrentMoney(this.money4s, this.money4)
          coin = parseInt(currentMoneyGa.nameValue) * this.coin / this.money
          speedFee = coin / 10
          if (this.speed) {
            coin = coin * 1.1
          }
          html = '<h2>' +
              'Tài khoản Garena' + this.nameAccount4 + '<br>' +
              'Mệnh giá: ' + currentMoneyGa.name + ' - '

          html += 'tương ứng với số coin: ' + formatCurrency(coin) + (this.speed ? '<p style=\'color: red\'>(+' + formatCurrency(speedFee) + ' phí rút tiền nhanh)</p>' : '') +
              '</h2>'
          break
        case this.MOBILE: // the dt
          speedFee = this.speed ? this.quickRates[this.method] * 100 : 0
          // eslint-disable-next-line no-case-declarations
          // eslint-disable-next-line no-case-declarations
          const moneyReceiver2 = formatCurrency(this.mobileDenom) + ' VND'
          // eslint-disable-next-line no-case-declarations
          const moneyPay = this.speed ? formatCurrency(this.mobileDenom * (1 + this.quickRates[this.method])) + ' VND' : formatCurrency(this.mobileDenom) + ' VND'
          html = '<h2>' +
              'Số điện thoại: ' + this.mobile + '<br>' +
              'Nhà mạng: ' + this.homeNetworks[this.homeNetwork - 1].name + '<br>' +
              'Loại hình: ' + this.simTypes[this.simType].name + '<br>' +
              'Mệnh giá: ' + this.getCurrentMoney(this.mobileDenoms, this.mobileDenom).name + '<br>' +
              'Số tiền: ' + moneyPay + '<br>' +
              'Số tiền thực nhận: ' + moneyReceiver2 + '<br>' +
              'Phí giao dịch: ' + speedFee + '%'
            '</h2>'
          break
        case this.MOMO: // momo
          coin = parseInt(this.money7) * this.coin / this.money
          speedFee = this.speed ? this.quickRates[this.method] * 100 : 0
          // eslint-disable-next-line no-case-declarations
          const moneyReceiver3 = this.speed ? (formatCurrency(coin * (1 - this.quickRates[this.method])) + ' VND') : formatCurrency(coin) + ' VND'
          html = '<h3>' +
              'Số điện thoại: ' + this.mobile7 + '<br>' +
              'Mệnh giá: ' + this.getCurrentMoney(this.money7s, this.money7).name + '<br>' +
              'Số tiền thực nhận: ' + moneyReceiver3 + '<br>' +
              'Phí giao dịch: ' + speedFee + '%'
              '</h3>'
          break
        case this.HACKLIKE: // Hacklike
          coin = parseInt(this.money8) * this.coin / this.money
          speedFee = this.speed ? this.quickRates[this.method] * 100 : 0
          // eslint-disable-next-line no-case-declarations
          let moneyReceiver4 = this.speed ? (formatCurrency(parseInt(this.money1) * (1 - this.quickRates[this.method])) + ' VND') : formatCurrency(parseInt(this.money1)) + ' VND'
          html = '<h2>' +
              'Tài khoản customer hacklikefb.net ' + this.nameAccount8 + '<br>' +
              'Số tiền: ' + formatCurrency(parseInt(this.money1)) + ' VND' + '<br>'+
              'Số tiền thực nhận: ' + moneyReceiver4 + '<br>' +
              '</h2>'
          break
        default:
      }
      return html
    },
    validateForm (method) {
      let validate = false
      switch (method) {
        case this.BANK: // ngan hang
          validate = this.$refs.form_user_withdraw_1.validate()
          break
        case this.GARENA: // the garena
          validate = this.$refs.form_user_withdraw_4.validate()
          break
        case this.MOBILE: // the dt
          validate = this.$refs.form_user_withdraw_5.validate()
          break
        case this.MOMO: // momo
          validate = this.$refs.form_user_withdraw_7.validate()
          break
        case this.HACKLIKE: // hacklike
          validate = this.$refs.form_user_withdraw_8.validate()
          break
        default:
      }
      return validate

    },
    withDrawV2 () {
      if (this.validateForm(this.method)) {
        Swal.fire({
          title: 'Bạn có chắc chắn muốn rút tiền với thông tin:',
          html: this.getHmlConfirm(this.method),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Vâng , chính xác!',
          cancelButtonText: 'Không'
        }).then(async (result) => {
          if (result.value) {
            this.overlay = true
            const res = await this.$store.dispatch(WITHDRAW_POST_FORM_V2, this.getParams(this.method))
            if (res) {
              let aThis = this
              this.$store.dispatch(AUTH_USER)
              setTimeout(function () {
                aThis.$router.go()
              }, 3000)
            }
            this.overlay = false
          }
        })
      }
    },
    makeSercurity () {
      this.$router.push('/account/setting-security')
    }
  },
  mounted () {
    this.getDataFromApi()
    this.getDataHistoryFromApi()
        .then(data => {
          this.desserts = data.items
          this.totalDesserts = data.total
        })
  }
}
</script>
<style scoped lang="scss">
.withdraw-container {
  .goto-link {
    text-decoration: unset;
    color: unset;
  }

  .v-card {
    border-radius: 15px;
    border: none;
  }

  .v-card.bg-gradient {
    background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
  }

  .v-list__group__header__prepend-icon .v-icon {
    color: red !important;
  }

  .v-card.notice {
    background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
  }

  .title {
    color: #0084F8;
  }

  .div-flag {
    width: 100%;
    height: 3px;
    background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
    margin-top: 3px;
  }

}

.sercue {
  background-color: #3B07F8;
}

</style>
