const state = {
    layout: localStorage.getItem("layout") || "simple-layout",
    snack: {
        type: "success",
        message: ""
    },
    currentUser: JSON.parse(localStorage.getItem("currentUser")),
    lang: 'vn',
    page: 'home',
    loading : false,
    listSeedingFacebook : {},
    listSeedingInstagram : {},
    listSeedingTiktok: {}
};

// getters
const getters = {
    common_layout: state => state.layout,
    common_snack: state => state.snack,
    currentUser: state => state.currentUser,
    lang: state => state.lang,
    page: state => state.page,
    loading: state => state.loading,
    listSeedingFacebook: state => state.listSeedingFacebook,
    listSeedingInstagram: state => state.listSeedingInstagram,
    listSeedingTiktok: state => state.listSeedingTiktok,
};

// actions
const actions = {
    common_setSnack({ commit }, snack) {
        return new Promise((resolve) => {
            // set state
            commit("common_setSnack", snack);
            resolve();
        });
    },
    setLang({commit}, payload) {
        commit("common_setLang", payload)
    },
    setPage({commit}, page) {
        commit("common_setPage", page)
    },
    setLoadingStatus({commit}, status) {
        commit("common_setLoadingStatus", status)
    },
    setListSeedingFacebook({commit}, listSeedingFacebook) {
        commit("common_setListSeeding_facebook", listSeedingFacebook)
    },
    setListSeedingInstagram({commit}, listSeedingInstagram) {
        commit("common_setListSeeding_instagram", listSeedingInstagram)
    },
    setListSeedingTiktok({commit}, listSeedingTiktok) {
        commit("common_setListSeeding_tiktok", listSeedingTiktok)
    }
};

// mutations
const mutations = {
    common_setSnack(state, snack) {
        state.snack = snack;
    },
    common_setLayout(state, layout) {
        state.layout = layout;
        localStorage.setItem("layout", layout);
    },
    common_setLang (state, payload) {
        state.lang = payload;
    },
    common_setPage (state, page) {
        state.page = page;
    },
    common_setLoadingStatus (state, status) {
        state.loading = status;
    },
    common_setListSeeding_facebook (state, listSeedingFacebook) {
        state.listSeeding = listSeedingFacebook;
    },
    common_setListSeeding_instagram (state, listSeedingInstagram) {
        state.listSeeding = listSeedingInstagram;
    },
    common_setListSeeding_tiktok (state, listSeedingTiktok) {
        state.listSeeding = listSeedingTiktok;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
