<template>
    <div class="jobs-facebook-detail">
        <v-container fluid grid-list-lg v-if="dataDetail">
            <v-row class="ma-2">
                <v-col cols="12">
                    <v-btn
                            :to="{ name : 'jobs_instagram' }"
                            color="primary text"
                            rounded
                            small
                    >
                        {{ back }}
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-row class="job box-shadow">
                        <v-col cols="3" md="1" class="text-center pa">
                            <div style="border-right: 1px solid #777777">
                                <v-avatar size="36">
                                    <img :src="dataDetail.icon" alt="">
                                </v-avatar>
                                <h6 class="body-1 font-weight-bold mt-1">
                                    <span><span class="hold-prices">{{ dataDetail.coin }}</span></span>
                                    <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon white--text"/>
                                </h6>
                            </div>
                        </v-col>
                        <v-col cols="9" md="11" class="caption job-info px-0">
                            <div>
                                <span class="text-uppercase primary--text font-weight-bold hidden-sm-and-down title">{{dataDetail.seeding_title}}</span>
                                <span class="text-uppercase primary--text font-weight-bold hidden-md-and-up caption">{{dataDetail.seeding_title}}</span><br/>
                                <span v-if="dataDetail.seeding_type === 8" class="black--text">Nội dung:
                                    <span class="hidden-md-and-up">{{ dataDetail.comment_need | jobContentFormat(25) }}</span>
                                    <span class="hidden-sm-and-down">{{ dataDetail.comment_need | jobContentFormat(80) }}</span>
                                </span>
<!--                                <span hidden id="comment">{{ dataDetail.comment_need}}</span>-->
                                <v-btn @click="copyComment" v-if="dataDetail.seeding_type === 8" class="mx-2" x-small max-width="25" max-height="25" dark color="cyan">
                                    copy
                                </v-btn>
                                <span v-if="dataDetail.seeding_type !== 8" class="black--text hidden-sm-and-down">Nội dung: {{ dataDetail.content }}</span>
                                <span v-if="dataDetail.seeding_type !== 8" class="black--text hidden-md-and-up">Nội dung: {{ dataDetail.content | jobContentFormat }}</span><br/>
                                <span class="caption">Job Id: <span class="font-weight-bold">{{ dataDetail.id }}</span> - Fb Id: <span class="font-weight-bold">{{ dataDetail.post_id }}</span></span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="ma-2 step-1">
                <v-col class="font-weight-bold body-2">
                    Bước 1: Làm việc
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow">
                    <v-divider cols="12" :inset="insetDivider"></v-divider>
                    <v-col cols="12 py-0"  @click="getData">
                        <a class="goto-link">
                            <v-row class="job">
                                <v-col cols="2" md="1" class="text-center px-0">
                                    <div>
                                        <v-icon color="primary" x-large>mdi-google-chrome</v-icon>
                                    </div>
                                </v-col>
                                <v-col cols="8" md="10" class="caption job-info px-0">
                                    <div>
                                        <span class="font-weight-bold">Web</span><br/>
                                        <span class="caption text-truncate">Làm việc bằng trình duyệt web.</span>
                                    </div>
                                </v-col>
                                <v-col cols="2" md="1" class="text-right">
                                    <v-icon>mdi-arrow-right</v-icon>
                                </v-col>
                            </v-row>
                        </a>
                    </v-col>

<!--                    <v-col cols="12 py-0">-->
<!--                        <a href="instagram://p?id=B-4M_Z1gx3u" target="_blank" class="goto-link">-->
<!--                            <v-row class="job">-->
<!--                                <v-col cols="2" md="1" class="text-center px-0">-->
<!--                                    <div>-->
<!--                                        <v-icon color="orange darken-1" x-large>mdi-instagram</v-icon>-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                                <v-col cols="8" md="10" class="caption job-info px-0">-->
<!--                                    <div>-->
<!--                                        <span class="font-weight-bold">App</span><br/>-->
<!--                                        <span class="caption text-truncate">Làm việc trên app Instagram.</span>-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                                <v-col cols="2" md="1" class="text-right">-->
<!--                                    <v-icon>mdi-arrow-right</v-icon>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                        </a>-->
<!--                    </v-col>-->

            </v-row>

            <v-row class="ma-2 step-1" v-if="stepVisible===true">
                <v-col class="font-weight-bold body-2">
                    Bước 2: Nhận tiền
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow" v-if="stepVisible===true">
                <v-col cols="12 py-0">
                    <v-row class="job complete-job" @click="completeJob">
                        <v-col cols="2" md="1" class="text-center px-0">
                            <div>
                                <v-icon color="warning" x-large>monetization_on</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="8" md="10" class="caption job-info px-0">
                            <div>
                                <span class="font-weight-bold">Hoàn thành</span><br/>
                                <span class="caption text-truncate">Bấm hoàn thành để nhận tiền sau khi làm việc xong.</span>
                            </div>
                        </v-col>
                        <v-col cols="2" md="1" class="text-right">
                            <v-icon>mdi-arrow-right</v-icon>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-row class="ma-2 step-1">
                <v-col class="font-weight-bold body-2">
                    Báo lỗi Job
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow" @click="showReport = !showReport">
                <v-col cols="12 py-0">
                    <v-row class="job">
                        <v-col cols="2" md="1" class="text-center px-0">
                            <div>
                                <v-icon color="red" x-large>warning</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="8" md="10" class="caption job-info px-0">
                            <div>
                                <span class="font-weight-bold">Báo lỗi</span><br/>
                                <span class="caption text-truncate">Báo lỗi cho hệ thống khi làm việc thất bại</span>
                            </div>
                        </v-col>
                        <v-col cols="2" md="1" class="text-right">
                            <v-icon>mdi-arrow-right</v-icon>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-row class="ma-2 step-1 box-shadow" v-if="showReport">
                <v-col cols="12 py-0">
                    <v-row class="job">
                         <v-col cols="12" class="caption pl-5">
                            <div>
                                <h3 class="font-weight-bold">Lỗi bạn gặp là gì ?</h3>
                                <v-radio-group v-model="reportErrors">
                                    <v-radio
                                            v-for="item in errors"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            class="lable-err"
                                    ></v-radio>

                                    <v-text-field label="Nhập ý kiến" v-if="reportErrors==7"></v-text-field>

                                    <v-btn width="100%" height="30px" color="blue white--text" @click="sendReport">
                                        Gửi báo cáo
                                    </v-btn>
                                </v-radio-group>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="ma-2 step-1 box-shadow">
                <v-col cols="12 py-0">
                    <div class="notice-detail mx-5 mt-5">
                        <p>
                            1. Tuyệt đối <span class="red--text">cấm</span> các hành động comment, review,.. không đúng yêu cầu của công việc hay phá hoại khách hàng.<br>

                            2. Nếu bị khách hàng báo cáo, tài khoản của bạn sẽ bị <span class="red--text">cấm sử dụng Farmer vĩnh viễn</span> và Farmer sẽ không trả bất kỳ 1 chi phí nào cho bạn.<br>

                            3. Nhấn nút <span class="blue--text">LÀM VIỆC</span>, sau đó nhấn <span class="red--text">LIKE</span> bài viết và quay lại Hana nhấn nút <span class="blue--text">HOÀN THÀNH</span> để nhận tiền thưởng.<br>

                            4. Farmer sẽ tự động kiểm tra ngẫu nhiên trong thời gian bảo hành của Job (thường là 7 ngày).<br>

                            5. Nếu bạn chưa thực hiện <span class="red--text">LIKE</span> bài viết sẽ bị trừ tiền, nhiều lần gian lận sẽ bị khóa tài khoản kiếm tiền.<br>
                        </p>
                    </div>
                </v-col>
            </v-row>
            <span class="white--text">Nội dung: <span id="comment">{{ dataDetail.comment_need }}</span></span>
        </v-container>
        <v-container v-else>
            <h3>Job không tồn tại !</h3>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import {INSTAGRAM_JOB_EXECUTE, INSTAGRAM_JOB_GET_DETAIL, INSTA_START_WORK} from "~/store/actions.type";
import {COMMON_SET_SNACK} from "~/store/actions.type";
import {INSTAGRAM_JOB_REPORT} from "../../../store/actions.type";
export default {
    components: {
    },
    data() {
        return {
            tab: null,
            coinSvg: '/images/coin.svg',
            likePageGif: '/images/like_page.gif',
            insetDivider: true,
            showReport: false,
            reportErrors: 1,
            overlay : false,
            errors: [
                {
                    value: 1,
                    label: "Tôi không muốn làm Job này"
                },
                {
                    value: 2,
                    label: "Tôi đã làm Job này rồi"
                },
                {
                    value: 3,
                    label: "Job đủ số lượng"
                },
                {
                    value: 4,
                    label: "Không tìm thấy bài viết"
                },
                {
                    value: 5,
                    label: "Báo cáo hoàn thành thất bại"
                },
                {
                    value: 6,
                    label: "Job có nội dung không lành mạnh"
                },
                {
                    value: 7,
                    label: "Ý kiến khác"
                },

            ],
            otherError: '',
            dataDetail: {},
            back : '<< Danh sách công việc',
            stepVisible: false
        }
    },
    filters: {
        jobContentFormat(content, count){
            if(typeof content !== "undefined" && content !== null){
                if(content.length > count){
                    return content.substring(0, count)+'...';
                }
                return content;
            }
            return content;
        },
    },
    computed: {

    },
    created() {
      this.getDataFromApi();
    },
    methods : {
      async removeJobInCache(job_id = '') {
        const account_id = await localStorage.getItem('instagram_account')
        const cacheJobKey = account_id.toString() + '_cached_jobs';
        let cachedObj = await localStorage.getItem(cacheJobKey);
        cachedObj = await JSON.parse(cachedObj);
        let newJobs = []

        const detail_job_id = job_id ? job_id : this.dataDetail.id.toString()

        for (const item of cachedObj.jobs) {
          if (item.id.toString() !== detail_job_id) {
            newJobs = await newJobs.concat(item)
          }
        }

        cachedObj.jobs = newJobs;
        await localStorage.setItem(cacheJobKey, JSON.stringify(cachedObj))
      },
      async getDataFromApi () {
        this.overlay = true;
          let params = {
              account_id: localStorage.getItem('instagram_account'),
              job_id: this.$route.params.id
          };
          let data = await this.$store.dispatch(INSTAGRAM_JOB_GET_DETAIL, params);
          if (!data) {
            this.overlay = false;
            await this.removeJobInCache(this.$route.params.id.toString());
            this.$router.push('/jobs/instagram')
          }
          this.dataDetail = data;
          if (this.dataDetail.comment_need !== null) {
              let arr = JSON.parse(data.comment_need);
              this.dataDetail.comment_need = arr[0];
          }
          this.overlay = false;
      },
      async getData() {
        this.overlay = true;
        let params = {
          job_id: this.$route.params.id,
          account_id: parseInt(localStorage.getItem('instagram_account'))
        };
        this.stepVisible = await this.$store.dispatch(INSTA_START_WORK, params);
        if (this.stepVisible) {
            this.openTab(this.dataDetail.link)
        } else {
          await this.removeJobInCache();
        }
        this.overlay = false;
      },
        completeJob () {
            let params = {
                account_id : localStorage.getItem('instagram_account'),
                job_id : this.$route.params.id
            };
            this.overlay = true;
            this.$store.dispatch(INSTAGRAM_JOB_EXECUTE, params).then(async (data) => {
              this.overlay = false;
              await this.removeJobInCache();
              if(typeof data !== "undefined" && typeof data.success !== "undefined" && data.success){
                  this.$router.push('/jobs/instagram')
              }
            });
        },
        sendReport () {
            let params = {
                account_id : localStorage.getItem('instagram_account'),
                job_id : this.$route.params.id
            };
            this.overlay = true;
            this.$store.dispatch(INSTAGRAM_JOB_REPORT, params).then(async (data) => {
              this.overlay = false;
              await this.removeJobInCache();
              if(typeof data !== "undefined" && typeof data.success !== "undefined" && data.success){
                this.$router.push('/jobs/instagram')
              }
            });
        },
        openTab(url) {
            // Create link in memory
            var a = window.document.createElement("a");
            a.target = '_blank';
            a.href = url;

            // Dispatch fake click
            var e = window.document.createEvent("MouseEvents");
            e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        copyComment() {
            try {
                let range = document.createRange();
                range.selectNode(document.getElementById("comment"));
                window.getSelection().removeAllRanges(); // clear current selection
                window.getSelection().addRange(range); // to select text
                document.execCommand("copy");
                window.getSelection().removeAllRanges();// to deselect
                this.$store.dispatch(COMMON_SET_SNACK, {
                    message: 'Đã copy comment thành công !',
                    type: "success"
                });
            }catch (e) {
                console.log('Copy comment thất bại !');
            }
        }
    }

};
</script>
<style lang="scss" scoped>
    .jobs-facebook-detail {
        .goto-link {
            text-decoration: unset;
            color: unset;
        }
        .complete-job {
            cursor: pointer;
        }
        .job {
            background-color: #ffffff;
            .job-info {
                position: relative;
                div {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .box-shadow {
            -webkit-box-shadow: -1px 0px 7px -1px rgba(0,0,0,0.75);
            -moz-box-shadow: -1px 0px 7px -1px rgba(0,0,0,0.75);
            box-shadow: -1px 0px 7px -1px rgba(0,0,0,0.75);
        }
        .notice-detail {
            font-size: 14px;
            line-height: 1.5rem;
        }
    }
</style>
