<template>
    <v-app id="inspire">
        <v-content>
            <v-container fluid fill-height :style="{'background-image': 'url(' + require('~/assets/farmer.png') + ')'}">
                <router-view></router-view>
            </v-container>
        </v-content>
        <v-overlay :value="overlay">
            <Maintain />
        </v-overlay>
    </v-app>
</template>

<script>
    import {IS_MAINTAIN} from "~/config";
    import Maintain from "~/components/Maintain";
export default {
    components: {
        Maintain
    },
    data() {
        return {
            overlay: IS_MAINTAIN,
            image : "'public/images/login.png'"
        }
    },
};
</script>
<style lang="scss" scoped>
    .container {
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
</style>
