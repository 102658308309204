import {
    LUCKY_WHEEL_GET_LIST_AWARD,
    LUCKY_WHEEL_PROCESS_WIN_AWARD,
    LUCKY_WHEEL_GET_HISTORY_AWARD,
    LUCKY_WHEEL_POST_NOTE_AWARD,
    LUCKY_WHEEL_GET_NOTE_AWARD
} from "../../store/actions.type";
import {
    COMMON_SET_SNACK,
} from "../../store/mutations.type";
import {LuckyWheelService} from "~/common/service.api";
import {getErrorMsg} from "~/common/helper.string";

const state = {
    accounts : {}
};

// getters
const getters = {
};

// actions
const actions = {
    [LUCKY_WHEEL_GET_LIST_AWARD]({commit}) {
        return new Promise((resolve) => {
            LuckyWheelService.get_list_award()
                .then(res => {
                    if( res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: getErrorMsg(err),
                        type: "error"
                    });
                    resolve(false);
                });
        });
    },
    [LUCKY_WHEEL_PROCESS_WIN_AWARD]({commit}) {
        return new Promise((resolve) => {
            LuckyWheelService.process_win_award()
            .then(res => {
                if( res.data.success) {
                    setTimeout(() => {
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "success"
                        });
                    }, 8000);
                    resolve(res.data.data);
                }else{
                    commit(COMMON_SET_SNACK, {
                        message: res.data.message,
                        type: "error"
                    });
                    resolve(false);
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: getErrorMsg(err),
                    type: "error"
                });
                resolve(false);
            });
        });
    },
    [LUCKY_WHEEL_GET_HISTORY_AWARD]({commit}) {
        return new Promise((resolve) => {
            LuckyWheelService.get_history_award()
            .then(res => {
                if( res.data.success) {
                    resolve(res.data.data);
                }else{
                    commit(COMMON_SET_SNACK, {
                        message: res.data.message,
                        type: "error"
                    });
                    resolve(false);
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: getErrorMsg(err),
                    type: "error"
                });
                resolve(false);
            });
        });
    },
    [LUCKY_WHEEL_POST_NOTE_AWARD]({commit}, params) {
        return new Promise((resolve) => {
            LuckyWheelService.post_note_award(params)
            .then(res => {
                if( res.data.success) {
                    commit(COMMON_SET_SNACK, {
                        message: res.data.message,
                        type: "success"
                    });
                    resolve(res.data.data);
                }else{
                    commit(COMMON_SET_SNACK, {
                        message: res.data.message,
                        type: "error"
                    });
                    resolve(false);
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: getErrorMsg(err),
                    type: "error"
                });
                resolve(false);
            });
        });
    },
    [LUCKY_WHEEL_GET_NOTE_AWARD]({commit}, params) {
        return new Promise((resolve) => {
            LuckyWheelService.get_note_award(params)
            .then(res => {
                if( res.data.success) {
                    resolve(res.data.data);
                }else{
                    commit(COMMON_SET_SNACK, {
                        message: res.data.message,
                        type: "error"
                    });
                    resolve(false);
                }
            })
            .catch(err => {
                commit(COMMON_SET_SNACK, {
                    message: getErrorMsg(err),
                    type: "error"
                });
                resolve(false);
            });
        });
    }
};

// mutations
const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
};
