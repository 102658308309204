<template>
    <v-app class="gray lighten-4">
        <template v-if="!overlay">
            <TopToolbar></TopToolbar>
            <v-content>
                <router-view></router-view>
            </v-content>
            <BottomNav></BottomNav>
        </template>

        <v-overlay :value="overlay">
            <v-card class="white--text text-center" color="warning" outlined>
                <Maintain />
            </v-card>
        </v-overlay>
    </v-app>
</template>

<script>
import { AUTH_USER } from "~/store/actions.type";
import TopToolbar from '~/components/TopToolbar.vue';
import BottomNav from '~/components/BottomNav.vue';
import {IS_MAINTAIN} from "~/config";
import Maintain from "~/components/Maintain";

export default {
    components: {
        TopToolbar,
        BottomNav,
        Maintain
    },
    data() {
        return {
            overlay: IS_MAINTAIN,
        }
    },
    computed: {},
    props: {
        source: String
    },
    created() {
        this.$store.dispatch('setPage', this.$router.currentRoute.name);
        this.checkValidToken();
    },
    methods: {
        logout() {
            this.$store.dispatch("auth_logout");
            this.$router.push("/login");
        },
        async checkValidToken() {
            let result = await this.$store.dispatch(AUTH_USER);
            if (result === false) {
                this.logout();
            }
            if(!result.success){
                this.overlay = true;
            }
        },
    }
};
</script>
<style lang="scss">
    .v-content__wrap {
        background-color: #f7f7f7 !important;
    }
    /*.v-application--wrap {*/
    /*    header {*/
    /*        left: 0px !important;*/
    /*    }*/
    /*    main{*/
    /*        padding-left: 0px !important;*/
    /*    }*/
    /*}*/
</style>
