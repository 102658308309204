<template>
    <div class="race_top">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col style="padding: unset;">
                    <v-card flat>
                        <v-tabs v-model="tab" dark grow icons-and-text class="menu_tab" style="background-color: #f7f7f7 !important;">
                            <v-tab key="top_coin" style="height: max-content;height: 88px;
            padding: unset;">
                                <div class="col-12 px-1" style="margin: unset;">
                                    <div class="card shadow-400 bg-gradient-3 h-100" style="background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
            color: white;
            border-radius: 10px;
            border: 0;
            height: 88px;">
                                        <div class="px-3 py-2 text-center">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAbCAYAAABr/T8RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADWSURBVHgB1dTbDcMgDAVQpxOwMekE7QbNRozkUglVlBg/CkbK/UticQhKLoBDEDGiEPCKhINnOBy8k400DOf5YJw/ht+4LJK0OIEmM5znns0CwYi+yv2ohvPMjud08R5aPY8i3EG7uITWuAWNeP49vjgyHxIVNdoeVYMfik3yMIcyuHaTNKxBhUWpTfIFonzTwODUPF8gSpQskIJr0N8CUaLzCwTl4/IrEOa4LlkgD7AGJxQIWIMTC2QqKuC+aAdfgzb4WnQkt49dXe/btt1hVf4+roG8AWAeEeEpUHzLAAAAAElFTkSuQmCC" alt="group-icon" width="25" class="group-icon"><span class="font-14 font-light d-block mt-2">Top</span>
                                            <h6 class="font-semi-bold" style="font-size: 15px;">Tháng {{ currentMonth }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </v-tab>
                            <v-tab key="top_ref" style="height: max-content;height: 88px;
            padding: unset;">
                                <div class="col-12 px-1" style="margin: unset;">
                                    <div class="card shadow-400 bg-gradient-2 h-100" style="background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
            color: white;
            border-radius: 10px;
            border: 0;
            height: 88px;">
                                        <div class="px-3 py-2 text-center">
                                            <i class="v-icon notranslate mdi mdi-account-group theme--light" style="color: #fff;"></i>
                                            <span class="font-14 font-light d-block mt-2">Top</span>
                                            <h6 class="font-semi-bold" style="font-size: 15px;">Mời Bạn</h6>
                                        </div>
                                    </div>
                                </div>
                            </v-tab>
                        </v-tabs>
                        <br>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="v">
                                <v-row>
                                    <v-col>
                                        <v-card class="pa-2 mx-auto primary--text" flat>
                                            <span class="title font-weight-bold ml-3">Bảng xếp hạng tháng</span>
                                            <v-btn @click="topCoinCurrentMonthShow = !topCoinCurrentMonthShow" 
                                                text icon color="primary" class="float-right">
                                                <v-icon large>
                                                    {{ topCoinCurrentMonthShow ? 'mdi-chevron-down' : 'mdi-chevron-right'}}
                                                </v-icon>
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                
                                <v-row v-if="topCoinCurrentMonthShow" class="row_area">
                                    <v-col cols="12 item_data" v-for="item in topCoinCurrentMonthData" :key="item.id">
                                        <v-card
                                            class="mx-auto"
                                            flat
                                            color="white"
                                        >
                                            <v-list-item class="pr-3 ml-5">
                                                <v-row>
                                                    <div class="ml-xs-1 ml-sm-3">
                                                        <span class="primary--text body-2 font-weight-bold">
                                                            {{ item.farmer_name }}
                                                        </span>
                                                        <span class="body-2 left_bottom">
                                                            {{ item.award_name }}
                                                        </span>
                                                    </div>
                                                    <div class="ml-xs-1 ml-sm-3 align_right">
                                                        <span v-if="item.top == 1" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-1">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 2" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-2">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 3" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-3">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span class="body-2 right_bottom">
                                                            {{ item.total_coin_in_month }} đ
                                                        </span>
                                                    </div>
                                                </v-row>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-card class="pa-2 mx-auto primary--text" flat>
                                            <span class="title font-weight-bold ml-3">Bảng xếp hạng tháng trước</span>
                                            <v-btn @click="topCoinLastMonthShow = !topCoinLastMonthShow"
                                                   text icon color="primary" class="float-right">
                                                <v-icon large>
                                                    {{ topCoinLastMonthShow ? 'mdi-chevron-down' : 'mdi-chevron-right'}}
                                                </v-icon>
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                
                                <v-row v-if="topCoinLastMonthShow" class="row_area">
                                    <v-col cols="12 item_data" v-for="item in topCoinLastMonthData" :key="item.id">
                                        <v-card
                                            class="mx-auto"
                                            flat
                                            color="white"
                                        >
                                            <v-list-item class="pr-3 ml-5">
                                                <v-row>
                                                    <div class="ml-xs-1 ml-sm-3">
                                                        <span class="primary--text body-2 font-weight-bold">
                                                            {{ item.farmer_name }}
                                                        </span>
                                                        <span class="body-2 left_bottom">
                                                            {{ item.award_name }}
                                                        </span>
                                                    </div>
                                                    <div class="ml-xs-1 ml-sm-3 align_right">
                                                        <span v-if="item.top == 1" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-1">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 2" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-2">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 3" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-3">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span class="body-2 right_bottom">
                                                            {{ item.total_coin_in_month }} đ
                                                        </span>
                                                    </div>
                                                </v-row>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                
                            </v-tab-item>
                            
                            <v-tab-item key="top_ref">

                                <!--<v-row>-->
                                    <!--<v-col>-->
                                        <!--<v-card class="top_ref_intro pa-2 white&#45;&#45;text mx-auto" outlined>-->
                                            <!--<div class="row align-items-center">-->
                                                <!--<div class="col-12">-->
                                                    <!--<span class="font-light font-14">Giải thưởng</span>-->
                                                    <!--<h6 class="font-semi-bold my-2 font-19">Top 1 của mời bạn nhận ngay 200.000</h6>-->
                                                <!--</div>-->
                                                <!--<div class="col-12">-->
                                                    <!--<span class="d-block font-light font-14">-->
                                                        <!--<i class="v-icon notranslate mdi mdi-information theme&#45;&#45;light white&#45;&#45;text"></i>-->
                                                        <!--Lưu ý: Tiền thưởng sẽ được gửi mỗi thứ 2 đầu tháng-->
                                                    <!--</span>-->
                                                    <!--<span class="d-block font-light font-14 text-center">-->
                                                        <!--(Chỉ tính những bạn được mời có làm việc tại Hana)-->
                                                    <!--</span>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</v-card>-->
                                    <!--</v-col>-->
                                <!--</v-row>-->
                                
                                <v-row>
                                    <v-col>
                                        <v-card class="pa-2 mx-auto primary--text" flat>
                                            <span class="title font-weight-bold ml-3">Bảng xếp hạng mời bạn</span>
                                            <v-btn @click="topRefCurrentMonthShow = !topRefCurrentMonthShow" 
                                                text icon color="primary" class="float-right">
                                                <v-icon large>
                                                    {{ topRefCurrentMonthShow ? 'mdi-chevron-down' : 'mdi-chevron-right'}}
                                                </v-icon>
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row v-if="topRefCurrentMonthShow" class="row_area">
                                    <v-col cols="12 item_data" v-for="item in topRefCurrentMonthData" :key="item.id">
                                        <v-card
                                            class="mx-auto"
                                            flat
                                            color="white"
                                        >
                                            <v-list-item class="pr-3 ml-5">
                                                <v-row>
                                                    <div class="ml-xs-1 ml-sm-3">
                                                        <span class="primary--text body-2 font-weight-bold">
                                                            {{ item.farmer_name }}
                                                        </span>
                                                        <span class="body-2 left_bottom">
                                                            {{ item.award_name }}
                                                        </span>
                                                    </div>
                                                    <div class="ml-xs-1 ml-sm-3 align_right">
                                                        <span v-if="item.top == 1" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-1">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 2" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-2">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 3" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-3">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span class="body-2 right_bottom">
                                                            {{ item.total_ref_in_month }}/{{ item.total_ref_until_month }} bạn
                                                        </span>
                                                    </div>
                                                </v-row>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-card class="pa-2 mx-auto primary--text" flat>
                                            <span class="title font-weight-bold ml-3">Bảng xếp hạng mời bạn tháng trước</span>
                                            <v-btn @click="topRefLastMonthShow = !topRefLastMonthShow"
                                                   text icon color="primary" class="float-right">
                                                <v-icon large>
                                                    {{ topRefLastMonthShow ? 'mdi-chevron-down' : 'mdi-chevron-right'}}
                                                </v-icon>
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row v-if="topRefLastMonthShow" class="row_area">
                                    <v-col cols="12 item_data" v-for="item in topRefLastMonthData" :key="item.id">
                                        <v-card
                                            class="mx-auto"
                                            flat
                                            color="white"
                                        >
                                            <v-list-item class="pr-3 ml-5">
                                                <v-row>
                                                    <div class="ml-xs-1 ml-sm-3">
                                                        <span class="primary--text body-2 font-weight-bold">
                                                            {{ item.farmer_name }}
                                                        </span> 
                                                        <span class="body-2 left_bottom">
                                                            {{ item.award_name }}
                                                        </span>
                                                    </div>
                                                    <div class="ml-xs-1 ml-sm-3 align_right">
                                                        <span v-if="item.top == 1" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-1">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 2" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-2">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else-if="item.top == 3" class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient bg-gradient-3">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span v-else class="font-14 d-inline-block font-light span-top px-2 
                                                            bg-gradient">
                                                            Top {{ item.top }}
                                                        </span>
                                                        <span class="body-2 right_bottom">
                                                            {{ item.total_ref_in_month }}/{{ item.total_ref_until_month }} bạn
                                                        </span>
                                                    </div>
                                                </v-row>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {
    RACE_TOP_GET_LIST
} from "~/store/actions.type";

export default {
    data() {
        return {
            tab: 0,
            currentMonth: 1,
            topCoinCurrentMonthShow : true,
            topCoinLastMonthShow : true,

            topRefCurrentMonthShow : true,
            topRefLastMonthShow : true,

            topCoinCurrentMonthData: {},
            topCoinLastMonthData: {},
            topRefCurrentMonthData: {},
            topRefLastMonthData: {},
        }
    },
    mounted() {
        this.getDataFromApi();
    },
    methods: {
        async getDataFromApi () {
            let race_top = await this.$store.dispatch(RACE_TOP_GET_LIST);
            
            this.currentMonth = race_top.currentMonth;
            
            this.topCoinCurrentMonthData = race_top.topCoinCurrentMonth;
            this.topCoinLastMonthData = race_top.topCoinLastMonth;
            this.topRefCurrentMonthData = race_top.topRefCurrentMonth;
            this.topRefLastMonthData = race_top.topRefLastMonth;
        },
    },
    
};
</script>
<style lang="scss">

    .race_top .menu_tab .v-item-group {
        background-color: #f7f7f7 !important;
    }

    .race_top .v-slide-group__wrapper {
        height: max-content;
    }

    .race_top .card shadow-400 bg-gradient-3 h-100 {
        background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
        color: white;
        border-radius: 10px;
        border: 0;
        height: 88px;
    }

    .race_top .card shadow-400 bg-gradient-2 h-100 {
        background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
        color: white;
        border-radius: 10px;
        border: 0;
        height: 88px;
    }


    .race_top .v-tabs-slider {
        display: none;
    }

    .race_top .v-tab {
        height: 88px;
        padding: unset;
    }

    .race_top .col-12 px-1 {
        margin: unset;
    }

    .race_top .v-card {
        border-radius: 15px !important;
        border: none!important;
    }
    .race_top .v-card.top_ref_intro {
        background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
    }
    .race_top .font-19 {
        font-size: 17px !important;
        font-weight: 500;
    }
    .race_top .font-14 {
        font-size: 14px !important;
    }
    .race_top .font-light {
        font-weight: 300;
    }
    .race_top .col-12 {
        padding-bottom: unset;
        padding-top: unset;
    }
    .race_top i.mdi-information {
        font-size: 19px !important;
    }
    .race_top .align_right{
        text-align: right;
        flex-grow: 1;
        margin-right: 12px !important;
    }
    .race_top .v-list-item {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }
    .race_top .span-top {
        border-radius: 100px;
        width: 69px;
        text-align: center;
    }
    
    //top 10
    .race_top .bg-gradient {
        color: white;
        background: #0084F8;
    }
    .race_top .bg-gradient-1 {
        background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%) !important;
    }
    .race_top .bg-gradient-2 {
        background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%) !important;
    }
    .race_top .bg-gradient-3 {
        background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%) !important;
    }

    .race_top .pl-2, .px-2 {
        padding-left: .5rem!important;
        padding-right: .5rem!important;
    }
    .race_top .d-inline-block {
        display: inline-block!important;
    }

    .race_top .mx-auto.v-card.v-card--flat.v-sheet.theme--light.white {
        padding: .5rem 0 !important;
    }

    .race_top .theme--light.v-tabs-items,
    .race_top .theme--light.v-card {
        background-color: #f7f7f7 !important;
    }
    .race_top .row_area{
        margin: 0px -6px;
    }
    .race_top .item_data{
        margin: 5px 0;
    }

    .race_top .left_bottom,
    .race_top .right_bottom {
        margin-top: 10px;
        display: block;
    }
    .race_top .float-right i.v-icon{
        font-size: 25px !important;
        background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        border-radius: 12px;
        color: #fff !important;
    }
</style>
