<template>
    <div class="account-manager-facebook">
        <v-container fluid grid-list-lg>
            <v-row class="ma-md-2">
                <v-col cols="12">
                    <h4 class="primary--text title">Các tài khoản</h4>
                </v-col>
                <v-col cols="12">
                    <v-card
                            class="mx-auto pb-3"
                            color="white"
                            flat
                    >
                        <v-expansion-panels focusable>
                            <v-expansion-panel
                                    v-for="account in accounts" :key="account.id"
                            >
                                <v-expansion-panel-header>
                                    <v-row class="account-list">
                                        <v-col cols="3" md="1" class="align-content-center">
                                            <v-avatar>
                                                <img width="50px" :src="account.profile_pic_url" alt="John">
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="9" md="5" class="show-info">
                                            <span class="primary--text body-1 font-weight-bold">{{ account.username }}
                                                <span class="caption ml-1 pa-1 black--text" style="background: #f8ff00;border-radius: 5px;" v-if="account.cookie_status == 2">Checkpoint</span>
                                                <span class="is-main-account caption ml-1 pa-1 white--text" v-if="account.is_main == 1 ">Tài khoản chính</span>
                                            </span><br/>
                                            <span class="caption font-weight-bold">Id : {{ account.id_account }}</span><br/>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                <v-row class="account-info">
                                    <v-col cols="12" class="body-2 text-center account-action" @click="setMainAccount(account.id)">Đặt làm tài khoản chính</v-col>
                                    <v-col cols="12" class="body-2">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="body-2 text-center account-action" @click="updateAccount(account.id)">Cập nhật thông tin tài khoản</v-col>
                                    <v-col cols="12" class="body-2">
                                        <v-divider></v-divider>
                                    </v-col>  
                                    <v-col cols="12" class="body-2 text-center account-action" @click="redirectProfile(account.link_profile)">Xem trang cá nhân</v-col>
                                    <v-col cols="12" class="body-2">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="body-2 text-center account-action" @click="deleteAccount(account.id)">Xóa tài khoản</v-col>
                                </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-col cols="12">
                            <v-card class="version pa-2 mx-auto text-center" color="primary"
                                    :to="{ name : 'account_manager_instagram_add' }">
                                <v-icon class="pl-3 mt-1 float-left" color="white" medium>mdi-account-plus
                                </v-icon>
                                <span class="title font-weight-bold ml-3 white--text">Thêm tài khoản</span>
                            </v-card>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {COMMON_SET_SNACK} from "~/store/actions.type";
    import {
        INSTAGRAM_UPDATE_ACCOUNT,
        INSTAGRAM_ACCOUNT_FETCH_ALL, INSTAGRAM_SET_MAIN_ACCOUNT, INSTAGRAM_DELETE_ACCOUNT
    } from "~/store/actions.type";
    import Swal from "sweetalert2";
    import {SWAL_SETTING_CONFIRM_DELETE} from "~/config/constants"
    import {AUTH_USER} from "../../../store/actions.type";
    global.jQuery = require('jquery');
    let $ = global.jQuery;
    window.$ = $;
    export default {
        data() {
            return {
                show_account_info: false,
                facebook_img: '/images/facebook.png',
                accounts: null,
                security: 0,
                slider : 2
            }
        },
        created() {
            this.instagramAccountFetchAll();
            this.initialize();
        },
        methods: {
            async initialize() {
                let currentUser = await this.$store.dispatch(AUTH_USER);
                this.security = currentUser.data.data.security;
            },
            instagramAccountFetchAll() {
                this.$store.dispatch(INSTAGRAM_ACCOUNT_FETCH_ALL)
                    .then(({data}) => {
                        this.accounts = data;
                        console.log(data);
                    })
                .catch(() => {
                   this.$store.dispatch(COMMON_SET_SNACK, {
                       message : 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
                       type : 'error'
                   });
                });
            },
            updateAccount(account_id){
                this.$store.dispatch(INSTAGRAM_UPDATE_ACCOUNT, account_id).then(()=>{
                    this.instagramAccountFetchAll();
                });
            },
            redirectProfile(link_profile){
                window.open(link_profile, "_target");
            },
            deleteAccount(account_id){
                switch (this.security) {
                    default:
                        Swal.fire(SWAL_SETTING_CONFIRM_DELETE('instagram')).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Hãy nhập mật khẩu của bạn',
                                    input: 'password',
                                    inputAttributes: {
                                        autocapitalize: 'off',
                                        required: 'true'
                                    },
                                    validationMessage: 'Bạn cần nhập mật khẩu',
                                    showCancelButton: true,
                                    confirmButtonText: 'Xác nhận',
                                    cancelButtonText: 'Bỏ qua',
                                    showLoaderOnConfirm: true,
                                    preConfirm: (password) => {
                                        let params = {
                                            'password' : password,
                                            'id' : account_id
                                        }
                                        return this.$store.dispatch(INSTAGRAM_DELETE_ACCOUNT, params);
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                }).then((result) => {
                                    if (result.value === true) {
                                        this.instagramAccountFetchAll()
                                    }
                                })
                            }
                        })
                }
            },
            setMainAccount(account_id){
                this.$store.dispatch(INSTAGRAM_SET_MAIN_ACCOUNT, account_id).then(() => {
                    this.instagramAccountFetchAll();
                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .account-manager-facebook {
        .v-avatar {
            width: 65px !important;
            height: 65px !important;

            img {

            }
        }

        .account-action {
            cursor: pointer;
        }

        .v-list-item {
            position: relative;
            .chevron-right {
                position: absolute;
                top: 40px;
                right: 0px;
            }
        }

        .is-main-account{
            border-radius: 5px;
            background-color:#EF5350;
        }

    }

</style>
