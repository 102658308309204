import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import TokenService from "~/common/service.token";
import { API_URL } from "~/config";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        this.setHeader();
    },

    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${TokenService.getToken()}`;
    },

    removeHeader() {
        delete Vue.axios.defaults.headers.common["Authorization"];
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`[Farmer] ApiService ${error}`);
        });
    },

    get(resource, slug = "") {
        let url = "";
        if (slug === "") {
            url = resource;
        } else {
            url = `${resource}/${slug}`;
        }
        return Vue.axios.get(url);
    },

    query_params_string(resource, params) {
        return Vue.axios
            .get(`${resource}?${new URLSearchParams(params).toString()}`);
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    update(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    delete(resource, params) {
        return Vue.axios
            .delete(`${resource}`, { params: params });
    }
};

export default ApiService;

const FACEBOOK_ACCOUNT = "facebook-account/account";

export const FacebookAccountService = {
    create(params) {
        let resource = 'facebook-account/add-facebook';
        return ApiService.post(resource, params);
    },
    set_main(id) {
        let resource = 'facebook-account/set-main';
        return ApiService.post(resource + '/' + id);
    },
    update(id) {
        let resource = 'facebook-account/update-account';
        return ApiService.post(resource + '/' + id);
    },
    get() {
        let resource = 'facebook-account/account';
        return ApiService.get(resource);
    },
    destroy(id, params) {
        return ApiService.delete(`${FACEBOOK_ACCOUNT}/${id}`, params);
    },
    get_post_id(params) {
        let resource = 'facebook-account/get-post-id';
        return ApiService.query_params_string(resource, params);
    },
    get_percent_presenter() {
        let resource = 'get-percent-presenter';
        return ApiService.get(resource);
    },
    createMulti(params) {
        let resource = 'facebook-account/add-facebook-multi';
        return ApiService.post(resource, params);
    },
};

const FACEBOOK_JOBS = "facebook-jobs";
export const FacebookJobService = {
    query_params_string(params) {
        return ApiService.query_params_string(FACEBOOK_JOBS, params);
    },

    get_list_job_v2(params) {
        return ApiService.query_params_string(FACEBOOK_JOBS + '/index-captcha', params);
    },
    get_list_seeding(params) {
        return ApiService.query_params_string(FACEBOOK_JOBS + '/list-seeding', params);
    },
    get_list_faqs() {
        return ApiService.get('faqs');
    },
    get(params, params2) {
        console.log(params, params2);
        return ApiService.query_params_string(FACEBOOK_JOBS + '/detail', params);
    },
    execute(params) {
        return ApiService.post(FACEBOOK_JOBS + '/execute', params);
    },
    report(params) {
        return ApiService.post(FACEBOOK_JOBS + '/report', params);
    },
    get_jobs_by_status(params) {
        return ApiService.query_params_string(FACEBOOK_JOBS + '/get-my-jobs', params);
    },
    start_work(params) {
        return ApiService.post(FACEBOOK_JOBS + '/start-work', params);
    },
};
export const WithdrawService = {
    query_params_string(uri, params) {
        return ApiService.query_params_string(uri, params);
    },
    get(param) {
        return ApiService.get(param);
    },
    post(uri, params) {
        return ApiService.post(uri, params);
    },
};

export const FarmerAccountService = {
    sendEmailChangeInfo(params) {
        let resource = 'send-email-change-information';
        return ApiService.post(resource, params);
    },
    sendEmailChangePassword(params) {
        let resource = 'send-email-update-password';
        return ApiService.post(resource, params);
    },
    update(params) {
        let resource = 'change-information';
        return ApiService.post(resource, params);
    },
    change_password(params) {
        let resource = 'change-password';
        return ApiService.post(resource, params);
    },
    set_second_password(params) {
        let resource = 'set-second-password';
        return ApiService.post(resource, params);
    },
    update_second_password(params) {
        let resource = 'update-second-password';
        return ApiService.post(resource, params);
    },
    turn_on_second_password(params) {
        let resource = 'on-second-password';
        return ApiService.post(resource, params);
    },
    turn_off_second_password(params) {
        let resource = 'off-second-password';
        return ApiService.post(resource, params);
    },
    last_history_withdrawal_success() {
        let resource = 'farmer/last-history-withdrawal-success';
        return ApiService.get(resource);
    },
    get_coin() {
        let resource = 'get-coin-farmer';
        return ApiService.get(resource);
    },
    destroy(id, params) {
        return ApiService.delete('delete-permanently', params);
    },
};

export const AuthService = {
    post(resource, params) {
        delete Vue.axios.defaults.headers.common["Authorization"];
        return Vue.axios.post(`${resource}`, params);
    }
};

export const ReferralService = {
    get() {
        let resource = 'list-presenter';
        return ApiService.get(resource);
    }
};

/*--------------Instagram------------*/

export const InstagramAccountService = {
    create(params) {
        let resource = 'instagram/add';
        return ApiService.post(resource, params);
    },
    set_main(id) {
        let resource = 'instagram/set-main';
        return ApiService.post(resource + '/' + id);
    },
    update(id) {
        let resource = 'instagram/update-account';
        return ApiService.post(resource + '/' + id);
    },
    get() {
        let resource = 'instagram/account';
        return ApiService.get(resource);
    },
    destroy(id, params) {
        return ApiService.delete('instagram/account/' + id, params);
    },
    get_post_id(params) {
        let resource = 'facebook-account/get-post-id';
        return ApiService.query_params_string(resource, params);
    },
    get_percent_presenter() {
        let resource = 'get-percent-presenter';
        return ApiService.get(resource);
    }
};

const INSTAGRAM_JOBS = "instagram";
export const InstagramJobService = {
    query_params_string(params) {
        return ApiService.query_params_string(INSTAGRAM_JOBS + '/get-farmer-job', params);
    },
    get_ins_job_v2(params) {
        return ApiService.query_params_string(INSTAGRAM_JOBS + '/index-captcha', params);
    },
    get(params) {
        return ApiService.query_params_string(INSTAGRAM_JOBS + '/detail', params);
    },
    execute(params) {
        return ApiService.post(INSTAGRAM_JOBS + '/execute', params);
    },
    report(params) {
        return ApiService.post(INSTAGRAM_JOBS + '/report', params);
    },
    get_my_jobs(params) {
        return ApiService.query_params_string(INSTAGRAM_JOBS + '/get-my-jobs', params);
    },
    start_work(params) {
        return ApiService.post(INSTAGRAM_JOBS + '/start-work', params);
    }
};

// ==================== TIKTOK =======================
export const TikTokAccountService = {
    create(params) {
        let resource = 'tiktok/add';
        return ApiService.post(resource, params);
    },
    set_main(id) {
        let resource = 'tiktok/set-main';
        return ApiService.post(resource + '/' + id);
    },
    update(id) {
        let resource = 'tiktok/update-account';
        return ApiService.post(resource + '/' + id);
    },
    updateCookie(params) {
        let resource = 'tiktok/update-cookie';
        return ApiService.post(resource, params);
    },
    get() {
        let resource = 'tiktok/account';
        return ApiService.get(resource);
    },
    destroy(id, params) {
        return ApiService.delete('tiktok/account/' + id, params);
    },
    get_post_id(params) {
        let resource = 'tiktok-account/get-post-id';
        return ApiService.query_params_string(resource, params);
    },
    get_percent_presenter() {
        let resource = 'get-percent-presenter';
        return ApiService.get(resource);
    }
};

const TIKTOK_JOBS = "tiktok";
export const TikTokJobService = {
    query_params_string(params) {
        return ApiService.query_params_string(TIKTOK_JOBS + '/get-farmer-job', params);
    },
    get_tiktok_job_v2(params) {
        return ApiService.query_params_string(TIKTOK_JOBS + '/index-captcha', params);
    },
    get(params) {
        return ApiService.query_params_string(TIKTOK_JOBS + '/detail', params);
    },
    execute(params) {
        return ApiService.post(TIKTOK_JOBS + '/execute', params);
    },
    report(params) {
        return ApiService.post(TIKTOK_JOBS + '/report', params);
    },
    get_my_jobs(params) {
        return ApiService.query_params_string(TIKTOK_JOBS + '/get-my-jobs', params);
    },
    start_work(params) {
        return ApiService.post(TIKTOK_JOBS + '/start-work', params);
    },
    get_data_post(params) {
        return ApiService.get(TIKTOK_JOBS + '/get-data-post?post_link=' + params);
    },
    get_data_user(params) {
        return ApiService.get(TIKTOK_JOBS + '/get-data-user?post_link=' + params);
    }
};

const LUCKY_WHEEL = "lucky-wheel";
export const LuckyWheelService = {
    get_list_award() {
        return ApiService.get(LUCKY_WHEEL + '/get-list-award');
    },
    process_win_award() {
        return ApiService.post(LUCKY_WHEEL + '/process-win-award');
    },
    get_history_award() {
        return ApiService.get(LUCKY_WHEEL + '/get-history-awardV2');
    },
    post_note_award(params) {
        return ApiService.post(LUCKY_WHEEL + '/post-note-awardV2', params);
    },
    get_note_award(id) {
        return ApiService.get(LUCKY_WHEEL + '/get-note-awardV2/' + id);
    }
};

export const ViewTubAccountService = {
    create(params) {
        let resource = 'viewtub/add';
        return ApiService.post(resource, params);
    },
    set_main(id) {
        let resource = 'viewtub/set-main';
        return ApiService.post(resource + '/' + id);
    },
    update(id) {
        let resource = 'viewtub/update-account';
        return ApiService.post(resource + '/' + id);
    },
    get() {
        let resource = 'viewtub/account';
        return ApiService.get(resource);
    },
    destroy(id, params) {
        return ApiService.delete('viewtub/account/' + id, params);
    }
};

// ==================== TOOL_DOWNLOAD =======================
const TOOL_DOWNLOAD = "tool_download";
export const ToolDownloadService = {
    get() {
        return ApiService.get(TOOL_DOWNLOAD);
    },
};

// ==================== RACE_TOP =======================
const RACE_TOP = "race_top";
export const RaceTopService = {
    get() {
        return ApiService.get(RACE_TOP + '/get_top');
    },
};
