<template>
    <div class="withdraw-container">
        <v-container fluid grid-list-lg>

            <v-row>
                <v-col cols="12">
                    <v-btn :to="{ name : 'lucky_wheel', params: {type: 'history'} }"
                        outlined class="ma-2">
                        Trở lại danh sách
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="mb-2" style="color: red; font-style: italic">Lưu ý: Nếu thông tin không chính xác, chúng tôi sẽ không trao quà cho bạn!</span>
                    <v-card class="pa-2 bg-white" outlined style="margin-top: 20px !important;">
                        <v-form ref="form_farmer_save_note">
                            <div class="mb-2 awardName">Giải thưởng: <span>{{ awardName }}</span></div><br>

                            <!--ÁO HANA-->
                            <div v-if="award_id === 2">
                                <span class="mb-2">Size áo</span>
                                <v-select v-if="award_id === 2"
                                          solo
                                          :items="sizes"
                                          v-model="size"
                                          item-text="name"
                                          item-value="value"
                                          :rules="sizeRules"
                                ></v-select>
                            </div>

                            <!--ÁO HANA, MŨ HANA-->
                            <div v-if="award_id === 2 || award_id === 10">
                                <span class="mb-2">Tên người nhận</span>
                                <v-text-field
                                    solo
                                    :items="fullname"
                                    v-model="fullname"
                                    item-text="name"
                                    item-value="value"
                                    :rules="fullnameRules"
                                ></v-text-field>

                                <span class="mb-2">Địa chỉ người nhận</span>
                                <v-text-field
                                    solo
                                    :items="address"
                                    v-model="address"
                                    item-text="name"
                                    item-value="value"
                                    :rules="addressRules"
                                ></v-text-field>
                            </div>

                            <!--THẺ 20K, THẺ 100K, THẺ 500K, THẺ 50K-->
                            <div v-if="award_id === 1 || award_id === 4 || award_id === 5 || award_id === 8">
                                <span class="mb-2">Nhà mạng</span>
                                <v-select
                                    solo
                                    :items="telcos"
                                    v-model="telco"
                                    item-text="name"
                                    item-value="value"
                                    :rules="telcoRules"
                                ></v-select>
                                <span class="mb-2">Loại hình</span>
                                <v-select v-if="telco !== 2"
                                          solo
                                          :items="simtypes"
                                          v-model="simtype"
                                          item-text="name"
                                          item-value="value"
                                ></v-select>
                                <v-select v-if="telco === 2"
                                          solo
                                          :items="simtypesMobi"
                                          v-model="simtypeMobi"
                                          item-text="name"
                                          item-value="value"
                                ></v-select>
                            </div>

                            <!--MOMO 10K, MOMO 20K, MOMO 50K, MOMO 100K-->
                            <div v-if="award_id === 11 || award_id === 12 || award_id === 13 || award_id === 14">
                                <span class="mb-2">Tên người nhận</span>
                                <v-text-field
                                    solo
                                    :items="fullname"
                                    v-model="fullname"
                                    item-text="name"
                                    item-value="value"
                                    :rules="fullnameRules"
                                ></v-text-field>
                                
                                <span class="mb-2">Link nhận tiền của tôi (lấy ở trong app momo)</span>
                                <v-text-field
                                    solo
                                    :items="linkMomo"
                                    v-model="linkMomo"
                                    item-text="name"
                                    item-value="value"
                                    :rules="linkMomoRules"
                                ></v-text-field>
                            </div>

                            <!--BANK 50K, BANK 100K-->
                            <div v-if="award_id === 15 || award_id === 16">
                                <span class="mb-2">Ngân hàng</span>
                                <v-autocomplete
                                    solo
                                    v-model="bankName"
                                    :items="bankNames"
                                    item-text="name"
                                    item-value="value"
                                    label="Gõ để tìm kiếm ngân hàng nhanh hơn"
                                    required
                                    :rules="bankNameRules"
                                ></v-autocomplete>
                                <span class="mb-2">Tên chủ thẻ</span>
                                <v-text-field
                                    v-model="nameAccount"
                                    solo
                                    required
                                    type="text"
                                    :rules="nameAccountRules"
                                ></v-text-field>
                                <span class="mb-2">Số tài khoản</span>
                                <v-text-field
                                    v-model="noAccount"
                                    solo
                                    required
                                    type="number"
                                    :rules="noAccountRules"
                                ></v-text-field>
                                <span class="mb-2">Chi nhánh</span>
                                <v-text-field
                                    v-model="branchName"
                                    solo
                                    required
                                    type="text"
                                    :rules="branchNameRules"
                                ></v-text-field>
                            </div>

                            <!--GIA HẠN AUTO +3 NGÀY: award_id === 17 -->
                            <!--done-->

                            <div>
                                <span class="mb-2">Số điện thoại người nhận</span>
                                <v-text-field
                                    v-model="mobile"
                                    solo
                                    :counter="11"
                                    type="number"
                                    :rules="mobileRules"
                                ></v-text-field>
                            </div>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn width="100%" height="50px" color="blue" class="white--text" @click="post_note_award()">
                        Lưu
                    </v-btn>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
    import {
        LUCKY_WHEEL_GET_LIST_AWARD,
        LUCKY_WHEEL_POST_NOTE_AWARD,
        LUCKY_WHEEL_GET_NOTE_AWARD,
        WITHDRAW_DETAIL_FORM
    } from "~/store/actions.type";
    import Swal from 'sweetalert2';

    export default {
        data() {
            return {
                award_id: 0,
                awardName: '',
                size: 'S',
                sizes: [
                    'S',
                    'M',
                    'L',
                    'XL',
                    'XXL',
                    'XXXL'
                ],
                fullname: '',
                address: '',
                mobile: '',
                telco: 1,
                telcos: [],
                simtype: 0,
                simtypes: [
                    {name: 'Trả trước', value: 0},
                    {name: 'Trả sau', value: 1}
                ],
                simtypeMobi: 0,
                simtypesMobi: [
                    {name: 'Trả trước', value: 0}
                ],
                linkMomo: '',

                bankNames: [],
                bankName: "",
                nameAccount:"",
                noAccount:"",
                branchName:"",

                //rules
                sizeRules: [
                    v => !!v || 'Vui lòng nhập Size áo'
                ],
                fullnameRules: [
                    v => !!v || 'Vui lòng nhập Tên người nhận'
                ],
                addressRules: [
                    v => !!v || 'Vui lòng nhập Địa chỉ người nhận'
                ],
                telcoRules: [
                    v => !!v || 'Vui lòng nhập Nhà mạng'
                ],
                mobileRules: [
                    v => !!v || 'Vui lòng nhập Số điện thoại người nhận',
                    v => (v && v.length <= 11 && v.length >=10) || 'Số điện thoại trong khoản 10-11 ký tự',
                ],
                linkMomoRules: [
                    v => !!v || 'Vui lòng nhập link nhận tiền hợp lệ'
                ],
                bankNameRules: [
                    v => !!v || 'Vui lòng chọn ngân hàng'
                ],
                nameAccountRules: [
                    v => !!v || 'Vui lòng nhập Tên chủ thẻ'
                ],
                noAccountRules: [
                    v => !!v || 'Vui lòng nhập Số tài khoản'
                ],
                branchNameRules: [
                    v => !!v || 'Vui lòng nhập Chi nhánh'
                ],
            }
        },
        computed: {
            coin() {
                return this.$store.getters.coin;
            },
        },
        methods: {
            validateForm() {
                let validate = this.$refs.form_farmer_save_note.validate();
                return validate;
            },
            getHmlConfirm() {
                var size_html       = this.size == null ? '' : "Size áo: " + this.size + "<br>";
                var fullname_html   = this.fullname == null ? '' : "Tên người nhận: " + this.fullname + "<br>";
                var address_html    = this.address == null ? '' : "Địa chỉ người nhận: " + this.address + "<br>";
                var telco_html      = this.telco == null ? '' : "Nhà mạng: " + this.telcos.find(x => x.value === this.telco).name + "<br>";
                var mobile_html     = this.mobile == null ? '' : "Số điện thoại người nhận: " + this.mobile + "<br>";

                var simtype_html = '';
                if (this.simtype == null) {
                    simtype_html = '';
                } else {
                    var simtypeVal = this.telco == 2 ? 0 : this.simtype;
                    simtype_html = "Loại hình: " + this.simtypes.find(x => x.value === simtypeVal).name + "<br>";
                }

                var link_momo_html     = this.linkMomo == null ? '' : "Link nhận tiền của tôi: " + this.linkMomo + "<br>";
                
                var bank_name_html = '';
                var bank_account_html = '';
                var bank_no_account_html = '';
                var bank_branch_name_html = '';
                
                if (this.award_id == 15 || this.award_id == 16) {//bank
                    bank_name_html = this.bankName == null ? '' : "Ngân hàng: " + this.bankNames[this.bankName].name + "<br>";
                    bank_account_html = this.nameAccount == null ? '' : "Tên chủ thẻ: " + this.nameAccount + "<br>";
                    bank_no_account_html = this.noAccount == null ? '' : "Số tài khoản: " + this.noAccount + "<br>";
                    bank_branch_name_html = this.branchName == null ? '' : "Chi nhánh: " + this.branchName + "<br>";
                }
                
                let html = "<h3>" +
                    "Giải thưởng: " + this.awardName + "<br>" +
                    size_html +
                    fullname_html +
                    address_html +
                    telco_html +
                    simtype_html +
                    mobile_html +
                    link_momo_html +
                    bank_name_html +
                    bank_account_html +
                    bank_no_account_html +
                    bank_branch_name_html +
                    "</h3>";
                return html;
            },
            post_note_award(){
                if (this.validateForm()) {
                    
                    var bank_name = '';
                    if (this.award_id == 15 || this.award_id == 16) {
                        bank_name = this.bankNames[this.bankName].name;
                    }
                    
                    let params_post = {
                        id: this.$route.params.id,
                        size: this.size,
                        fullname: this.fullname,
                        address: this.address,
                        mobile: this.mobile,
                        telco: this.telco,
                        simtype: this.telco == 2 ? 0 : this.simtype,
                        link_momo: this.linkMomo,
                        bank_name: bank_name,
                        account_name: this.nameAccount,
                        account_id: this.noAccount,
                        branch_name: this.branchName,
                    };
                    Swal.fire({
                        title: 'Bạn có chắc chắn muốn lưu thông tin nhận giải:',
                        html: this.getHmlConfirm(),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Vâng, chính xác!',
                        cancelButtonText: 'Không'
                    }).then((result) => {
                        if (result.value) {
                            this.$store.dispatch(LUCKY_WHEEL_POST_NOTE_AWARD, params_post).then(res =>{
                                if(res) {
                                    let aThis = this;
                                    setTimeout(function () {
                                        aThis.$router.push('/lucky-wheel/history');
                                    }, 3000);
                                }
                            });
                        }
                    })
                }
            },
            async get_data_api () {
                let dataAward = await this.$store.dispatch(LUCKY_WHEEL_GET_LIST_AWARD);

                if (dataAward.setting.is_maintain === 1) {
                    alert('Vòng quay đang được bảo trì. Hệ thống sẽ hoạt động trở lại trong thời gian sớm nhất.');
                    this.$router.push('/');
                }
                
                let id = this.$route.params.id;
                let data = await this.$store.dispatch(LUCKY_WHEEL_GET_NOTE_AWARD, id);

                if (data !== false) {

                    let homeNetworksValues  = Object.values(data.homeNetworks);
                    let homeNetworksKeys    = Object.keys(data.homeNetworks);

                    //mobile
                    for(let i=0; i< homeNetworksValues.length; i++) {
                        this.telcos.push({
                            name:   homeNetworksValues[i],
                            value:  parseInt(homeNetworksKeys[i])
                        });
                    }

                    let item = data.historyDetail;

                    this.award_id   = item.award_id;
                    this.awardName  = item.awardName;
                    this.size       = item.size;
                    this.fullname   = item.fullname;
                    this.address    = item.address;
                    this.mobile     = item.mobile;
                    this.telco      = item.telco;
                    this.linkMomo   = item.linkMomo;

                    if (this.award_id == 1
                    || this.award_id == 4
                    || this.award_id == 5
                    || this.award_id == 8) {
                        this.simtype = item.simtype == null ? 0 : 1;
                    } else {
                        this.simtype = item.simtype;
                    }
                } else {
                    this.$router.push('/lucky-wheel/history');
                }

                //bank
                let dataDetail = await this.$store.dispatch(WITHDRAW_DETAIL_FORM, {form_convert_id: 1});

                if (dataDetail !== false) {
                    this.dataDetail = dataDetail;
                    for(let i=0; i< Object.values(dataDetail.banks).length; i++) {
                        this.bankNames.push({
                            name:  Object.values(dataDetail.banks)[i],
                            value:  i
                        });
                    }
                }
            },
        },
        mounted() {
            this.get_data_api();
        }
    };
</script>
<style scoped lang="scss">
    .swal2-container .swal2-popup{
        font-family: Arial !important;
    }
    .withdraw-container {
        .goto-link {
            text-decoration: unset;
            color: unset;
        }
        .v-card {
            border-radius: 15px;
            border: none;
        }
        .v-card.bg-gradient {
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        }
        .v-list__group__header__prepend-icon .v-icon {
            color: red !important;
        }
        .v-card.notice {
            background: linear-gradient(69.69deg, #FF8576 9.02%, #FF3B30 90.81%);
        }
        .title {
            color: #0084F8;
        }
        .div-flag {
            width: 100%;
            height: 3px;
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
            margin-top: 3px;
        }

    }
    div.awardName span{
        color: #2196F3;
    }


</style>
