<template>
    <div class="faqs">
        <v-container fluid grid-list-lg>
            <v-row>
                <h2 class="ml-5">Bạn cần hỗ trợ gì?</h2><br>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                            class="mt-5"
                            outlined
                            label="Bạn cần tìm ..."
                            v-model="search"
                            prepend-inner-icon="search"
                    ></v-text-field>
                </v-col>

            </v-row>
            <v-row>
                <v-col>
                    <a :href="link.fanpage" target="_blank" class="goto-link">
                        <v-card class="fanpage pa-2 white--text text-center bg-gradient" outlined>
                            <v-icon color="white" large>mdi-facebook</v-icon><br />
                            <span>Hỗ trợ</span><br />
                            <span>Facebook</span>
                        </v-card>
                    </a>
                </v-col>
                <v-col>
                    <a :href="link.telegram" target="_blank" class="goto-link">
                        <v-card class="telegram pa-2 white--text text-center bg-gradient" outlined>
                            <v-icon color="white" large>mdi-telegram</v-icon><br />
                            <span>Hỗ trợ</span><br />
                            <span>Telegram</span>
                        </v-card>
                    </a>

                </v-col>
            </v-row>
            <v-row>
                <div class="col-sm-12 mb-n3 text-center">
                    <iframe width="800" height="500" src="https://www.youtube-nocookie.com/embed/KdrUX1r4Xpc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </v-row>
            <v-row>
                <h2 class="ml-5 mt-3">Câu hỏi thường gặp?</h2><br>
            </v-row>
            <v-row>
                <v-list class="ml-5 col-xs-12 col-md-11">
                    <v-list-group
                            v-for="faq in faqs"
                            :key="faq.question"
                            prepend-icon="mdi-frequently-asked-questions"
                            no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <div class="" v-html="faq.question"></div>
                            </v-list-item-content>
                        </template>

                        <v-list-item class="pl-xs-4">
                            <div class="" v-html="nToBr(faq.answer)"></div>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {LIST_FAQS} from "~/store/actions.type";

export default {
    data() {
        return {
            link: {
                deposit: 'https://amai-customer.ml/',
                fanpage: 'https://www.facebook.com/HanaSupport',
                telegram: 'https://t.me/hanakiemtienonline',
            },
            search:'',
            faqs: [],
            faqsClone:[],

        }
    },
    created() {
    },
    watch: {
      search() {
          let s = this.search;
          if (s.trim() !== '') {
              this.faqs = this.faqsClone.filter(name => name.question.includes(s))
          } else {
              this.faqs = this.faqsClone;
          }
      }
    },
    methods: {
        async getDataFromApi () {
            let data = await this.$store.dispatch(LIST_FAQS);
            if (data !== false) {
                this.faqs = data;
                this.faqsClone = data;
            }

        },
        nToBr(value) {
            if (!value) return ''
            value = value.toString()
            return value.replace(/\n/g, "<br>")
        }
    },
    mounted() {
        this.getDataFromApi();
    }
};
</script>
<style scoped lang="scss">
    .faqs {
        .goto-link {
            text-decoration: unset;
            color: unset;
        }
        .v-card {
            border-radius: 15px;
            border: none;
        }
        .v-card.bg-gradient {
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        }
        .v-list__group__header__prepend-icon .v-icon {
            color: red !important;
        }
    }


</style>
