<template>
    <div class="tool">
        <v-container fluid grid-list-lg class="text-center">

            <!--pc-->
            <v-row
                class="pc ma-2 pa-5">
                <div class="ml-3 white--text" style="width: 100%">
                    <span class="headline font-weight-bold">Bạn có chắc chắn muốn xoá tài khoản của này vĩnh viễn?</span><br/>
                    <span class="col-8 body-2">"Xoá tài khoản vĩnh viễn" đồng nghĩa với việc bạn sẽ không thể sử dụng nó trong tương lai. Nếu bạn chắc chắn về quyết định này. Hãy Ấn nút "Xoá tài khoản vĩnh viễn" bên dưới để hoàn tất việc xoá tài khoản hana.</span>
                </div>
                <div class="row col-12">
                    <div class="col-12 text-center">
                        <v-btn
                            outlined
                            class="ma-2 white--text link_download"
                            target="_blank"
                            @click="deleteAccount()"
                        >
                            Xoá tài khoản vĩnh viễn
                            <v-icon color="white" right dark>mdi-delete</v-icon>
                        </v-btn>
                        <br><br>
                    </div>
                </div>
            </v-row>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {SWAL_SETTING_CONFIRM_DELETE_HANA_ACCOUNT} from "~/config/constants"
    import {DELETE_PERMANENTLY_ACCOUNT} from "~/store/actions.type";
    import Swal from "sweetalert2";

    export default {
        data() {
            return {
                facebook_img: '/images/facebook.png',
                instagram_img: '/images/instagram.jpg',
                tiktok_img: '/images/tiktok.jpg',
                latest_withdrawal_list: true,
                account_ins_number: 0,
                account_tiktok_number: 0,
                overlay: false,
                tool: {
                    pc1: {
                        name: 'Download',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/toolPCHana',
                        status: 0,
                    },
                    termux1: {
                        name: 'Download',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/toolhana',
                        status: 0,
                    },
                    termux2: {
                        name: 'Download',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/toolhana',
                        status: 0,
                    },
                    mobile1: {
                        name: 'Download Android',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/hanaautoapp',
                        status: 0,
                    },
                    mobile2: {
                        name: 'Download iOS',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/hanaautoapp',
                        status: 0,
                    },
                }
            }
        },
        methods: {
            deleteAccount(){
                Swal.fire(SWAL_SETTING_CONFIRM_DELETE_HANA_ACCOUNT()).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Hãy nhập mật khẩu của bạn',
                            input: 'password',
                            inputAttributes: {
                                autocapitalize: 'off',
                                required: 'true'
                            },
                            validationMessage: 'Bạn cần nhập mật khẩu',
                            showCancelButton: true,
                            confirmButtonText: 'Xác nhận',
                            cancelButtonText: 'Bỏ qua',
                            showLoaderOnConfirm: true,
                            preConfirm: (password) => {
                                let params = {
                                    'password' : password,
                                }
                                return this.$store.dispatch(DELETE_PERMANENTLY_ACCOUNT, params);
                            },
                            allowOutsideClick: () => !Swal.isLoading()
                        }).then((result) => {
                            if (result.value === true) {
                                this.$router.push("/login");
                            }
                        })
                    }
                })
            },
        }
    };
</script>
<style scoped lang="scss">
    .tool {
        .pc,
        .termux,
        .mobile {
            border-radius: 15px;
            position: relative;
            /*background: linear-gradient(#0084F8, dimgrey);*/
            /*background: linear-gradient(white, #0084F8 10%);*/
            background: linear-gradient(#ff0000ad 90%, white);
        }
        
        .tool-icon {
            right: 30px;
            top: 80px;
            font-size: 60px;
            position: absolute;
            color: #fff !important;
            border-radius: 100%;
            box-shadow: 0px 0px 2px #888;
            padding: 0.2em 0.2em;
        }

        @media (max-width: 767px) {
            .tool-icon {
                position: sticky;
            }
        }
        a.link_download {
            margin: 0px !important;
            padding: 0 10px;
            margin-right: 10px !important;
            margin-top: 10px !important;
            font-size: 12px;
        }
        a.link_support {
            color: #fff;
            text-decoration: unset;
        }
    }

</style>
