import {
    TOOL_DOWNLOAD_GET_LIST
} from "../../store/actions.type";
import {
    COMMON_SET_SNACK
} from "../../store/mutations.type";
import {ToolDownloadService} from "~/common/service.api";

const state = {
    accounts : {}
};

// getters
const getters = {
};

// actions
const actions = {
    [TOOL_DOWNLOAD_GET_LIST]({commit}) {
        return new Promise((resolve) => {
            ToolDownloadService.get()
                .then(res => {
                    if(res.data.success) {
                        resolve(res.data.data);
                    }else{
                        commit(COMMON_SET_SNACK, {
                            message: res.data.message,
                            type: "error"
                        });
                        resolve(false);
                    }
                })
                .catch(err => {
                    commit(COMMON_SET_SNACK, {
                        message: err.response.data.message,
                        type: "error"
                    });
                    // throw new Error(err);
                });
        });
    },

};

// mutations
const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
};
