<template>
  <div class="account-manager-facebook-add">
    <v-container fluid grid-list-lg>
      <v-row class="ma-md-2">
        <v-col>
          <v-card
            class="text-center primary--text pa-3"
            href="https://www.youtube.com/watch?v=Oev_7zt8Gj8"
            target="_blank"
          >
            <v-icon color="primary">mdi-file-video</v-icon>
            Click để xem Video hướng dẫn
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-md-5 my-2">
        <div class="ml-3">Xác nhận tài khoản</div>
      </v-row>
      <v-row class="mx-md-5 my-2">
        <v-col cols="12" class="red--text">Bước 1: Copy mã xác nhận</v-col>
        <v-col cols="8">
          <v-text-field
            label="Outlined"
            single-line
            :value="fb_code"
            outlined
            readonly
            id="verify-code"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            width="120px"
            class="mt-1"
            large
            color="primary"
            @click="copyVerifyCode"
            >Copy</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mx-md-5 my-2">
        <v-col cols="12" class="red--text"
          >Bước 2: Nhập @username của bạn. Các bạn vào app tiktok sau đó vào
          phần profile cá nhân của mình, dưới avatar có hiển thị username, đấy
          là username của bạn nhé.
        </v-col>
        <v-col cols="8">
          <v-text-field
            label="Outlined"
            single-line
            :value="username"
            v-model="username"
            outlined
            placeholder="VD: @tiktok"
            id="verify-code"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            width="120px"
            class="mt-1"
            large
            color="primary"
            @click="deleteUserName"
            >Xóa</v-btn
          >
        </v-col>
        <v-col cols="12" class="red--text"
          >Bước 3: Cập nhật tiểu sử với mã xác nhận đã copy ở bước 1 để thêm tài
          khoản.
        </v-col>
        <v-col cols="12" class="text-warning"
          >- Lưu ý: Các bạn chỉ có thể cập nhật tiểu sử bằng app Tiktok trên
          điện thoại.
        </v-col>
        <v-col cols="12">
          <v-card
            flat
            class="text-center facebook white--text pa-3"
            @click="gotoTiktok"
            :disabled="!username"
          >
            <v-icon color="white" large>mdi-music-circle</v-icon>
            Cập nhật tiểu sử ngay
          </v-card>
        </v-col>
        <v-col cols="12" class="red--text"
          >Bước 4 (Tùy chọn): Nhập cookie của bạn. Các bạn xem video để biết
          thêm chi tiết nhé ^_^
        </v-col>
          <v-col cols="12" class="text-warning"
          >- Lưu ý: Cookie của bạn sẽ được sử dụng để check job follow!!.
        </v-col>
        <v-col cols="8">
          <v-text-field
            label="Outlined"
            single-line
            :value="cookie"
            v-model="cookie"
            outlined
            placeholder="Cookie của bạn"
            id="cookie"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            width="120px"
            class="mt-1"
            large
            color="primary"
            @click="deleteCookie"
            >Xóa</v-btn
          >
        </v-col>
      </v-row>

      <v-row class="mx-md-5 my-2" @click="addAccount">
        <v-col cols="12" class="red--text"
          >Bước 5: Xác nhận thêm tài khoản
        </v-col>
        <v-col cols="12">
          <v-card flat class="text-center confirm white--text pa-3">
            <v-icon color="white" large>mdi-check</v-icon>
            Xác nhận thêm tài khoản
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="info"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { ADD_TIKTOK_ACCOUNT, COMMON_SET_SNACK } from "~/store/actions.type";

export default {
  data() {
    return {
      postUrl: "",
      postId: 123456784536543,
      fb_code: "",
      overlay: false,
      username: "",
      cookie: "",
    };
  },
  created() {
    this.initialize();
  },
  computed: {},
  methods: {
    initialize() {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.fb_code = currentUser.fb_code;
      this.username = "";
    },
    copyVerifyCode() {
      let testingCodeToCopy = document.querySelector("#verify-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        document.execCommand("copy");
        this.$store.dispatch(COMMON_SET_SNACK, {
          message: "Đã copy thành công !",
          type: "success",
        });
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    },
    deleteUserName() {
      this.username = "";
    },
    deleteCookie() {
      this.cookie = "";
    },
    gotoTiktok() {
      let url = "https://www.tiktok.com/@" + this.username.replace("@", "");
      window.open(url, "_target");
    },
    getPortId(evt) {
      // let clipboardData, pastedData, postId;
      // let clipboardData,  postId;
      evt.stopPropagation();
      evt.preventDefault();
      // clipboardData = evt.clipboardData || window.clipboardData;
      // pastedData = clipboardData.getData('Text');

      /* eslint-disable */
      // let rex = /(?:(?:http|https):\/\/(?:www|m|mbasic|business)?\.?(?:facebook|fb)\.com\/)(?:photo(?:\.php|s)|permalink\.php|video\.php|story\.php|media|watch\/|questions|notes|[^\/]+\/(?:activity|posts|videos|photos))[\/?](?:fbid=|story_fbid=|id=|b=|v=|)([0-9]+|[^\/]+\/[\d]+)/gm;
      // postId = rex.exec(pastedData);
      let postId = ["asd", "123456784536543"];
      if (
        postId === null ||
        typeof postId[1] === "undefined" ||
        postId[1] === null
      ) {
        this.$store.dispatch("common_setSnack", {
          message: "Link bài viết không hợp lệ !",
          type: "error",
        });
      } else {
        this.postId = this.postUrl = postId[1];
        this.$store.dispatch("common_setSnack", {
          message: "Hệ thống tự động lấy ID bài viết cho bạn !",
          type: "success",
        });
      }
      // postId = pastedData.search(/^https:\/\/www\.facebook\.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^\/]+\/(activity|posts))[\/?].*$/gm);
      // if(postId.includes('?')){
      //     postId = postId.split('?')[0]
      // }
    },
    addAccount() {
      this.overlay = true;
      this.$store
        .dispatch(ADD_TIKTOK_ACCOUNT, {
          code: this.fb_code,
          post_id: this.postId,
          username: this.username,
          cookie: this.cookie,
        })
        .then((data) => {
          this.overlay = false;
          if (typeof data !== "undefined" && data.success) {
            this.$router.push("/account/manager/tiktok");
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.account-manager-facebook-add {
  .v-card {
    &.facebook {
      background: linear-gradient(51.19deg, #0084f8 9.02%, #58c2f1 90.81%);
    }

    &.confirm {
      background: linear-gradient(69.69deg, #ff8576 9.02%, #ff3b30 90.81%);
    }
  }
}

.text-warning {
  color: #ff0000;
}
</style>

