import VueRouter from "vue-router";
import store from "./store";
// Pages
import NotFound from "./views/pages/404";
import Login from "./views/Login";
import Register from "./views/Register";

import Home from "./views/pages/Home";
import Delete from "./views/pages/Delete";

import Jobs from "./views/pages/Jobs";
import JobsFacebook from "./views/pages/facebook/JobsFacebook";
import JobsFacebookDetail from "./views/pages/facebook/JobsFacebookDetail";

import FAQs from "./views/pages/FAQs";

import Account from "./views/pages/Account";
import AccountSetting from "./views/pages/AccountSetting";
// import AccountSettingSercurity from "./views/pages/AccountSettingSercurity";
import AccountManager from "./views/pages/AccountManager";
import AccountManagerFacebook from "./views/pages/facebook/AccountManagerFacebook";
import AccountManagerFacebookAdd from "./views/pages/facebook/AccountManagerFacebookAdd";
import AccountManagerFacebookMultiAdd from "./views/pages/facebook/AccountManagerFacebookMultiAdd";
import AccountWithdraw from "./views/pages/AccountWithdraw";
import Email from "./views/password/email";
import JobsFacebookLogDetail from "./views/pages/facebook/JobsFacebookLogDetail";
import Referral from "./views/pages/Referral";

import AccountManagerInstagram from "./views/pages/instagram/AccountManagerInstagram";
import AccountManagerInstagramAdd from "./views/pages/instagram/AccountManagerInstagramAdd";
import JobsInstagram from "./views/pages/instagram/JobsInstagram";
import JobsInstagramDetail from "./views/pages/instagram/JobsInstagramDetail";
import JobsInstagramLogDetail from "./views/pages/instagram/JobsInstagramLogDetail";

import AccountManagerTikTok from "./views/pages/tiktok/AccountManagerTikTok";
import AccountManagerTikTokAdd from "./views/pages/tiktok/AccountManagerTikTokAdd";
import AccountManagerViewTub from "./views/pages/viewtub/AccountManagerViewTub";
import AccountManagerViewTubAdd from "./views/pages/viewtub/AccountManagerViewTubAdd";
import JobsTikTok from "./views/pages/tiktok/JobsTikTok";
import JobsTikTokDetail from "./views/pages/tiktok/JobsTikTokDetail";
import JobsTikTokLogDetail from "./views/pages/tiktok/JobsTikTokLogDetail";

import LuckyWheel from "./views/pages/LuckyWheel";
import LuckyWheelHistoryReceiveAward from "./views/pages/LuckyWheelHistoryReceiveAward";

import ViewTubMoney from "./components/ViewTubMoney";

import RaceTop from "./views/pages/RaceTop";
import Tool from "./views/pages/Tool";

// Routes
const routes = [
    {path: "/login", name: "login", component: Login, meta: {auth: false}},
    {path: "/register", name: "register", component: Register, meta: {requiresAuth: false}},
    {path: "/password/email", name: "password.email", component: Email, meta: {auth: false}},
    // {path: "/password/reset", name: "password.reset", component: Reset, meta: {auth: false}},

    // home
    {path: "/", name: "home", component: Home, meta: {requiresAuth: true}},
    {path: "/", name: "re-home", component: Home, meta: {requiresAuth: true}},
    {path: "/delete-permanently", name: "delete", component: Delete, meta: {requiresAuth: true}},

    // jobs
    {path: "/jobs", name: "jobs", component: Jobs, meta: {requiresAuth: true}},
    {path: "/jobs/facebook", name: "jobs_facebook", component: JobsFacebook, meta: {requiresAuth: true}},
    {path: "/jobs/facebook/detail/:id", name: "jobs_facebook_detail", component: JobsFacebookDetail, meta: {requiresAuth: true}},
    {path: "/jobs/facebook/log-job-detail/:id/:own_by_id", name: "jobs_facebook_log_detail", component: JobsFacebookLogDetail, meta: {requiresAuth: true}},

    // faqs
    {path: "/faqs", name: "faqs", component: FAQs, meta: {requiresAuth: true}},

    // account
    {path: "/account", name: "account", component: Account, meta: {requiresAuth: true}},
    {path: "/account/setting", name: "account_setting", component: AccountSetting, meta: {requiresAuth: true}},
    // {path: "/account/setting-security", name: "account_setting_security", component: AccountSettingSercurity, meta: {requiresAuth: true}},
    {path: "/account/manager", name: "account_manager", component: AccountManager, meta: {requiresAuth: true}},
    {path: "/account/manager/facebook", name: "account_manager_facebook", component: AccountManagerFacebook, meta: {requiresAuth: true}},
    {path: "/account/manager/facebook/add", name: "account_manager_facebook_add", component: AccountManagerFacebookAdd, meta: {requiresAuth: true}},
    {path: "/account/manager/facebook/multi-add", name: "account_manager_facebook_multi_add", component: AccountManagerFacebookMultiAdd, meta: {requiresAuth: true}},
    {path: "/account/withdraw/:type?", name: "account_withdraw", component: AccountWithdraw, meta: {requiresAuth: true}},

    //referral
    {path: "/referral", name: "referral", component: Referral, meta: {requiresAuth: true}},

    /*--------------------------- Instagram ------------------------*/
    {path: "/account/manager/instagram", name: "account_manager_instagram", component: AccountManagerInstagram, meta: {requiresAuth: true}},
    {path: "/account/manager/instagram/add", name: "account_manager_instagram_add", component: AccountManagerInstagramAdd, meta: {requiresAuth: true}},

    {path: "/jobs/instagram", name: "jobs_instagram", component: JobsInstagram, meta: {requiresAuth: true}},
    {path: "/jobs/instagram/detail/:id", name: "jobs_instagram_detail", component: JobsInstagramDetail, meta: {requiresAuth: true}},
    {path: "/jobs/instagram/log-job-detail/:id", name: "jobs_instagram_log_detail", component: JobsInstagramLogDetail, meta: {requiresAuth: true}},

    // ==TIKTOK==
    {path: "/account/manager/tiktok", name: "account_manager_tiktok", component: AccountManagerTikTok, meta: {requiresAuth: true}},
    {path: "/account/manager/tiktok/add", name: "account_manager_tiktok_add", component: AccountManagerTikTokAdd, meta: {requiresAuth: true}},
    {path: "/jobs/tiktok", name: "jobs_tiktok", component: JobsTikTok, meta: {requiresAuth: true}},
    {path: "/jobs/tiktok/detail/:id", name: "jobs_tiktok_detail", component: JobsTikTokDetail, meta: {requiresAuth: true}},
    {path: "/jobs/tiktok/log-job-detail/:id", name: "jobs_tiktok_log_detail", component: JobsTikTokLogDetail, meta: {requiresAuth: true}},
    {path: "/404", name: "not_found", component: NotFound, meta: {requiresAuth: false}},

    //ViewTub
    {path: "/account/manager/viewtub", name: "account_manager_viewtub", component: AccountManagerViewTub, meta: {requiresAuth: true}},
    {path: "/account/manager/viewtub/add", name: "account_manager_viewtub_add", component: AccountManagerViewTubAdd, meta: {requiresAuth: true}},
    {
        path: "*",
        redirect: "/404",
    },

    //Lucky Wheel
    {path: "/lucky-wheel/:type?", name: "lucky_wheel", component: LuckyWheel, meta: {requiresAuth: true}},
    {path: "/lucky-wheel/history-receive-award/:id", name: "lucky_wheel_history_receive_award", component: LuckyWheelHistoryReceiveAward, meta: {requiresAuth: true}},

    {path: "/money-viewtub-process", name: "money_viewtub", component: ViewTubMoney, meta: {requiresAuth: true}},

    //Race Top
    {path: "/race-top", name: "race_top", component: RaceTop, meta: {requiresAuth: true}},

    //Tool Download
    {path: "/tool", name: "tool", component: Tool, meta: {requiresAuth: true}},
];

let router = new VueRouter({
    history: true,
    mode: "history",
    routes
});

//Handling unauthorized access cases
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.auth_isLoggedIn) {
            next();
            return;
        }
        next("/login");
        return;
    } else if ((to.name === "login" || to.name === "register") && store.getters.auth_isLoggedIn) {
        next("/");
        return;
    }
    next();
});
export default router;
