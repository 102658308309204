<template>
    <div class="referral">
        <v-container fluid grid-list-lg>
            <h3 class="primary--text pl-5">Mời bạn bè</h3>
            <v-row class="px-5 share-link">
                <v-col>
                    <v-card flat color="white">
                        <v-card-text cols="12">
                            <span>Mời bạn bè cùng kiếm tiền để nhận ngay {{ percent_presenter }}% doanh thu của họ mỗi tháng.</span><br>
                            <span>Chia sẻ link giới thiệu ngay:</span>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="pb-0">
                                <v-col cols="8" class="pb-0">
                                    <v-text-field
                                            label="Outlined"
                                            single-line
                                            :value="userInfo.link_share"
                                            outlined
                                            readonly
                                            id="ref-code"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-btn max-width="120px" class="mt-1" large color="primary" @click="copyRefCode">Chép</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        <v-card-text cols="12" class="py-0">
                            <span>Mã giới thiệu:</span>
                            <span class="caption"><i>(Mã này dùng để bạn bè nhập khi đăng kí tài khoản mới)</i></span>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="pb-0">
                                <v-col cols="8" class="pb-0 py-0">
                                    <v-text-field
                                            label="Outlined"
                                            single-line
                                            :value="userInfo.link_share | get_share_code"
                                            outlined
                                            readonly
                                            id="share-code"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3" class="pb-0 py-0">
                                    <v-btn max-width="120px" class="mt-1" large color="primary" @click="copyShareCode">Chép</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        <social-sharing :url="userInfo.link_share" inline-template>
                            <div class="social-sharing">
                            <network network="facebook">
                                <img width="50px" src="/images/facebook.png" alt="" />
                            </network>
                            <network network="twitter">
                                <img width="50px" src="/images/twitter.jpg" alt="" />
                            </network>
                            </div>
                        </social-sharing>
                    </v-card>
                </v-col>
            </v-row>
            <h3 class="primary--text pl-5">Tổng các khách mời</h3>
            <v-row class="px-5">
                <v-col>
                    <v-card class="number-invited" flat>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1 white--text mb-1">
                                    <span>Số khách đã mời</span><br>
                                    <span class="font-weight-bold">{{ userInfo.count_precenter}}</span>
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="white">mdi-account-multiple</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="reward" flat>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1 white--text mb-1">
                                    <span>Số tiền nhận được</span><br>
                                    <span class="font-weight-bold">{{ userInfo.coin_recieve }} đ</span>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon color="white">mdi-cash-usd</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <h3 class="primary--text pl-5 mt-3">Danh sách người đã mời</h3>
            <v-row class="px-5">
                <v-col>
                    <v-card class="mx-auto" v-if="listPresenter.length > 0">
                        <v-list two-line subheader>
                            <v-list-item
                                    v-for="presenter in listPresenter"
                                    :key="presenter.id"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{ presenter.user.username }}</v-list-item-title>
                                    <v-list-item-subtitle>Nhận ref <span class="font-weight-bold primary--text">{{ percent_presenter }}%</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <span>Thu nhập</span><br/>
                                    <span class="font-weight-bold primary--text">{{ presenter.coin }} đ</span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <h3 v-if="listPresenter.length <= 0" class="text-center body-1">Hiện chưa có bạn mời</h3>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {
        COMMON_SET_SNACK,
        AUTH_USER,
        REFERRAL_LIST_PRESENTER,
        GET_PERCENT_PRESENTER
    } from "~/store/actions.type";

export default {

    data() {
        return {
            userInfo : {},
            items: [
                { icon: 'folder', iconClass: 'grey lighten-1 white--text', title: 'Nguyễn văn A', subtitle: 'nhận khuyễn mãi ' },
                { icon: 'folder', iconClass: 'grey lighten-1 white--text', title: 'Recipes', subtitle: 'Jan 17, 2014' },
            ],
            listPresenter: {},
            share_code: ''
        }
    },
    created() {
        this.initialize();
    },
    filters : {
      get_share_code(link) {
          let res = link.split("=");
          return res[1];
      }
    },
    computed: {
        percent_presenter() {
            return this.$store.getters.percentPresenter;
        }
    },
    methods: {
        async initialize(){
           await this.$store.dispatch(AUTH_USER).then(({data}) => {
                this.userInfo = data.data;
           }).catch(() => {
               this.$store.dispatch(COMMON_SET_SNACK, {
                   message: 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
                   type: "error"
               });
           });
            await this.$store.dispatch(GET_PERCENT_PRESENTER);
           await this.$store.dispatch(REFERRAL_LIST_PRESENTER).then(({data}) => {
              this.listPresenter = data;
           }).catch(() => {
               this.$store.dispatch(COMMON_SET_SNACK, {
                   message: 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
                   type: "error"
               });
           });
        },

        copyRefCode () {
            let testingCodeToCopy = document.querySelector('#ref-code');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            try {
                document.execCommand('copy');
                this.$store.dispatch(COMMON_SET_SNACK, {
                    message: 'Đã copy link mời bạn bè thành công !',
                    type: "success"
                });
            } catch (err) {
                console.log('Oops, unable to copy');
            }
        },

        copyShareCode () {
            let testingCodeToCopy = document.querySelector('#share-code');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            try {
                document.execCommand('copy');
                this.$store.dispatch(COMMON_SET_SNACK, {
                    message: 'Đã copy mã giới thiệu thành công !',
                    type: "success"
                });
            } catch (err) {
                console.log('Oops, unable to copy');
            }
        },
    }
};
</script>
<style scoped lang="scss">
    .referral {
        .number-invited {
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        }
        .reward {
            background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
        }
        .v-icon{
            font-size: 55px;
        }
        .v-list-item__action {
            line-height: 10px !important;
            margin-top: 20px;
        }
        .share-link {
            position: relative;
            .social-sharing {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }
    }

</style>
