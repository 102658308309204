<template>
    <div class="account-manager">
        <v-container fluid grid-list-lg>
            <router-link :to="{ name : 'account_manager_instagram' }">
                <v-row class="instagram ma-2 pa-5">
                    <div class="pa-0">
                        <v-avatar class="mt-1">
                            <img width="20px" :src="instagram_img" alt="instagram">
                        </v-avatar>
                    </div>
                    <div class="ml-3 white--text">
                        <span class="headline font-weight-bold">Instagram</span><br />
                        <span class="body-2">{{ account_ins_number }} tài khoản làm việc</span>
                    </div>
                    <v-icon color="white" class="float-right" large>mdi-arrow-right</v-icon>
                </v-row>
            </router-link>
            <router-link :to="{ name : 'account_manager_facebook' }">
                <v-row class="facebook ma-2 pa-5">
                    <div class="pa-0">
                        <v-avatar class="mt-1">
                            <img width="20px" :src="facebook_img" alt="facebook">
                        </v-avatar>
                    </div>
                    <div class="ml-3 white--text">
                        <span class="headline font-weight-bold">Facebook</span><br />
                        <span class="body-2">{{ account_fb_number }} tài khoản làm việc</span>
                    </div>
                    <v-icon color="white" class="float-right" large>mdi-arrow-right</v-icon>
                </v-row>
            </router-link>
            <router-link :to="{ name : 'account_manager_tiktok' }">
                <v-row class="tiktok ma-2 pa-5">
                    <div class="pa-0">
                        <v-avatar class="mt-1">
                            <img width="20px" :src="tiktok_img" alt="instagram">
                        </v-avatar>
                    </div>
                    <div class="ml-3 white--text">
                        <span class="headline font-weight-bold">TikTok</span><br />
                        <span class="body-2">{{ account_tiktok_number }} tài khoản làm việc</span>
                    </div>
                    <v-icon color="white" class="float-right" large>mdi-arrow-right</v-icon>
                </v-row>
            </router-link>
            <router-link :to="{ name : 'account_manager_viewtub' }">
                <v-row class="viewtub ma-2 pa-5">
                    <div class="pa-0">
                        <v-avatar class="mt-1">
                            <img width="20px" :src="view_tub_img" alt="viewtub">
                        </v-avatar>
                    </div>
                    <div class="ml-3 white--text">
                        <span class="headline font-weight-bold">ViewTub</span><br />
                        <span class="body-2">{{ account_viewtub_number }} tài khoản liên kết</span>
                    </div>
                    <v-icon color="white" class="float-right" large>mdi-arrow-right</v-icon>
                </v-row>
            </router-link>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {FACEBOOK_ACCOUNT_FETCH_ALL, INSTAGRAM_ACCOUNT_FETCH_ALL, TIKTOK_ACCOUNT_FETCH_ALL} from "~/store/actions.type";
    import {VIEWTUB_ACCOUNT_FETCH_ALL} from "../../store/actions.type";

    export default {
        data() {
            return {
                facebook_img : '/images/facebook.png',
                instagram_img : '/images/instagram.jpg',
                tiktok_img: '/images/tiktok.jpg',
                view_tub_img: '/images/viewtub.png',
                account_fb_number : 0,
                account_ins_number : 0,
              account_tiktok_number: 0,
              account_viewtub_number: 0,
                overlay: false
            }
        },
        created() {
            this.initialize();
        },
        methods : {
            async initialize(){
                this.overlay = true;
                let facebookAccount = await this.$store.dispatch(FACEBOOK_ACCOUNT_FETCH_ALL);
                this.account_fb_number = facebookAccount.data.length;
                let instagramAccount = await this.$store.dispatch(INSTAGRAM_ACCOUNT_FETCH_ALL);
                this.account_ins_number = instagramAccount.data.length;
                let tiktokAccount = await this.$store.dispatch(TIKTOK_ACCOUNT_FETCH_ALL);
                this.account_tiktok_number = tiktokAccount.data.length;
                let viewTubAccount = await this.$store.dispatch(VIEWTUB_ACCOUNT_FETCH_ALL);
                this.account_viewtub_number = viewTubAccount.data.length;
                this.overlay = false;
            }
        }
    };
</script>
<style scoped lang="scss">
    .v-application{
        a {
            color: transparent !important;
        }
    }
    .account-manager {
        .facebook{
            background: linear-gradient(51.19deg, #0084F8 9.02%, #58C2F1 90.81%);
        }
        .instagram {
            background: linear-gradient(77.89deg, #FD3F36 9.02%, #FDE259 90.81%)
        }
        .tiktok {
            background: linear-gradient(77.89deg, #000000 9.02%, #33001a 90.81%);
        }
        .viewtub {
            background: #2EAB38;
        }
        .facebook, .instagram, .tiktok, .viewtub {
            border-radius: 15px;
            position: relative;
            .v-icon {
                position: absolute;
                right: 30px;
                top: 30px;
            }
        }

    }

</style>
