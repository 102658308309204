<template>
    <v-card flat class="pa-3 work-list">
     <!-- <v-card-actions v-if="captchaVisible" class="px-8 col-11 offset-1 md-12 align-center recaptcha">
       <vue-recaptcha
           class="g-recaptcha"
           ref="recaptcha"
           @verify="onVerify"
           refs="recaptcha"
           :sitekey="g_recaptcha_key">
       </vue-recaptcha>
     </v-card-actions> -->
        <v-row @click="listAccountShow = !listAccountShow" v-if="accounts.length !== 0">
            <v-col>
                <v-card>
                    <v-card-text class="font-weight-bold">
                        <v-row>
                            <v-col class="py-0">
                                <span>Chọn tài khoản</span><br/>
                                <span class="primary--text">Kiếm tiền</span>
                                <v-icon color="primary" class="ml-1">mdi-arrow-right-bold-circle-outline</v-icon>
                            </v-col>
                            <v-col class="py-0 text-right">
                                <v-avatar>
                                    <img :src="profilePic" alt="">
                                </v-avatar>
                                {{ accountName }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row><!---->
        <v-row v-if="accounts.length === 0">
            <v-col cols="12">
                <v-card class="version pa-2 mx-auto text-center" color="primary"
                        :to="{ name : 'account_manager_instagram_add' }">
                    <v-icon class="pl-3 mt-1 float-left" color="white" medium>mdi-account-plus
                    </v-icon>
                    <span class="title font-weight-bold ml-3 white--text">Thêm tài khoản</span>
                </v-card>
            </v-col>
            <v-col cols12 class="text-center">
                <h4>Bạn cần thêm tài khoản facebook trước khi thực hiện công việc. </h4>
            </v-col>
        </v-row>
        <v-row v-if="listAccountShow" class="list-account">
            <v-col>
                <v-card class="mx-auto" tile flat>
                    <v-list>
                        <v-list-item-group color="primary">
                            <v-list-item
                                    v-for="(account, i) in accounts"
                                    :key="i"
                                    @click="accountSelected(account)"
                            >
                                <v-list-item-icon>
                                    <v-avatar class="seeding">
                                        <img :src="account.profile_pic_url" alt="">
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                       {{ account.username }}<br>
                                        <span class="caption">Việc đã làm trong ngày</span>
                                        <v-btn class="mx-2" fab dark x-small color="primary">
                                            {{ account.job_count_today }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon v-if="account.id == accountSelectedId">
                                    <v-icon>mdi-check</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <div v-if="!listAccountShow && accounts.length !== 0">
            <v-row>
                <v-col>
                    <v-combobox
                            v-model="listSeedingInstagram"
                            :items="seeding"
                            chips
                            multiple
                            prepend-icon="filter_list"
                            solo
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                    class="share white--text"
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    @click="select"
                                    @click:close="remove(item)"
                            >
                                <strong>{{ item }}</strong>
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
            </v-row>

            <v-row v-if="accounts.length !== 0">
                <v-col>
                    <span class="primary--text title">Danh sách công việc</span>
                    <v-icon @click="listWorkShow = !listWorkShow" color="primary" class="float-right" medium>{{
                        listWorkShow ? 'mdi-arrow-down-bold-circle-outline' : 'mdi-arrow-right-bold-circle-outline' }}
                    </v-icon>
                    <v-icon @click="initialize()" color="primary" class="float-right" medium>
                        mdi-reload
                    </v-icon>
                </v-col>
            </v-row>

            <v-row v-if="listWorkShow && accounts.length !== 0">
                <v-col cols="12" v-for="(job, key) in jobs10" :key="job.id">
                    <router-link @click.native="scrollToTop" v-if="key < 10" :to="{ name : 'jobs_instagram_detail', params : { id : job.id } }">
                        <v-row class="job">
                            <v-col cols="3" md="1" class="text-center pa">
                                <div style="border-right: 1px solid #777777">
                                    <v-avatar size="36">
                                        <img :src="job.icon" alt="">
                                    </v-avatar>
                                    <h6 class="body-1 font-weight-bold mt-1">
                                        <span><span class="hold-prices">{{ job.coin }}</span></span>
                                        <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon white--text"/>
<!--                                        <img style="max-width: 18px" :src="coinSvg" alt="">-->
                                    </h6>
                                </div>
                            </v-col>
                            <v-col cols="9" md="11" class="caption job-info px-0" >
                                <div class="hidden-md-and-up">
                                    <span class="nowrap font-weight-bold caption">{{ job.seeding_title }}</span><br/>
                                    <span class="black--text">Còn lại <span class="font-weight-bold">{{ job.num_seeding_remain }}</span><span class="float-right">{{ job.time_remain }}<v-icon
                                            small>mdi-arrow-right</v-icon></span></span><br/>
                                    <span class="black--text">{{ job.content | jobContentFormat}}</span>
                                </div>
                                <div class="hidden-sm-and-down">
                                    <span class="nowrap font-weight-bold title">{{ job.seeding_title }}</span><br/>
                                    <span class="black--text">Còn lại <span class="font-weight-bold">{{ job.num_seeding_remain }}</span><span class="float-right">{{ job.time_remain }}<v-icon
                                            small>mdi-arrow-right</v-icon></span></span><br/>
                                    <span class="black--text">{{ job.content }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
            </v-row>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
    import {
      LIST_SEEDING,
    } from "~/store/actions.type";
    // import VueRecaptcha from 'vue-recaptcha';
    import {JOB_TYPE_INSTAGRAM, GOOGLE_RECAPTCHA_KEY} from "~/config/constants";
    // import {JOB_TYPE_INSTAGRAM} from "~/config/constants";
    import {
      INSTAGRAM_ACCOUNT_FETCH_ALL,
      // INSTAGRAM_JOB_FETCH_ALL,
      INSTAGRAM_JOB_FETCH_ALL_V2
    } from "~/store/actions.type";
    import {COMMON_SET_SNACK} from "~/store/actions.type";
    import moment from "moment";
    import {decodeData} from "~/common/service.encode";

    global.jQuery = require('jquery');
    let $ = global.jQuery;
    window.$ = $;
    export default {
    //   components: { VueRecaptcha },
        data() {
            return {
                lowPayJobs: false,
                seeding: ['Like instagram', 'Tăng follow instagram', 'Bình luận instagram'],
                coinSvg: '/images/coin.svg',
                likePageGif: '/images/like_page.gif',
                listAccountShow: false,
                listWorkShow: true,
                params : {
                    'seeding_type' : 'all', 
                    'instagram_account_id' : localStorage.getItem('instagram_account'), 
                    'per_page' : 100, 
                    'page' : 1
                    , 'recaptcha': ''
                },
                account: null,
                jobs : {},
                accounts : {},
                listSeedingInstagram : [],
                listSeedingOriginal : [],
                profilePic : '',
                accountName : '',
                accountSelectedId : localStorage.getItem('instagram_account'),
                jobs10 : {},
                overlay: false,
                g_recaptcha_key : GOOGLE_RECAPTCHA_KEY,
                captchaVisible: false,
            }
        },
        filters: {
            jobContentFormat(content){
                let cutoff = 30;
                if(typeof content !== "undefined" && content.length > 30){
                    return content.substring(0, cutoff)+'...';
                }
                return content;
            }
        },
        computed: {

        },
        watch: {
            listSeedingInstagram: {
                handler(val){
                    this.$store.dispatch('setListSeedingInstagram', this.listSeedingInstagram);
                    let keys = [];
                    console.log('asdasd', val, this.listSeedingOriginal);
                    for(let k in val){
                        for (let l in this.listSeedingOriginal){
                            if(val[k] === this.listSeedingOriginal[l].title){
                                keys.push(this.listSeedingOriginal[l].id);
                            }
                            // if(val[k] === this.capitalize(this.listSeedingOriginal[l].key.split('_').join(' '))){
                            //     keys.push(this.listSeedingOriginal[l].id);
                            // }
                        }
                    }
                    this.params = { 'seeding_type' : keys.join(','), 'instagram_account_id' : this.accountSelectedId, 'per_page' : 100, 'page' : 1};
                },
                deep: true
            }
        },
        created() {
            this.instagramJobListSeedingFetchAll();
            this.instagramAccountFetchAll();
        },
        methods: {
            async isCacheJobsBlank(cacheJobKey) {
              let cachedObj = await localStorage.getItem(cacheJobKey);
              cachedObj = await JSON.parse(cachedObj)
              if(!cachedObj || (cachedObj && cachedObj.jobs && cachedObj.jobs.length == 0) || (cachedObj && !cachedObj.jobs)) {
                return null;
              }else if (cachedObj && cachedObj.timeAt && moment().diff(moment(cachedObj.timeAt), 'minutes') >= 4){
                return null
              } else {
                return cachedObj;
              }
            },
            async initialize() {
              this.jobs10 = [];
              if(this.params.instagram_account_id){
                const cached_account_id = await localStorage.getItem('instagram_account');
                const instagram_account_id = cached_account_id ? cached_account_id : this.params.instagram_account_id;
                this.params.instagram_account_id = instagram_account_id;
                const cacheJobKey = instagram_account_id.toString() + '_cached_jobs';
                this.overlay = true;
                const cachedData = await this.isCacheJobsBlank(cacheJobKey);
                if (!cachedData) {
                //   if (this.captchaVisible && this.recaptcha != '') {
                    this.jobs = await this.$store.dispatch(INSTAGRAM_JOB_FETCH_ALL_V2, this.params);
                      this.jobs = decodeData(this.jobs);
                      
                    if (this.jobs) {
                      const cacheObj = {
                        timeAt: moment(),
                        jobs: this.jobs
                      }
                      await localStorage.setItem(cacheJobKey, JSON.stringify(cacheObj))
                    }
                //   } else {
                //     this.captchaVisible = true;
                //     this.overlay = false;
                //     this.$store.dispatch(COMMON_SET_SNACK, {
                //       message: 'Bạn cần thực hiện captcha để lấy jobs nhé!',
                //       type: "error"
                //     });
                //   }
                } else {
                  this.jobs = cachedData.jobs;
                }
                if (this.jobs) {
                  this.jobs10 = this.jobs.slice(0, 20).map(i => {
                    return i;
                  });
                  this.captchaVisible = false;
                }
                this.overlay = false;
              }
             },
            async instagramJobListSeedingFetchAll(){
                let listSeedingInstagram = await this.$store.dispatch(LIST_SEEDING, {'job_type' : JOB_TYPE_INSTAGRAM});
                this.listSeedingOriginal = listSeedingInstagram;
                console.log('aaa', this.$store.getters.listSeedingInstagram);
                if((this.$store.getters.listSeedingInstagram).length > 0){
                    this.listSeedingInstagram = this.$store.getters.listSeedingInstagram;
                }else{
                    for (let i in listSeedingInstagram){
                        let key = (listSeedingInstagram[i].key).split('_').join(' ');
                        key = this.capitalize(key);
                        this.listSeedingInstagram.push(listSeedingInstagram[i].title);
                    }
                    this.$store.dispatch('setListSeedingInstagram', this.listSeedingInstagram);
                }
            },
            async instagramAccountFetchAll() {
                 await this.$store.dispatch(INSTAGRAM_ACCOUNT_FETCH_ALL)
                    .then(({data}) => {
                        this.accounts = data;
                        if(data.length === 0) return;
                        let main_account = {};
                        if(!localStorage.getItem('instagram_account')) {
                          main_account = data.filter(o => o.is_main == 1)[0];
                        }else{
                          main_account = data.filter(o => o.id == localStorage.getItem('instagram_account'))[0];
                        }
                        if (typeof main_account !== "undefined") {
                            this.profilePic = main_account.profile_pic_url;
                            this.accountName = main_account.username;
                            this.accountSelectedId = main_account.id;
                            this.params.instagram_account_id = main_account.id;
                            localStorage.setItem('instagram_account', main_account.id);
                        }
                        this.initialize();
                    });
            },
            accountSelected(account) {
                this.profilePic = account.profile_pic_url;
                this.accountName = account.username;
                this.accountSelectedId = account.id;
                localStorage.setItem('instagram_account', account.id);
                this.listAccountShow = false;
                this.params.instagram_account_id = account.id;
                this.initialize();
            },
            getRandomJobs(){
                let jobs = this.jobs;
                const shuffled = jobs.sort(() => 0.5 - Math.random());
                this.jobs10 = shuffled.slice(0, 10);
                this.$store.dispatch(COMMON_SET_SNACK, {
                    message: 'Lấy jobs thành công !',
                    type: "success"
                });
            },
            capitalize(a){
                return a.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
            },
            remove(item) {
                this.listSeedingInstagram.splice(this.listSeedingInstagram.indexOf(item), 1);
                this.listSeedingInstagram = [...this.listSeedingInstagram]
            },
            scrollToTop() {
                window.scrollTo(0,0);
            },
            onVerify(response) {
              this.recaptcha = response;
              this.params.recaptcha = response;
              this.initialize();
            },
            resetRecaptcha: function() {
              this.grecaptcha.reset();
            },
        }
    };
</script>
<style scoped lang="scss">
    .work-list {
        .job {
            .v-avatar{
               height: 40px !important;
            }
            background-color: #ffffff;
            cursor: pointer;
            .job-info {
                position: relative;
                div {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .goto-detail {
                    text-decoration: none;
                    color: unset;
                }
            }

        }

        .v-chip {
            &.share {
                background: linear-gradient(69.69deg, #00F8BC 9.02%, #58C2F1 90.81%);
            }
        }

        a {
            text-decoration: none;
        }
    }
    .recaptcha {
      position: relative;
      padding-top: 70px;
      padding-bottom: 55px;
      .g-recaptcha {
        position: absolute;
        left: 50%;
        margin-left: -152px;
      }
    }

</style>
