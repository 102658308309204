<template>
    <div class="tool">
        <v-container fluid grid-list-lg>

            <!--pc-->
            <v-row v-if="tool.pc1.status == 1" 
                class="pc ma-2 pa-5">
                <div class="ml-3 white--text">
                    <span class="headline font-weight-bold">Tool Auto PC (miễn phí)</span><br/>
                    <span class="body-2">{{ tool.pc1.description }}</span>
                </div>
                <div class="row col-12">
                    <div class="col-8">
                        <v-btn
                            :href="tool.pc1.link_download"
                            outlined
                            class="ma-2 white--text link_download"
                            target="_blank"
                        >
                            {{ tool.pc1.name }}
                            <v-icon color="white" right dark>mdi-download</v-icon>
                        </v-btn>
                        <br><br>
                        <a class="body-2 link_support" :href="tool.pc1.link_support" target="_blank">>> Group hỗ trợ</a>
                    </div>
                    <div class="col-4">
                        <v-icon color="primary" class="tool-icon">mdi-desktop-mac</v-icon>
                    </div>
                </div>
            </v-row>

            <!--termux-->
            <v-row v-if="tool.termux1.status == 1"
                class="termux ma-2 pa-5">
                <div class="ml-3 white--text">
                    <span class="headline font-weight-bold">Tool Auto Termux (miễn phí)</span><br/>
                    <span class="body-2">{{ tool.termux1.description }}</span>
                </div>
                <div class="row col-12">
                    <div class="col-8">
                        <v-btn
                            :href="tool.termux1.link_download"
                            outlined
                            class="ma-2 white--text link_download"
                            target="_blank"
                        >
                            {{ tool.termux1.name }}
                            <v-icon color="white" right dark>mdi-download</v-icon>
                        </v-btn>
                        <v-btn
                            :href="tool.termux2.link_download"
                            outlined
                            class="ma-2 white--text link_download"
                            target="_blank"
                        >
                            {{ tool.termux2.name }}
                            <v-icon color="white" right dark>mdi-download</v-icon>
                        </v-btn>
                        <br><br>
                        <a class="body-2 link_support" :href="tool.termux1.link_support" target="_blank">>> Group hỗ trợ</a>
                    </div>
                    <div class="col-4">
                        <v-icon color="primary" class="tool-icon">mdi-tablet-cellphone</v-icon>
                    </div>
                </div>
            </v-row>

            <!--mobile-->
            <v-row v-if="tool.mobile1.status == 1"
               class="mobile ma-2 pa-5">
                <div class="ml-3 white--text">
                    <span class="headline font-weight-bold">Tool Auto App Mobile (miễn phí tool instagram)</span><br/>
                    <span class="body-2">{{ tool.mobile1.description }}</span>
                </div>
                <div class="row col-12">
                    <div class="col-8">
                        <v-btn
                            :href="tool.mobile1.link_download"
                            outlined
                            class="ma-2 white--text link_download"
                            target="_blank"
                        >
                            {{ tool.mobile1.name }}
                            <v-icon color="white" right dark>mdi-download</v-icon>
                        </v-btn>
                        <v-btn
                            :href="tool.mobile2.link_download"
                            outlined
                            class="ma-2 white--text link_download"
                            target="_blank"
                        >
                            {{ tool.mobile2.name }}
                            <v-icon color="white" right dark>mdi-download</v-icon>
                        </v-btn>
                        <br><br>
                        <a class="body-2 link_support" :href="tool.mobile1.link_support" target="_blank">>> Group hỗ trợ</a>
                    </div>
                    <div class="col-4" style="padding-left: 35px;">
                        <v-icon color="primary" class="tool-icon">mdi-cellphone-iphone</v-icon>
                    </div>
                </div>
            </v-row>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {
        TOOL_DOWNLOAD_GET_LIST
    } from "~/store/actions.type";

    export default {
        data() {
            return {
                facebook_img: '/images/facebook.png',
                instagram_img: '/images/instagram.jpg',
                tiktok_img: '/images/tiktok.jpg',
                latest_withdrawal_list: true,
                account_ins_number: 0,
                account_tiktok_number: 0,
                overlay: false,
                tool: {
                    pc1: {
                        name: 'Download',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/toolPCHana',
                        status: 0,
                    },
                    termux1: {
                        name: 'Download',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/toolhana',
                        status: 0,
                    },
                    termux2: {
                        name: 'Download',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/toolhana',
                        status: 0,
                    },
                    mobile1: {
                        name: 'Download Android',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/hanaautoapp',
                        status: 0,
                    },
                    mobile2: {
                        name: 'Download iOS',
                        description: '',
                        link_download: '',
                        link_support: 'https://t.me/hanaautoapp',
                        status: 0,
                    },
                }
            }
        },
        mounted() {
            this.configToolDownload();
        },
        methods: {
            configToolDownload() {
                this.$store.dispatch(TOOL_DOWNLOAD_GET_LIST).then((data) => {
                    
                    //pc
                    let pc = data.filter(o => o.key == 'tool_pc');
                    
                    this.tool.pc1.name = pc[0]['name'];
                    this.tool.pc1.description = pc[0]['description'];
                    this.tool.pc1.link_download = pc[0]['link_download'];
                    this.tool.pc1.link_support = pc[0]['link_support'];
                    this.tool.pc1.status = pc[0]['status'];

                    //termux
                    let termux = data.filter(o => o.key == 'tool_termux');
                    
                    this.tool.termux1.name = termux[0]['name'];
                    this.tool.termux1.description = termux[0]['description'];
                    this.tool.termux1.link_download = termux[0]['link_download'];
                    this.tool.termux1.link_support = termux[0]['link_support'];
                    this.tool.termux1.status = termux[0]['status'];

                    this.tool.termux2.name = termux[1]['name'];
                    this.tool.termux2.description = termux[1]['description'];
                    this.tool.termux2.link_download = termux[1]['link_download'];
                    this.tool.termux2.link_support = termux[1]['link_support'];
                    this.tool.termux2.status = termux[1]['status'];

                    //mobile
                    let mobile = data.filter(o => o.key == 'tool_mobile');
                    
                    this.tool.mobile1.name = mobile[0]['name'];
                    this.tool.mobile1.description = mobile[0]['description'];
                    this.tool.mobile1.link_download = mobile[0]['link_download'];
                    this.tool.mobile1.link_support = mobile[0]['link_support'];
                    this.tool.mobile1.status = mobile[0]['status'];

                    this.tool.mobile2.name = mobile[1]['name'];
                    this.tool.mobile2.description = mobile[1]['description'];
                    this.tool.mobile2.link_download = mobile[1]['link_download'];
                    this.tool.mobile2.link_support = mobile[1]['link_support'];
                    this.tool.mobile2.status = mobile[1]['status'];

                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .tool {
        .pc,
        .termux,
        .mobile {
            border-radius: 15px;
            position: relative;
            /*background: linear-gradient(#0084F8, dimgrey);*/
            /*background: linear-gradient(white, #0084F8 10%);*/
            background: linear-gradient(#0084F8 90%, white);
        }
        
        .tool-icon {
            right: 30px;
            top: 80px;
            font-size: 60px;
            position: absolute;
            color: #fff !important;
            border-radius: 100%;
            box-shadow: 0px 0px 2px #888;
            padding: 0.2em 0.2em;
        }

        @media (max-width: 767px) {
            .tool-icon {
                position: sticky;
            }
        }
        a.link_download {
            margin: 0px !important;
            padding: 0 10px;
            margin-right: 10px !important;
            margin-top: 10px !important;
            font-size: 12px;
        }
        a.link_support {
            color: #fff;
            text-decoration: unset;
        }
    }

</style>
