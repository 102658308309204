<template>
    <div class="jobs-facebook-detail">
        <v-container fluid grid-list-lg v-if="dataDetail">
            <v-row class="ma-2">
                <v-col cols="12">
                    <v-btn
                            :to="{ name : 'jobs_facebook' }"
                            color="primary text"
                            rounded
                            small
                    >
                        {{ back }}
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-row class="job box-shadow">
                        <v-col cols="3" md="1" class="text-center pa">
                            <div style="border-right: 1px solid #777777">
                                <v-avatar size="36">
                                    <img :src="dataDetail.icon" alt="">
                                </v-avatar>
                                <h6 class="body-1 font-weight-bold mt-1">
                                    <span><span class="hold-prices">{{ dataDetail.coin }}</span></span>
                                    <font-awesome-icon :icon="['fas', 'coins']" class="v-coin-icon white--text"/>
                                </h6>
                            </div>
                        </v-col>
                        <v-col cols="9" md="11" class="caption job-info px-0">
                            <div>
                                <span class="text-uppercase primary--text font-weight-bold hidden-md-and-up body-2">{{dataDetail.seeding_title}}</span>
                                <span class="text-uppercase primary--text font-weight-bold hidden-sm-and-down title">{{dataDetail.seeding_title}}</span><br/>
                                <span v-if="dataDetail.seeding_type === 3 || dataDetail.seeding_type === 15 || dataDetail.seeding_type === 13">
                                    <span class="hidden-md-and-up">Nội dung: {{ dataDetail.comment_need | jobContentFormat(25)}}</span>
                                    <span class="hidden-sm-and-down">Nội dung: {{ dataDetail.comment_need | jobContentFormat(80)}}</span>
                                    <v-btn @click="copyComment" class="mx-2" x-small max-width="25" max-height="25" dark
                                           color="cyan">
                                        copy
                                    </v-btn>
                                </span>
                                <span v-else>
                                    <span class="hidden-md-and-up">Nội dung: {{ dataDetail.content | jobContentFormat(25)}}</span>
                                    <span class="hidden-sm-and-down">Nội dung: {{ dataDetail.content | jobContentFormat(80)}}</span>
                                </span>
                                <br/>
                                <span class="caption">Job Id: <span class="font-weight-bold">{{ dataDetail.id }}</span> - Fb Id: <span
                                        class="font-weight-bold">{{ dataDetail.post_id }}</span></span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row v-if="dataDetail.seeding_type === 5" class="ma-2 step-1 box-shadow guide_job_follow">
                <v-col cols="12 py-0">
                    <div class="notice-detail mx-5 mt-5">
                        <div class="guide_title">
                            <v-col cols="12 py-0">
                                <v-row class="job">
                                    <v-col cols="2" md="1" class="text-center px-0">
                                        <div>
                                            <v-icon color="yellow" x-large>warning</v-icon>
                                        </div>
                                    </v-col>
                                    <v-col cols="10" md="10" class="caption job-info px-0">
                                        <div>
                                            <span class="font-weight-bold">Để thực hiện được job follow, bạn cần bật công khai danh sách “Đang Theo Dõi” trên tài khoản facebook</span><br/>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                        <span class="guide_text">
                            Bước 1: Vào bạn bè trong trang cá nhân<br>
                            Bước 2: Chọn “Chỉnh Sửa Quyền Riêng Tư”<br>
                            Bước 3: Mục “Đang Theo Dõi” chọn “Công Khai”<br>
                            Vui lòng xem video để biết thêm hướng dẫn <a href="https://www.youtube.com/watch?v=7HEkX3vipd0" target="_blank">https://www.youtube.com/watch?v=7HEkX3vipd0</a>
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="dataDetail.seeding_type === 15" class="ma-2 step-1 box-shadow guide_job_follow">
                <v-col cols="12 py-0">
                    <div class="notice-detail mx-5 mt-5">
                        <div class="guide_title">
                            <v-col cols="12 py-0">
                                <v-row class="job">
                                    <v-col cols="2" md="1" class="text-center px-0">
                                        <div>
                                            <v-icon color="yellow" x-large>warning</v-icon>
                                        </div>
                                    </v-col>
                                    <v-col cols="10" md="10" class="caption job-info px-0">
                                        <div>
                                            <span class="font-weight-bold">Để thực hiện được job review fanpage, hãy thực hiện đúng quy trình sau:</span><br/>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                        <span class="guide_text">
                            Bước 1: Truy cập Fanpage của job<br>
                            Bước 2: Chọn tab “Đánh giá”<br>
                            Bước 3: Giới thiệu trang hoặc đóng góp ý kiến. Dán nội dung cần đánh giá và đăng.<br>
                            Bước 4: Chia sẻ bài viết bạn vừa đánh giá lên trang cá nhân.
                            Bước 5: Hoàn thành! Đợi hệ thống check thôi.
                            <!-- Vui lòng xem video để biết thêm hướng dẫn <a href="https://www.youtube.com/watch?v=7HEkX3vipd0" target="_blank">https://www.youtube.com/watch?v=7HEkX3vipd0</a> -->
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row class="ma-2 step-1">
                <v-col class="font-weight-bold body-2">
                    Bước 1: Làm việc
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow" v-if="isMobile() && dataDetail.seeding_type == 15">
                <v-col cols="12 py-0" @click="getData('app-page')">
                    <a class="goto-link">
                        <v-row class="job">
                            <v-col cols="2" md="1" class="text-center px-0" >
                                <div>
                                    <v-icon color="primary" x-large>mdi-facebook</v-icon>
                                </div>
                            </v-col>
                            <v-col cols="8" md="10" class="caption job-info px-0">
                                <div>
                                    <span class="font-weight-bold">Facebook</span><br/>
                                    <span class="caption text-truncate">Làm việc bằng ứng dụng Facebook trên điện thoại.</span>
                                </div>
                            </v-col>
                            <v-col cols="2" md="1" class="text-right">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                        </v-row>
                    </a>
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow" v-else>
                <v-col cols="12 py-0" @click="getData('phone')">
                <a class="goto-link">
                        <v-row class="job">
                            <v-col cols="2" md="1" class="text-center px-0" >
                                <div>
                                    <v-icon color="primary" x-large>mdi-facebook</v-icon>
                                </div>
                            </v-col>
                            <v-col cols="8" md="10" class="caption job-info px-0">
                                <div>
                                    <span class="font-weight-bold">Facebook</span><br/>
                                    <span class="caption text-truncate">Làm việc bằng ứng dụng Facebook trên điện thoại.</span>
                                </div>
                            </v-col>
                            <v-col cols="2" md="1" class="text-right">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                        </v-row>
                    </a>
                </v-col>
                <v-divider cols="12" :inset="insetDivider"></v-divider>
                <v-col cols="12 py-0" @click="getData('web')">
                    <a class="goto-link">
                        <v-row class="job">
                            <v-col cols="2" md="1" class="text-center px-0">
                                <div>
                                    <v-icon color="primary" x-large>mdi-google-chrome</v-icon>
                                </div>
                            </v-col>
                            <v-col cols="8" md="10" class="caption job-info px-0">
                                <div>
                                    <span class="font-weight-bold">Facebook</span><br/>
                                    <span class="caption text-truncate">Làm việc bằng trình duyệt web.</span>
                                </div>
                            </v-col>
                            <v-col cols="2" md="1" class="text-right">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                        </v-row>
                    </a>
                </v-col>
                <v-divider cols="12" :inset="insetDivider"></v-divider>

                <!--2, 9, 10, 11, 12-->
                <v-col cols="12 py-0" v-if="dataDetail.seeding_type !== 15 
                    && dataDetail.seeding_type !== 2 
                    && dataDetail.seeding_type !== 9
                    && dataDetail.seeding_type !== 10
                    && dataDetail.seeding_type !== 11
                    && dataDetail.seeding_type !== 12"
                       @click="getData('mbasic')">
                    <a class="goto-link">
                        <v-row class="job">
                            <v-col cols="2" md="1" class="text-center px-0">
                                <div>
                                    <v-icon color="primary" x-large>mdi-web</v-icon>
                                </div>
                            </v-col>
                            <v-col cols="8" md="10" class="caption job-info px-0">
                                <div>
                                    <span class="font-weight-bold">mBasic</span><br/>
                                    <span class="caption text-truncate">Làm việc bằng Link rút gọn mbasic của facebook.</span>
                                </div>
                            </v-col>
                            <v-col cols="2" md="1" class="text-right">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                        </v-row>
                    </a>
                </v-col>

            </v-row>

            <v-row class="ma-2 step-1" v-if="stepVisible === true">
                <v-col class="font-weight-bold body-2">
                    Bước 2: Nhận tiền
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow" v-if="stepVisible === true">
                <v-col cols="12 py-0">
                    <v-row class="job complete-job" @click="completeJob">
                        <v-col cols="2" md="1" class="text-center px-0">
                            <div>
                                <v-icon color="warning" x-large>monetization_on</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="8" md="10" class="caption job-info px-0">
                            <div>
                                <span class="font-weight-bold">Hoàn thành</span><br/>
                                <span class="caption text-truncate">Bấm hoàn thành để nhận tiền sau khi làm việc xong.</span>
                            </div>
                        </v-col>
                        <v-col cols="2" md="1" class="text-right">
                            <v-icon>mdi-arrow-right</v-icon>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-row class="ma-2 step-1">
                <v-col class="font-weight-bold body-2">
                    Báo lỗi Job
                </v-col>
            </v-row>
            <v-row class="ma-2 step-1 box-shadow" @click="showReport = !showReport">
                <v-col cols="12 py-0">
                    <v-row class="job">
                        <v-col cols="2" md="1" class="text-center px-0">
                            <div>
                                <v-icon color="red" x-large>warning</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="8" md="10" class="caption job-info px-0">
                            <div>
                                <span class="font-weight-bold">Báo lỗi</span><br/>
                                <span class="caption text-truncate">Báo lỗi cho hệ thống khi làm việc thất bại</span>
                            </div>
                        </v-col>
                        <v-col cols="2" md="1" class="text-right">
                            <v-icon>mdi-arrow-right</v-icon>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-row class="ma-2 step-1 box-shadow" v-if="showReport">
                <v-col cols="12 py-0">
                    <v-row class="job">
                        <v-col cols="12" class="caption pl-5">
                            <div>
                                <h3 class="font-weight-bold">Lỗi bạn gặp là gì ?</h3>
                                <v-radio-group v-model="reportErrors">
                                    <v-radio
                                            v-for="item in errors"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            class="lable-err"
                                    ></v-radio>

                                    <v-text-field label="Nhập ý kiến" v-if="reportErrors==7"></v-text-field>

                                    <v-btn width="100%" height="30px" color="blue white--text" @click="sendReport">
                                        Gửi báo cáo
                                    </v-btn>
                                </v-radio-group>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-row class="ma-2 step-1 box-shadow">
                <v-col cols="12 py-0">
                    <div class="notice-detail mx-5 mt-5">
                        <p>
                            1. Tuyệt đối <span class="red--text">cấm</span> các hành động comment, review,.. không đúng
                            yêu cầu của công việc hay phá hoại khách hàng.<br>

                            2. Nếu bị khách hàng báo cáo, tài khoản của bạn sẽ bị <span class="red--text">cấm sử dụng Farmer vĩnh viễn</span>
                            và Farmer sẽ không trả bất kỳ 1 chi phí nào cho bạn.<br>

                            3. Nhấn nút <span class="blue--text">LÀM VIỆC</span>, sau đó nhấn <span class="red--text">LIKE</span>
                            bài viết và quay lại Hana nhấn nút <span class="blue--text">HOÀN THÀNH</span> để nhận tiền
                            thưởng.<br>

                            4. Farmer sẽ tự động kiểm tra ngẫu nhiên trong thời gian bảo hành của Job (thường là 7
                            ngày).<br>

                            5. Nếu bạn chưa thực hiện <span class="red--text">LIKE</span> bài viết sẽ bị trừ tiền, nhiều
                            lần gian lận sẽ bị khóa tài khoản kiếm tiền.<br>
                        </p>
                    </div>
                </v-col>
            </v-row>
            <span class="white--text">Nội dung: <span id="comment">{{ dataDetail.comment_need }}</span></span>
        </v-container>
        <v-container v-else>
            <h3>Job không tồn tại !</h3>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    color="info"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
  import {
    FACEBOOK_JOB_GET_DETAIL,
    FACEBOOK_JOB_REPORT,
    FACEBOOK_JOB_EXECUTE,
    FACEBOOK_START_WORK
  } from "~/store/actions.type";
  import {COMMON_SET_SNACK} from "~/store/actions.type";

  export default {
    components: {},
    data() {
      return {
        tab: null,
        coinSvg: '/images/coin.svg',
        likePageGif: '/images/like_page.gif',
        insetDivider: true,
        showReport: false,
        reportErrors: 1,
        overlay: false,
        stepVisible: false,
        back: '<< Danh sách công việc',
        errors: [
          {
            value: 1,
            label: "Tôi không muốn làm Job này"
          },
          {
            value: 2,
            label: "Tôi đã làm Job này rồi"
          },
          {
            value: 3,
            label: "Job đủ số lượng"
          },
          {
            value: 4,
            label: "Không tìm thấy bài viết"
          },
          {
            value: 5,
            label: "Báo cáo hoàn thành thất bại"
          },
          {
            value: 6,
            label: "Job có nội dung không lành mạnh"
          },
          {
            value: 7,
            label: "Ý kiến khác"
          },

        ],
        otherError: '',
        dataDetail: {},
        basic_post_link: "https://mbasic.facebook.com/",
        phone_post_link: "https://www.facebook.com/",
        web_post_link: "https://m.facebook.com/",
        report: ''
      }
    },
    filters: {
      jobContentFormat(content, count) {
        if (typeof content !== "undefined" && content !== null) {
          if (content.length > count) {
            return content.substring(0, count) + '...';
          }
          return content;
        }
        return content;
      },
    },
    computed: {},
    created() {
      this.getDataFromApi();
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            }
            return false
        },
      async removeJobInCache(job_id = '') {
          const account_id = await localStorage.getItem('facebook_account')
          const cacheJobKey = account_id.toString() + '_cached_jobs';
          let cachedObj = await localStorage.getItem(cacheJobKey);
          cachedObj = await JSON.parse(cachedObj);
          let newJobs = []

          const detail_job_id = job_id ? job_id : this.dataDetail.id.toString()

          for (const item of cachedObj.jobs) {
              if (item.id.toString() !== detail_job_id) {
                  newJobs = await newJobs.concat(item)
              }
          }

          cachedObj.jobs = newJobs;
          await localStorage.setItem(cacheJobKey, JSON.stringify(cachedObj))
      },
      async getDataFromApi() {
        this.overlay = true;
        let params = {
          fb_account_id: localStorage.getItem('facebook_account'),
          job_id: this.$route.params.id
        };
        let data = await this.$store.dispatch(FACEBOOK_JOB_GET_DETAIL, params);
        if (!data) {
            this.overlay = false;
            await this.removeJobInCache(this.$route.params.id.toString());
            this.$router.push('/jobs/facebook')
        }
        this.dataDetail = data;
        if (this.dataDetail.comment_need !== null && (this.dataDetail.seeding_type === 3 || this.dataDetail.seeding_type === 15 || this.dataDetail.seeding_type === 13)) {
          let arr = JSON.parse(data.comment_need);
          this.dataDetail.comment_need = arr[0];
        }
        this.overlay = false;
      },

      async getData(typeLink) {
          this.overlay = true;
          let baseLink = 'https://www.facebook.com/';
          if (typeLink == 'phone') {
              baseLink = this.phone_post_link;
          } else if (typeLink == 'web') {
              baseLink = this.web_post_link;
          } else if (typeLink == 'mbasic') {
              baseLink = this.basic_post_link;
          } else if (typeLink == 'app-page') {
              baseLink = 'fb://page/'
          }
        let linkFB = '';
        if (this.dataDetail.seeding_type === 15) {
            linkFB = baseLink + '' + this.dataDetail.post_id
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                linkFB += '?referrer=app_link';
            }
        } else {
            linkFB = baseLink + '' + this.dataDetail.post_id
        }
        var newWindow = window.open();
        let params = {
          job_id: this.$route.params.id,
          account_id: parseInt(localStorage.getItem('facebook_account')),
          linkFB: linkFB,
          newWindow: newWindow
        };
        this.stepVisible = await this.$store.dispatch(FACEBOOK_START_WORK, params);
        if (!this.stepVisible) {
            await this.removeJobInCache();
        }
        this.overlay = false;
      },
      completeJob() {
        let params = {
          fb_account_id: localStorage.getItem('facebook_account'),
          job_id: this.$route.params.id,
            user_following_info: this.dataDetail.user_following_info || this.dataDetail.user_following_info == 0
                ? this.dataDetail.user_following_info
                : '',
            user_likes_info: this.dataDetail.user_likes_info || this.dataDetail.user_likes_info == 0
                ? this.dataDetail.user_likes_info
                : '',
            fanpage_likes_info: this.dataDetail.fanpage_likes_info || this.dataDetail.fanpage_likes_info == 0
                ? this.dataDetail.fanpage_likes_info
                : '',
        };
        this.overlay = true;
        this.$store.dispatch(FACEBOOK_JOB_EXECUTE, params).then(async (data) => {
          this.overlay = false;
          await this.removeJobInCache();
          if (typeof data !== "undefined" && typeof data.success !== "undefined" && data.success) {
            this.$router.push('/jobs/facebook')
          }
        });
      },
      sendReport() {
        let params = {
          fb_account_id: localStorage.getItem('facebook_account'),
          job_id: this.$route.params.id,
        };
        this.$store.dispatch(FACEBOOK_JOB_REPORT, params).then(async (data) => {
          this.overlay = false;
          await this.removeJobInCache();
          if (typeof data !== "undefined" && typeof data.success !== "undefined" && data.success) {
            this.$router.push('/jobs/facebook')
          }
        });
      },
      copyComment() {
        try {
          let range = document.createRange();
          range.selectNode(document.getElementById("comment"));
          window.getSelection().removeAllRanges(); // clear current selection
          window.getSelection().addRange(range); // to select text
          document.execCommand("copy");
          window.getSelection().removeAllRanges();// to deselect
          this.$store.dispatch(COMMON_SET_SNACK, {
            message: 'Đã copy comment thành công !',
            type: "success"
          });
        } catch (e) {
          console.log('Copy comment thất bại !');
        }
      }
    }

  };
</script>
<style lang="scss" scoped>
    .guide_job_follow {
        background: #ffffff;
        padding-bottom: 10px;
    }
    .guide_job_follow .guide_title {
        padding-bottom: 10px !important;
    }
    .guide_job_follow .guide_title .caption {
        padding-left: 10px !important;
        font-size: 0.9rem !important;
    }
    .v-application .yellow--text {
        color: #FFD700 !important;
        caret-color: #FFD700 !important;
    }

    .jobs-facebook-detail {
        .goto-link {
            text-decoration: unset;
            color: unset;
        }

        .complete-job {
            cursor: pointer;
        }

        .job {
            background-color: #ffffff;

            .job-info {
                position: relative;

                div {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .box-shadow {
            -webkit-box-shadow: -1px 0px 7px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: -1px 0px 7px -1px rgba(0, 0, 0, 0.75);
            box-shadow: -1px 0px 7px -1px rgba(0, 0, 0, 0.75);
        }

        .notice-detail {
            font-size: 14px;
            line-height: 1.5rem;
        }
    }
</style>

