import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.js";
import common from "./modules/common.js";
import facebook_account from "./modules/facebook_account.js";
import facebook_job from "./modules/facebook_job.js";
import farmer_account from "./modules/farmer_account.js";
import referral from "./modules/referral.js";
import instagram_account from "./modules/instagram_account";
import instagram_job from "./modules/instagram_job";
import tiktok_account from "./modules/tiktok_account";
import tiktok_job from "./modules/tiktok_job";
import lucky_wheel from "./modules/lucky_wheel";
import viewtub_account from "./modules/viewtub_account";
import tool_download from "./modules/tool_download";
import race_top from "./modules/race_top";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        auth,
        common,
        farmer_account,
        facebook_account,
        facebook_job,
        referral,
        instagram_account,
        instagram_job,
        tiktok_account,
        tiktok_job,
        lucky_wheel,
        viewtub_account,
        tool_download,
        race_top
    },
    strict: debug
    // plugins: debug ? [createLogger()] : []
});
