<template>
    <div class="withdraw-container">
        <v-container fluid grid-list-lg>
            <div class="tab-withdraw">
                <v-row>
                    <v-col>
                        <v-card class="notice pa-2 black--text bg-gradient" outlined >
                            <p>
                                <span class="font-weight-bold">Lưu ý:</span><br>
                                - Thực hiện việc rút tiền của tài khoản ViewTub vào tài khoản Hana của bạn.<br>
                                - Việc rút tiền sẽ thực hiện ngay tức thời và không thể huỷ.<br>
                            </p>
                        </v-card>
                    </v-col>
                </v-row>
                <v-form ref="form_user_withdraw">
                    <v-row>
                    <v-col cols="12">
                        <v-card class="pa-2 bg-white" outlined >
                            <span class="mb-4 font-weight-bold">Chọn tài khoản ViewTub</span><br>
                            <v-select
                                    solo
                                    :items="viewtub_accounts"
                                    v-model="viewtub_account"
                                    item-text="name"
                                    item-value="id"
                            ></v-select>
                            <span class="mb-4 font-weight-bold">Nhập số tiền cần rút</span><br>
                            <v-text-field
                                    solo
                                    v-model="money"
                                    type="number"
                                    aria-valuemin="0"
                                    required
                                    :rules="moneyRules"
                            ></v-text-field>
                            <span class="mb-2">Mật khẩu rút tiền</span>
                            <v-text-field
                                    solo
                                    v-model="password"
                                    type="password"
                                    required
                                    :rules="passwordRules"
                            ></v-text-field>
                        </v-card>
                    </v-col>
                </v-row>
                </v-form>
                <v-row>
                    <v-col cols="12">
                        <v-btn width="100%" height="50px" color="blue" class="white--text" @click="withDrawV2()">
                            Rút Tiền
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
    import {formatCurrency} from "~/common/helper.string";
    import {
        AUTH_USER,
        COMMON_SET_SNACK,
        VIEWTUB_ACCOUNT_FETCH_ALL,
        WITHDRAW_POST_VIEWTUB
    } from "../store/actions.type";
    import Swal from "sweetalert2";

    export default {
        name: "ViewTubMoney",
        data() {
            return {
                // history
                viewtub_accounts: [],
                viewtub_account: null,
                money: 1,
                moneyRules: [
                    v => !!v || 'Vui lòng nhập Số tiền',
                    v => v > 0 || 'Số tiền phải là số dương'
                ],
                password: "",
                passwordRules: [
                    v => !!v || 'Vui lòng nhập mật khẩu rút tiền'
                ],
                security: 0,
            }
        },
        created() {
            this.initialize()
            this.viewTubAccountFetchAll()
        },
        methods: {
            async initialize() {
                let currentUser = await this.$store.dispatch(AUTH_USER);
                this.security = currentUser.data.data.security;
            },
            withDrawV2() {
                if (this.viewtub_accounts.length == 0) {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Bạn phải có liên kết tài khoản viewtub trước!',
                        footer: '<a href="/account/manager/viewtub">Liên kết tài khoản viewTub</a>'
                    })
                }

                switch (this.security) {
                    case 0:
                        Swal.fire({
                            icon: 'warning',
                            title: 'Notice...',
                            text: 'Bạn cần tạo mật khẩu rút tiền trước!',
                            footer: '<a href="/account/setting-security">Tạo mật khẩu rút tiền!</a>',
                        })
                        break;
                    case 1:
                        Swal.fire({
                            icon: 'warning',
                            title: 'Notice...',
                            text: 'Bạn cần bật mật khẩu rút tiền trước!',
                            footer: '<a href="/account/setting-security">Bật bảo mật tài khoản!</a>'
                        })
                        break;
                    case 2:
                        if (this.$refs.form_user_withdraw.validate()) {
                            Swal.fire({
                                title: 'Bạn có chắc chắn muốn rút tiền với thông tin:',
                                html: this.getHmlConfirm(),
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Vâng , chính xác!',
                                cancelButtonText: 'Không'
                            }).then((result) => {
                                if (result.value) {
                                    this.$store.dispatch(WITHDRAW_POST_VIEWTUB, {account_id: this.viewtub_account, money: this.money, password: this.password}).then(res => {
                                        if (res) {
                                            let aThis = this;
                                            this.$store.dispatch(AUTH_USER);
                                            setTimeout(function () {
                                                aThis.$router.go();
                                            }, 3000);
                                        }
                                    });

                                }
                            })
                        }
                }
            },
            getHmlConfirm() {
                let html = "<h2>" +
                    "Rút tiền từ tài khoản viewtub với số tiền: " + formatCurrency(parseInt(this.money))  +
                    "</h2>";
                return html
            },
            viewTubAccountFetchAll() {
                this.$store.dispatch(VIEWTUB_ACCOUNT_FETCH_ALL)
                    .then(({data}) => {
                        this.viewtub_account = data[0].id
                        this.viewtub_accounts = data.map(item => {
                            let data = {
                                id: item.id,
                                name: item.name + ' - Coin:' + item.coin
                            }
                            return data
                        });
                        if (this.viewtub_accounts.length == 0) {
                            return Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Bạn phải có liên kết tài khoản viewtub trước!',
                                footer: '<a href="/account/manager/viewtub">Liên kết tài khoản viewTub</a>'
                            })
                        }
                    })
                    .catch(() => {
                        this.$store.dispatch(COMMON_SET_SNACK, {
                            message: 'Đã có lỗi xảy ra, vui lòng thử lại sau !',
                            type: 'error'
                        });
                    });
            },
        }
    }
</script>

<style scoped>

</style>